import { Path } from "react-hook-form";
import __ from "../../../utils/utils";
import { WorkOrder } from "@eljouren/domain";
import { AppFormTextField } from "../../common/text-fields/AppFormTextField";
import { CustomerOrderDefaultFormFieldProps } from "./CustomerOrderDefaultFormFields";

const CustomerOrderContactInformationFieldset = (
  props: CustomerOrderDefaultFormFieldProps
) => {
  const allowEditing: Partial<Record<Path<WorkOrder.Type>, boolean>> =
    props.allowEditing || {};

  return (
    <fieldset
      className={__.classNames("flex flex-col gap-2 pb-6", props.className)}
    >
      <header>
        <h3 className="text-xl">Kontaktinformation</h3>
      </header>
      <main className="grid grid-cols-2 gap-2">
        <AppFormTextField
          className="col-span-2"
          register={props.register}
          name="contact.fullName"
          label="Namn"
          htmlAttributes={{
            readOnly: !allowEditing["contact.firstName"],
            disabled: !allowEditing["contact.firstName"],
          }}
        />
        <AppFormTextField
          className="col-span-2"
          register={props.register}
          name="contact.phone"
          label="Telefonnummer"
          options={{
            pattern: {
              value: /^\+.*/,
              message: "Börja telefonnumret med ett +",
            },
          }}
          htmlAttributes={{
            type: "tel",
            readOnly: !allowEditing["contact.phone"],
            disabled: !allowEditing["contact.phone"],
          }}
          helperText={
            props.showPhoneNumberHelperLabels === true ||
            props.showPhoneNumberHelperLabels === "onlyForPrimary"
              ? "Börja telefonnumret med ett +"
              : undefined
          }
        />

        {!props.order.customerIsCompany && (
          <AppFormTextField
            className="col-span-2"
            register={props.register}
            name="contact.phone2"
            label="Ytterligare telefonnummer"
            options={{
              pattern: {
                value: /^\+.*/,
                message: "Börja telefonnumret med ett +",
              },
            }}
            htmlAttributes={{
              type: "tel",
              readOnly: !allowEditing["contact.phone2"],
              disabled: !allowEditing["contact.phone2"],
            }}
            helperText={
              props.showPhoneNumberHelperLabels === true ||
              props.showPhoneNumberHelperLabels === "onlyForSecondary"
                ? "Börja telefonnumret med ett +"
                : undefined
            }
          />
        )}
        <AppFormTextField
          className="col-span-2"
          register={props.register}
          name="contact.email"
          label="E-postadress"
          htmlAttributes={{
            readOnly: !allowEditing["contact.email"],
            disabled: !allowEditing["contact.email"],
            type: "email",
          }}
        />
      </main>
    </fieldset>
  );
};

export default CustomerOrderContactInformationFieldset;
