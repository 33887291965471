import { useEffect } from "react";
import { useBrand } from "../../hooks/brand-hooks";

interface Props {
  className?: string;
}

/*
  This doesn't feel like the correct way to use React Components lmao
*/
const BrandLogic = (props: Props) => {
  const brand = useBrand();

  useEffect(() => {
    let linkHref: string;
    let title: string;
    switch (brand) {
      case "eljouren":
        title = "Svenska Eljouren – Sveriges modernaste elektriker";
        linkHref = "/favicon-eljouren.svg";
        break;
      case "rorjouren":
        title = "Rörjour 24/7 – Effektiva rörmokare & rörjour 24/7";
        linkHref = "/favicon-rorjouren.svg";
        break;
      case "ipis":
        title = "IPIS – Din kompletta installationspartner";
        linkHref = "/favicon-ipis.svg";
        break;
    }

    document.title = title;

    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      //@ts-ignore
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    //@ts-ignore
    link.href = linkHref;
  }, [brand]);

  return <></>;
};

export default BrandLogic;
