import { parse } from "date-fns";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import __ from "../../../utils/utils";
import WorkerDateHelper from "../../../_model/helpers/WorkerDateHelper";

interface Props<T extends FieldValues> {
  className?: string;
  name: Path<T>;
  control: Control<T>;
  htmlAttributes?: Omit<JSX.IntrinsicElements["input"], "ref">;
}

function ControlledTimePicker<T extends FieldValues>(props: Props<T>) {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => {
        const helper = new WorkerDateHelper(field.value);
        const value = helper.hourAndMinutes();
        return (
          <input
            className={__.classNames(
              "rounded bg-bg-base-layer p-2",
              props.className
            )}
            ref={field.ref}
            type="time"
            value={value}
            onBlur={field.onBlur}
            onChange={(e) => {
              let value = e.target.value;
              const date = parse(value, "HH:mm", new Date());
              field.onChange(date);
            }}
            {...props.htmlAttributes}
          />
        );
      }}
    />
  );
}

export default ControlledTimePicker;
