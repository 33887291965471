import { IpisFile } from "@eljouren/file-schemas/build";
import { UseMutatableQueryResult } from "../../../hooks/use-mutatable-query";
import { UseQueryWrapperResult } from "../../../hooks/use-query-wrapper";
import { AppLoader } from "../../common/loaders/AppLoader";
import IpisFileDataList from "./IpisFileDataList";

interface Props {
  className?: string;
  res:
    | UseQueryWrapperResult<IpisFile.WithMetaType[]>
    | UseMutatableQueryResult<IpisFile.WithMetaType[]>;
}

const IpisFileQueryList = (props: Props) => {
  let data: IpisFile.WithMetaType[] | undefined;
  let isLoading: boolean;
  let isError: boolean;
  if ("query" in props.res) {
    data = props.res.query.data;
    isLoading = props.res.query.isLoading;
    isError = props.res.query.isError;
  } else {
    data = props.res.data;
    isLoading = props.res.isLoading;
    isError = props.res.isError;
  }

  if (isError) {
    return <p>Det gick inte att hämta relaterade filer just nu</p>;
  }

  if (isLoading) {
    return <AppLoader />;
  }

  if (!data?.length) {
    return <p>Det finns inga filer att visa</p>;
  }

  /* 
    ToDo: 
      * Cool animations :sunglasses:
  */

  if (data) {
    return <IpisFileDataList data={data} />;
  }
  return <></>;
};

export default IpisFileQueryList;
