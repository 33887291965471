import { IpisFile } from "@eljouren/file-schemas/build";
import { AnimatePresence, motion } from "framer-motion";
import { useContext } from "react";
import { useBundledState } from "../../hooks/hooks";
import { GlobalContext } from "../../top-level-contexts";
import __ from "../../utils/utils";
import { AppButton } from "../common/buttons/AppButton";
import AppTextButton from "../common/buttons/AppTextButton";
import { AppLoader } from "../common/loaders/AppLoader";
import SelectableFileGrid from "../image-grid/SelectableFileGrid";
import { WorkOrderFileContext } from "../order-image-wrapper/WorkOrderFileContextProvider";
import IpisFileDataList from "./handyman/IpisFileDataList";
import WorkOrderImageUpload, { FullImageType } from "./WorkOrderImageUpload";

interface Props {
  className?: string;
  allowUploading: boolean;
  allowDeleting: boolean;
  orderId: string;
  uploadLabel: string;
}

type ShowImages = "all" | "customer" | "worker" | "staff";

const WorkOrderImages = (props: Props) => {
  const page = useBundledState<"display" | "upload">("display");
  const { signInState } = useContext(GlobalContext);
  const allowedToViewAllImages =
    signInState.signedInAs === "worker" || signInState.signedInAs === "sales";
  const imageCtx = useContext(WorkOrderFileContext);
  const isLoading = imageCtx.fileRes.query.isLoading;

  const view = useBundledState<"list" | "grid">("grid");
  const showImages = useBundledState<ShowImages>("all");

  const filteredFiles = (imageCtx.fileRes.query.data ?? []).filter((obj) => {
    if (showImages.value === "all") {
      return true;
    }
    return obj.uploadedBy === showImages.value;
  });

  async function onSubmit(images: FullImageType[]) {
    if (images.length) {
      try {
        page.set("display");
        await imageCtx.uploadFileMutation(images);
      } catch (ex) {
        window.modal.alert({
          title: "Det gick inte att ladda upp filer just nu",
          prompt: "Vänligen försök igen senare",
          typeOfAlert: "error",
        });
      }
    } else {
      page.set("display");
    }
  }

  async function onDelete(file: IpisFile.WithMetaType): Promise<boolean> {
    try {
      const confirm = await window.modal.confirm({
        title: "Ta bort fil",
        prompt: `Är du säker på att du vill ta bort '${
          file.meta?.customName ?? file.name
        }${file.ext}'?`,
      });
      if (confirm) {
        await imageCtx.deleteFileMutation(file);
      }
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att ta bort filen just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
      });
    }

    return false;
  }

  function toggleView() {
    if (view.value === "grid") {
      view.set("list");
    } else {
      view.set("grid");
    }
  }

  const isError = imageCtx.fileRes.query.isError;
  return (
    <AnimatePresence exitBeforeEnter>
      {page.value === "display" && (
        <motion.section
          key="view"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          className={__.classNames(
            "mx-auto grid h-full w-full grid-cols-1",
            props.allowUploading && "grid-rows-[minmax(0,1fr),auto]",
            !props.allowUploading && "grid-rows-1",
            props.className
          )}
        >
          {isLoading && <AppLoader />}
          {!isLoading && (
            <main className="flex h-full w-full flex-col">
              <section
                className={__.classNames(
                  allowedToViewAllImages &&
                    "grid h-full grid-cols-1 grid-rows-[auto,minmax(0,1fr)]",
                  !allowedToViewAllImages && "flex h-full flex-col"
                )}
              >
                {allowedToViewAllImages && (
                  <header className="flex flex-col gap-1 border-b p-2 md:flex-row md:items-center md:gap-2">
                    <AppTextButton
                      onClick={toggleView}
                      className="mr-auto px-2"
                    >
                      {view.value === "grid" && "Visa listvy"}
                      {view.value === "list" && "Visa rutnätsvy"}
                    </AppTextButton>
                    <select
                      className="cursor-pointer rounded border-2 p-2 md:ml-auto"
                      onChange={(e) =>
                        showImages.set(e.target.value as ShowImages)
                      }
                    >
                      <option value={"all"}>Alla filer</option>
                      <option value={"customer"}>Kundfiler</option>
                      <option value={"worker"}>Hanterverkarfiler</option>
                      <option value={"staff"}>Anställda</option>
                    </select>
                    {imageCtx.fileRes.isLoading && (
                      <AppLoader className="ml-0 mr-2 h-6 w-6" />
                    )}
                  </header>
                )}
                <main className="h-full w-full">
                  <AnimatePresence>
                    {isError && (
                      <p className="flex p-2 text-red-600">
                        Något gick fel när filerna skulle hämtas
                      </p>
                    )}
                    {view.value === "grid" && !isError && (
                      <SelectableFileGrid
                        files={
                          filteredFiles.length
                            ? filteredFiles.map((obj) => ({
                                ...obj,
                                alt: `Uppladdad bild till ärende ${props.orderId} med namn ${obj.name}`,
                              }))
                            : undefined
                        }
                        isError={imageCtx.fileRes.query.isError}
                        isLoading={imageCtx.fileRes.query.isLoading}
                        onDelete={onDelete}
                        disableDelete={!props.allowDeleting}
                      />
                    )}
                    {view.value === "list" && !isError && (
                      <IpisFileDataList data={filteredFiles} />
                    )}
                  </AnimatePresence>
                </main>
              </section>
            </main>
          )}
          {props.allowUploading && (
            <footer className="mt-auto flex border-t p-2">
              <AppButton className="ml-auto" onClick={() => page.set("upload")}>
                Ladda upp filer
              </AppButton>
            </footer>
          )}
        </motion.section>
      )}

      {page.value === "upload" && (
        <motion.section
          key="upload"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          className={__.classNames(
            "mx-auto flex w-full grow flex-col p-2",
            props.className
          )}
        >
          <WorkOrderImageUpload
            uploadLabel={props.uploadLabel}
            onGoBack={() => page.set("display")}
            onSubmit={onSubmit}
            requireAtLeastOneImage={true}
          />
        </motion.section>
      )}
    </AnimatePresence>
  );
};

export default WorkOrderImages;
