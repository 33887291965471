import { ErrorLog, ErrorLogType } from "@eljouren/domain/build";
import { useState } from "react";
import withAdminPermissions from "../../components/auth-hocs/withAdminPermissions";
import LabelAndText from "../../components/common/LabelAndText";
import { AppLoader } from "../../components/common/loaders/AppLoader";
import PageSection from "../../components/common/PageSection";
import PaginationControls from "../../components/common/pagination/PaginationControls";
import PageHeader from "../../components/layout/PageHeader";
import useQueryWrapper from "../../hooks/use-query-wrapper";
import { useRepos } from "../../hooks/use-repos";
import __ from "../../utils/utils";
import DateHelper from "../../_model/helpers/DateHelper";
import Paginator from "../../_model/helpers/Paginator";

const ErrorLogs = () => {
  const { errorLogRepo } = useRepos();
  const perPage = 2;
  const [page, setPage] = useState(0);
  const res = useQueryWrapper({
    queryKey: ["errorsLogs", page],
    queryFn: () => errorLogRepo.getMultiple({ perPage, page }),
  });

  const paginator = new Paginator({
    pageIndex: page,
    totalCountOfElements: res.data?.totalCount ?? 0,
    data: res.data?.errorLogs ?? [],
    elementsPerPage: perPage,
  });

  return (
    <section className={__.classNames("")}>
      <PageHeader heading="Errorlogg" />
      <main className="mx-auto flex max-w-screen-xl flex-col gap-4 p-4">
        <PageSection as="section">Här ska man kunna söka</PageSection>
        <PageSection as="section">
          {paginator.needsPagination && (
            <header className="flex justify-end p-4">
              <PaginationControls
                className="ml-auto"
                canGoBack={paginator.canGoBack}
                canGoForward={paginator.canGoForward}
                pageIndex={paginator.pageIndex}
                maxPageIndex={paginator.limits.maxPageIndex}
                onGoBack={() => setPage(page - 1)}
                onGoForward={() => setPage(page + 1)}
              />
            </header>
          )}
          <main>
            {res.isLoading && <AppLoader />}
            {res.isError && (
              <p className="p-2 text-red-600">
                Det gick inte att hämta loggarna just nu
              </p>
            )}
            {res.data && (
              <ul className="mx-auto flex w-full max-w-screen-xl flex-col gap-2">
                {res.data.errorLogs.map((log) => {
                  return <ErrorLogListItem key={log.id} errorLog={log} />;
                })}
              </ul>
            )}
          </main>
        </PageSection>
      </main>
    </section>
  );
};

const ErrorLogListItem = (props: { errorLog: ErrorLog.Type }) => {
  const log = props.errorLog;
  if (log.type === ErrorLogType.Enum.moreThanOneContactLinkedToEmail) {
    return (
      <ErrorLogListItemWrapper className="flex flex-col gap-2">
        <h3 className="text-lg">Fler än en användare vid inlogg</h3>
        <LabelAndText
          id={"clientId" + log.id}
          label="Klient-id"
          text={log.clientId}
        />
        <LabelAndText
          id={"date" + log.id}
          label="Datum och tid"
          text={new DateHelper(log.date).dateTimeInputFormat.replace("T", " ")}
        />
        <LabelAndText
          id={"email" + log.id}
          label="E-post som användes vid inloggsförsök"
          text={log.additionalInformation.email}
        />
        <p className="flex flex-col gap-2">
          <span className="text-sm">Användare med samma e-postaddress</span>
          <ul>
            {log.additionalInformation.contacts.map((contact) => (
              <li
                key={contact.id + log.id}
                className="grid grid-cols-[auto,minmax(0,1fr)] gap-2"
              >
                <span className="font-bold">{contact.id}</span>
                <span>{`${contact.firstName} ${contact.lastName}`}</span>
              </li>
            ))}
          </ul>
        </p>
      </ErrorLogListItemWrapper>
    );
  }

  return <></>;
};

const ErrorLogListItemWrapper = (props: {
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <li className={__.classNames("rounded border p-4", props.className)}>
      {props.children}
    </li>
  );
};

export default withAdminPermissions(ErrorLogs);
