import { ErrorLog } from "@eljouren/domain/build/";
import trpcClient from "../../trpc-setup";
import IErrorLogRepo from "./interfaces/IErrorLogRepo";

export default class ErrorLogRepo implements IErrorLogRepo {
  private transformDates(
    log: Omit<ErrorLog.Type, "date"> & { date: string }
  ): ErrorLog.Type {
    //@ts-ignore
    return {
      ...log,
      date: new Date(log.date),
    };
  }

  async getMultiple(args: { page: number; perPage: number }): Promise<{
    totalCount: number;
    errorLogs: ErrorLog.Type[];
  }> {
    const res = await trpcClient.errorLog.multiple.query(args);
    const withDates = res.errorLogs.map((item) => this.transformDates(item));
    return {
      totalCount: res.totalCount,
      errorLogs: withDates,
    };
  }
  async getSingle(args: { id: string }): Promise<ErrorLog.Type> {
    const res = await trpcClient.errorLog.single.query(args);
    return this.transformDates(res);
  }
  async search(args: {
    query: string;
    limit: number;
  }): Promise<ErrorLog.Type[]> {
    const res = await trpcClient.errorLog.search.query(args);
    const withDates = res.map((item) => this.transformDates(item));
    return withDates;
  }
}
