import { Handyman } from "@eljouren/domain/build";
import { ErrorBoundary } from "react-error-boundary";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import { TWorkerSignInData } from "../../../_model/schemas-and-types/repo-schemas";
import HandymanLandingPage, {
  HandymanLandingPageContext,
} from "./HandymanLandingPage";

interface Props {
  className?: string;
  signInData: TWorkerSignInData;
  otherWorker?: Handyman.Type;
}

const HandymanRouteOnceSignedIn = (props: Props) => {
  const signedInWorker = props.signInData.handyman;
  const handyman = props.otherWorker ?? signedInWorker;

  return (
    <ErrorBoundary
      fallbackRender={(props) => <SomethingWentWrong devError={props.error} />}
    >
      <HandymanLandingPageContext.Provider
        value={{
          signedInWorker,
          handyman,
          isViewingOwnProfile: handyman.id === signedInWorker.id,
        }}
      >
        <HandymanLandingPage />
      </HandymanLandingPageContext.Provider>
    </ErrorBoundary>
  );
};

export default HandymanRouteOnceSignedIn;
