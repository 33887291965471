import { HandymanWorkingHours } from "@eljouren/domain/build";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useBundledCustomSearchParamState } from "../../hooks/hooks";
import { useRepos } from "../../hooks/use-repos";
import DatePeriod from "../../_model/helpers/AvailabilityPeriod";
import DateHelper from "../../_model/helpers/DateHelper";
import WorkOrdersAndWorkingHoursMerger from "../../_model/helpers/WorkOrdersAndWorkingHoursMerger";
import HandymanContext from "./HandymanContext";

interface Props {
  children?: React.ReactNode;
  handymanId: string;
  pageIndexSearchParamKey: string;
}

const HandymanContextProvider = (props: Props) => {
  const weeksInPeriod = 2;
  const { handymanRepo, workOrderRepo } = useRepos();
  const pageBundle = useBundledCustomSearchParamState<number>(
    props.pageIndexSearchParamKey,
    0,
    {
      fromString: (str) => {
        return str ? Number.parseInt(str) : 0;
      },
      toString: (index) => index.toString(),
    }
  );

  function getInterval(index: number): { start: Date; end: Date } {
    const period = new DatePeriod({
      weeksInPeriod,
    });
    const dates = period.getPeriodAtIndex(index);

    const start = new DateHelper(dates[0]).startOfDay.date;
    const end = new DateHelper(dates[dates.length - 1]).add("days", 1)
      .startOfDay.date;

    return { start, end };
  }

  const workOrderRes = useQuery(
    ["workOrders", props.handymanId, pageBundle.value],
    () => {
      return workOrderRepo.getForHandymanBetween({
        handymanId: props.handymanId,
        interval: getInterval(pageBundle.value),
      });
    }
  );

  const workingHoursRes = useQuery(
    ["workingHours", props.handymanId, pageBundle.value],
    () => {
      return handymanRepo.fetchWorkingHours({
        handymanId: props.handymanId,
        interval: getInterval(pageBundle.value),
      });
    }
  );

  const saveWorkingHours = useMutation(
    async (workingHours: HandymanWorkingHours.DictIndexedByStartOfDayType) => {
      return handymanRepo.saveWorkingHours({
        workingHours,
        handymanId: props.handymanId,
      });
    },
    {
      onSettled: () => {
        workingHoursRes.refetch();
      },
    }
  );

  const isLoading =
    workingHoursRes.isFetching ||
    saveWorkingHours.isLoading ||
    workOrderRes.isFetching;

  const merger = new WorkOrdersAndWorkingHoursMerger({
    workingHours: workingHoursRes.data ?? {},
    workOrders: workOrderRes.data ?? [],
  });

  return (
    <HandymanContext.Provider
      value={{
        merger,
        isLoading,
        weeksInPeriod,
        workingHoursRes,
        workOrderRes,
        setPage: pageBundle.set,
        page: pageBundle.value,
        incrementPage: () => pageBundle.set(pageBundle.value + 1),
        decrementPage: () => pageBundle.set(pageBundle.value - 1),
        saveWorkingHours,
      }}
    >
      {props.children}
    </HandymanContext.Provider>
  );
};

export default HandymanContextProvider;
