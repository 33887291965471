import { WorkOrderLineItem } from "@eljouren/domain";

export default class WorkOrderReimbursementCalculator {
  constructor(
    private readonly props: {
      hoursData?: WorkOrderLineItem.Type[];
      materialData?: WorkOrderLineItem.Type[];
    }
  ) {}

  get forHours(): number {
    const { hoursData } = this.props;
    if (!hoursData) {
      return 0;
    }
    return hoursData
      .filter(
        (row): row is WorkOrderLineItem.Type & { unitPrice: number } =>
          !!row.unitPrice
      )
      .reduce((acc, row) => acc + row.unitPrice * row.quantity, 0);
  }

  get forMaterial(): number {
    const { materialData } = this.props;
    if (!materialData) {
      return 0;
    }
    return materialData
      .filter(
        (row): row is WorkOrderLineItem.Type & { unitPrice: number } =>
          !!row.unitPrice
      )
      .reduce((acc, row) => acc + row.unitPrice * row.quantity, 0);
  }

  get forAll(): number {
    return this.forHours + this.forMaterial;
  }
}
