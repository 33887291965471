"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierInvoice = void 0;
var zod_1 = require("zod");
var SupplierInvoice;
(function (SupplierInvoice) {
    /*
      Domain
    */
    SupplierInvoice.Schema = zod_1.z.object({
        id: zod_1.z.string(),
        serialNumber: zod_1.z.string(),
        periodStart: zod_1.z.date(),
        periodEnd: zod_1.z.date(),
        documentHref: zod_1.z.string(),
    });
})(SupplierInvoice || (SupplierInvoice = {}));
exports.SupplierInvoice = SupplierInvoice;
