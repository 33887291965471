import { HandymanWorkingHours } from "@eljouren/domain/build";
import { format, formatDistance } from "date-fns";
import { sv as locale } from "date-fns/locale";
import __ from "../../utils/utils";

export default class WorkerDateHelper {
  constructor(private date: Date) {}

  static parse(time: string) {
    return new WorkerDateHelper(new Date(Date.parse(time)));
  }

  /**
   * Specify the format string using '$start' or '$end' where you want to insert
   * the respective values
   *
   * @param item
   * @param options
   * @returns
   */
  static fromTo(
    item: HandymanWorkingHours.Type | undefined,
    options: {
      whenNotSpecified: string;
      whenOffDuty: string;
      format: string;
    }
  ): string {
    if (!item) {
      return options.whenNotSpecified;
    }
    if (item.status === "offWork") {
      return options.whenOffDuty;
    }
    const { start, end } = item;
    const startText = new WorkerDateHelper(start).hourAndMinutes();
    const endText = new WorkerDateHelper(end).hourAndMinutes();
    return options.format.replace("$start", startText).replace("$end", endText);
  }

  static distance(date: number | Date, baseDate: number | Date) {
    return formatDistance(date, baseDate, { locale });
  }

  hourAndMinutes(): string {
    try {
      const formatStr = format(this.date, "HH:mm", { locale });
      return formatStr;
    } catch (er) {
      return "00:00";
    }
  }

  weekdayDayOfMonthAndMonth(): string {
    return __.capitalize(format(this.date, "EEEE, do MMM", { locale }));
  }
}
