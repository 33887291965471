import { useEffect } from "react";
import { BiRefresh } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import __ from "../../utils/utils";
import Logo from "../common/brand/Logo";
import AppTextButton from "../common/buttons/AppTextButton";

interface Props {
  className?: string;
  description?: string;
  devError: any;
  tryAgainLabel?: string;
  onTryAgain?(): void;
}

const SomethingWentWrong = (props: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      process.env.NODE_ENV === "development" ||
      process.env.NODE_ENV === "test"
    ) {
      console.error(props.devError);
    }
  }, [props.devError]);

  function onTryAgain() {
    if (props.onTryAgain) {
      props.onTryAgain();
    } else {
      navigate(0);
    }
  }

  return (
    <section
      className={__.classNames(
        "mx-auto flex max-w-md flex-col p-4",
        props.className
      )}
    >
      <header className="flex flex-col gap-2">
        <Logo wrapperClassName="mx-auto h-20 w-40" withColor />
        <h2 className="py-2 text-center text-2xl">Något gick tyvärr fel</h2>
      </header>
      <main className="mx-auto flex min-w-[250px] flex-col">
        <p className="text-lg">{props.description || "Okänt fel"}</p>
      </main>
      <footer className="flex justify-end p-2">
        <AppTextButton
          onClick={onTryAgain}
          className="flex items-center gap-2 py-2"
        >
          <BiRefresh size={35} />
          {props.tryAgainLabel || "Ladda om"}
        </AppTextButton>
      </footer>
    </section>
  );
};

export default SomethingWentWrong;
