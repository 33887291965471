"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderProduct = void 0;
var zod_1 = require("zod");
/*
    ToDo: Better, more descriptive name
*/
var WorkOrderProduct;
(function (WorkOrderProduct) {
    WorkOrderProduct.Schema = zod_1.z.object({
        id: zod_1.z.string(),
        name: zod_1.z.string(),
        unitPrice: zod_1.z.number().optional(),
        //priceBookEntryId: z.string(),
        //isCustom: z.boolean(),
    });
})(WorkOrderProduct || (WorkOrderProduct = {}));
exports.WorkOrderProduct = WorkOrderProduct;
