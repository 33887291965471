import {
  Handyman,
  HandymanWorkingHours,
  WorkOrder,
} from "@eljouren/domain/build";
import { UseQueryResult } from "react-query";
import { AppLoader } from "../../../components/common/loaders/AppLoader";
import DateHelper from "../../../_model/helpers/DateHelper";

interface Props {
  className?: string;
  workingHoursRes: UseQueryResult<HandymanWorkingHours.DictIndexedByStartOfDayType>;
  workOrderRes: UseQueryResult<WorkOrder.HandymanWithPermissionsType[]>;
  handyman: Handyman.Type;
}

const OldComponent = (props: Props) => {
  if (props.workingHoursRes.isLoading || props.workOrderRes.isLoading) {
    return <AppLoader className="mr-auto ml-0 h-6 w-6" />;
  }
  const data = props.workingHoursRes.data;
  if (data) {
    const length = Object.keys(data).length;
    if (length === 0) {
      return (
        <span className="text-sm">
          Inga arbetstimmar rapporterade de kommande två veckorna
        </span>
      );
    } else {
      const workingDaysTodayAndForward = Object.values(data)
        .filter((workingHours) => {
          const startOfWorkingHourDate = new DateHelper(workingHours.start)
            .startOfDay.date;
          const startOfTodayDate = new DateHelper().startOfDay.date;

          return (
            workingHours.status === "reported" &&
            Number(startOfWorkingHourDate) >= Number(startOfTodayDate)
          );
        })
        .sort((a, b) => Number(a.start) - Number(b.start))
        .map((workingHours) => workingHours.start);

      if (workingDaysTodayAndForward.length === 0) {
        return (
          <span className="text-sm">
            Inga tillgängliga datum de kommande två veckorna
          </span>
        );
      }

      return (
        <ul className="flex flex-wrap gap-2 text-sm">
          {workingDaysTodayAndForward.map((date) => {
            const helper = new DateHelper(date);
            return (
              <li
                className="rounded border border-black/50 p-1"
                key={date.getTime() + props.handyman.id}
              >
                {`${helper.dayOfMonth.toString().padStart(2, "0")}/${(
                  helper.month + 1
                )
                  .toString()
                  .padStart(2, "0")}`}
              </li>
            );
          })}
        </ul>
      );
    }
  }

  return <></>;
};

const SalesTeamWorkingHoursComponent = (props: Props) => {
  if (props.workingHoursRes.isLoading || props.workOrderRes.isLoading) {
    return <AppLoader className="mr-auto ml-0 h-6 w-6" />;
  }

  return <OldComponent {...props}></OldComponent>;

  /* const workingHours = props.workingHoursRes.data;
  const workOrders = props.workOrderRes.data;
  if (workingHours && workOrders) {
    const merger = new WorkOrdersAndWorkingHoursMerger({
      workingHours,
      workOrders,
    });
    const dates = eachDayOfInterval({
      start: new Date(),
      end: new DateHelper().add("weeks", 2).date,
    });

    const datesWithGaps = dates.filter((date) => merger.getScheduleGaps(date));

    if (!datesWithGaps.length) {
      return (
        <span className="text-sm">
          Inga tillgängliga datum de kommande två veckorna
        </span>
      );
    }
    return (
      <section>
        <header>
          <h3 className="text-lg">Luckor</h3>
        </header>
        <main>
          <ul className="flex flex-wrap gap-2 text-sm">
            {datesWithGaps.map((date) => {
              const gaps = merger.getScheduleGaps(date);
              if (!gaps) {
                return <></>;
              }
              const helper = new DateHelper(date);
              return (
                <li
                  className="rounded border p-1"
                  key={date.getTime() + props.handyman.id}
                >
                  <header>
                    <h3 className="text-base">
                      {`${helper.dayOfMonth.toString().padStart(2, "0")}/${(
                        helper.month + 1
                      )
                        .toString()
                        .padStart(2, "0")}`}
                    </h3>
                  </header>
                  <ul>
                    {gaps.map((gap) => (
                      <li
                        className="border-b p-1"
                        key={gap.start.getTime() + "-schedulegap"}
                      >
                        {new DateHelper(gap.start).timeInputFormat}-
                        {new DateHelper(gap.end).timeInputFormat}
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
        </main>
      </section>
    );
  } */
};

export default SalesTeamWorkingHoursComponent;
