import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { BiError } from "react-icons/bi";
import { FiCheckCircle, FiX } from "react-icons/fi";
import {
  useOnEnterClick,
  useOnEscapeClick,
} from "../../hooks/keyboard-event-hooks";
import __ from "../../utils/utils";
import { UUID } from "../../utils/UUID";
import { AppButton } from "../common/buttons/AppButton";

declare global {
  interface Window {
    modal: {
      alert(args: AlertArgs): void;
      confirm(args: ConfirmArgs): Promise<boolean>;
      toast(args: ToastArgs): void;
    };
  }
}

type AlertArgs = {
  title: string;
  prompt: string;
  typeOfAlert: "notification" | "error";
  buttonLabel?: string;
};

type AlertState = {
  type: "alert";
  args: AlertArgs;
};

type ConfirmArgs = {
  title: string;
  prompt: string;
  yesLabel?: string;
  noLabel?: string;
  callback?: (args: { answer: boolean }) => void;
};

type ConfirmState = {
  type: "confirm";
  args: ConfirmArgs;
};

type ToastArgs = {
  id?: string;
  title: string;
  prompt: string;
  timeVisibleInMs?: number;
  toastType: "success" | "error";
};

type ToastWithId = ToastArgs & { id: string };

type ToastState = {
  type: "toast";
  toasts: ToastWithId[];
};

type State = AlertState | ConfirmState | ToastState;

type ConfirmDialogAnswerArgs = { answer: boolean };

interface Props {
  className?: string;
}

const Modal = (props: Props) => {
  const [show, setShow] = useState<State | null>(null);
  const showRef = useRef<State | null>(show);
  const confirmPromiseResolveFunctionRef = useRef<
    ((answer: boolean) => void) | null
  >(null);

  useEffect(() => {
    window.modal = {
      alert: (args) => {
        setShow({ type: "alert", args });
      },
      confirm: (args) => {
        if (confirmPromiseResolveFunctionRef.current) {
          Promise.reject(confirmPromiseResolveFunctionRef.current);
        }

        let resolve: (value: boolean) => void;
        const promise = new Promise<boolean>((res, rej) => {
          resolve = res;
        });

        confirmPromiseResolveFunctionRef.current = resolve!;
        setShow({ type: "confirm", args });
        return promise;
      },
      toast: (args) => {
        const id = args.id ?? UUID.generate().value;
        const current = showRef.current;
        const newToast = { ...args, id };

        if (current?.type === "toast") {
          if (current.toasts.find((toast) => toast.id === id)) {
            return;
          }

          const copy = { ...current };
          copy.toasts.push(newToast);
          setShow(copy);
        } else {
          setShow({ type: "toast", toasts: [newToast] });
        }
      },
    };
  }, []);

  useEffect(() => {
    showRef.current = show;
  }, [show]);

  function close() {
    setShow(null);
  }

  function closeToast(toast: ToastWithId) {
    if (show?.type !== "toast") {
      return;
    }
    const copy = { ...show };
    const filtered = copy.toasts.filter((el) => el.id !== toast.id);
    copy.toasts = filtered;

    if (filtered.length > 0) {
      setShow(copy);
    } else {
      setShow(null);
    }
  }

  function answerConfirmDialog(args: ConfirmDialogAnswerArgs) {
    if (show?.type !== "confirm") {
      return;
    }
    if (show.args.callback) {
      show.args.callback(args);
    }

    if (confirmPromiseResolveFunctionRef.current) {
      confirmPromiseResolveFunctionRef.current(args.answer);
      confirmPromiseResolveFunctionRef.current = null;
    }

    setShow(null);
  }

  return (
    <AnimatePresence>
      {!show && <></>}
      {show?.type === "alert" && (
        <AlertDialog key="alert" {...show} close={close} />
      )}
      {show?.type === "confirm" && (
        <AlertDialog key="confirm" {...show} answer={answerConfirmDialog} />
      )}
      {show?.type === "toast" && (
        <ToastList
          key="toastList"
          toasts={show.toasts}
          closeToast={closeToast}
        />
      )}
    </AnimatePresence>
  );
};

type AlertDialogProps =
  | (AlertState & { close: () => void })
  | (ConfirmState & { answer: (args: ConfirmDialogAnswerArgs) => void });

const AlertDialog = (props: AlertDialogProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    ref.current?.focus();
  });
  useOnEnterClick({
    ref: ref,
    callback: () => {
      if (props.type === "alert") {
        props.close();
      }
    },
  });
  useOnEscapeClick(() => {
    if (props.type === "alert") {
      props.close();
    }
  });

  return (
    <motion.div
      className="fixed z-50 flex h-full w-full flex-col items-center justify-center bg-bg-base-layer/50 p-8"
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      role="alertdialog"
      aria-labelledby="alertTitle"
      aria-describedby="alertPrompt"
    >
      <div
        className={__.classNames(
          "pointer-events-auto flex w-96 max-w-full flex-col gap-2 rounded border bg-white/90 p-4 shadow-lg"
          //props.typeOfAlert === "notification" && "border-green-400",
          //props.typeOfAlert === "error" && "border-red-400"
        )}
        role="document"
        tabIndex={0}
        ref={ref}
      >
        <h2 id="alertTitle" className="text-lg">
          {props.args.title}
        </h2>
        <p id="alertPrompt" className="text-base text-gray-700">
          {props.args.prompt}
        </p>
        {props.type === "alert" && (
          <AppButton
            onClick={props.close}
            className="ml-auto mt-2 min-w-[100px]"
          >
            {props.args.buttonLabel ?? "OK"}
          </AppButton>
        )}
        {props.type === "confirm" && (
          <span className="ml-auto flex gap-2">
            <AppButton
              buttonMode="outline"
              onClick={() => {
                props.answer({ answer: false });
              }}
            >
              {props.args.noLabel ?? "Nej"}
            </AppButton>
            <AppButton
              onClick={() => {
                props.answer({ answer: true });
              }}
            >
              {props.args.yesLabel ?? "Ja"}
            </AppButton>
          </span>
        )}
      </div>
    </motion.div>
  );
};

type ToastListProps = {
  toasts: ToastWithId[];
  closeToast: (toast: ToastWithId) => void;
};

const ToastList = (props: ToastListProps) => {
  return (
    <motion.ul
      className="fixed z-50 mt-8 flex max-w-[min(90vw,500px)] flex-col-reverse gap-2 justify-self-center"
      exit={{
        opacity: 0.5,
      }}
    >
      {props.toasts.map((toast) => {
        return (
          <Toast
            key={`toastWithId${toast.id}`}
            {...toast}
            closeToast={props.closeToast}
          />
        );
      })}
    </motion.ul>
  );
};

type ToastProps = ToastWithId & { closeToast(toast: ToastWithId): void };

const Toast = (props: ToastProps) => {
  const durationInMs = props.timeVisibleInMs ?? 4000;
  const base = "#ffffff";
  const from = "#314a4e";
  const to = base;

  const timeoutRef = useRef<null | NodeJS.Timeout>(null);
  const timeLeftRef = useRef(durationInMs);

  useEffect(() => {
    const now = new Date();
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        props.closeToast(props);
      }, timeLeftRef.current);
    }

    return () => {
      if (timeoutRef.current) {
        const then = new Date();
        const timePassed = Number(then) - Number(now);
        const timeLeft = Math.max(timeLeftRef.current - timePassed, 0);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
        timeLeftRef.current = timeLeft;
      }
    };
  }, [timeLeftRef.current]);

  function close() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    props.closeToast(props);
  }

  const toastTitleId = `toastTitle${props.id}`;
  const toastDescriptionId = `toastDescription${props.id}`;

  return (
    <motion.div
      className="flex flex-col gap-2 overflow-hidden rounded border bg-white shadow-lg"
      role="alertdialog"
      layout
      aria-labelledby={toastTitleId}
      aria-describedby={toastDescriptionId}
      initial={{
        y: -100,
      }}
      animate={{
        y: 0,
      }}
      exit={{
        x: "calc(60vw + 100%)",
      }}
    >
      <span className="flex items-center justify-between gap-4 px-4 pt-4">
        <h2 id={toastTitleId} className="text-lg">
          {props.title}
        </h2>

        <button
          onClick={() => close()}
          className="z-40 col-start-1 row-start-1 ml-auto mb-auto"
        >
          <FiX size={20} />
        </button>
      </span>
      <motion.span
        className="grid grid-cols-[auto,minmax(0,1fr)] items-center gap-4 px-4 pb-4"
        initial={{
          background: `linear-gradient(to top, transparent 4px, ${base} 4px),linear-gradient(to left, ${from} 0%, ${to} 1%)`,
        }}
        animate={{
          background: `linear-gradient(to top, transparent 4px, ${base} 4px), linear-gradient(to left, ${from} 100%, ${to} 100%)`,
        }}
        transition={{
          duration: durationInMs / 1000,
        }}
      >
        {props.toastType === "success" && (
          <FiCheckCircle size={30} className="text-green-600" />
        )}
        {props.toastType === "error" && (
          <BiError size={30} className="text-red-600" />
        )}
        <p id={toastDescriptionId} className="text-base text-gray-700">
          {props.prompt}
        </p>
      </motion.span>
    </motion.div>
  );
};

export default Modal;
