import { motion } from "framer-motion";
import __ from "../utils/utils";

interface Props {
  className?: string;
  children?: React.ReactNode;
  isLoading: boolean;
}

const PageBanner = (props: Props) => {
  const from = "#ddfbe7";
  const to = "rgba(1, 136, 95, 0.202)";
  const notLoading = "#b7e3f280";

  return (
    <motion.aside
      className={__.classNames(
        "flex h-10 items-center border-t bg-blue-100 p-2 text-sm font-bold md:border-t-0 md:border-b md:bg-gray-100",
        props.className
      )}
      initial={{
        background: props.isLoading
          ? `linear-gradient(100deg, ${from} 0%, ${to} 1%)`
          : notLoading,
      }}
      animate={{
        background: props.isLoading
          ? `linear-gradient(100deg, ${from} 100%, ${to} 100%)`
          : notLoading,
      }}
      transition={
        props.isLoading
          ? {
              repeat: Infinity,
              repeatDelay: 0.8,
              duration: 0.5,
            }
          : undefined
      }
    >
      {props.children}
    </motion.aside>
  );
};

export default PageBanner;
