"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorLogType = void 0;
var zod_1 = require("zod");
var ErrorLogType;
(function (ErrorLogType) {
    var Enum;
    (function (Enum) {
        Enum["moreThanOneContactLinkedToEmail"] = "moreThanOneContactLinkedToEmail";
    })(Enum = ErrorLogType.Enum || (ErrorLogType.Enum = {}));
    ErrorLogType.EnumSchema = zod_1.z.nativeEnum(Enum);
})(ErrorLogType || (ErrorLogType = {}));
exports.ErrorLogType = ErrorLogType;
