import { AnimatePresence, motion, useReducedMotion } from "framer-motion";
import __ from "../../../utils/utils";

interface Props {
  className?: string;
}

export function AppLoader(props: Props) {
  const reducedMotion = useReducedMotion();

  return (
    <AnimatePresence initial>
      <motion.div
        data-test-id="app-loader"
        className={__.classNames(
          "m-auto rounded-full border-2 border-solid border-black",
          !reducedMotion && "border-b-0",
          props.className,
          !props.className && "h-8 w-8"
        )}
        animate={
          reducedMotion
            ? { opacity: [0.2, 1, 0.2] }
            : { rotate: 360, opacity: 1 }
        }
        transition={{
          repeat: Infinity,
          duration: reducedMotion ? 3 : 1,
          ease: "linear",
        }}
      />
    </AnimatePresence>
  );
}
