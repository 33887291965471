"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderContactInfo = void 0;
var zod_1 = require("zod");
var WorkOrderContactInfo;
(function (WorkOrderContactInfo) {
    WorkOrderContactInfo.Schema = zod_1.z.object({
        fullName: zod_1.z.string(),
        phone: zod_1.z.string(),
        phone2: zod_1.z.string().optional(),
        email: zod_1.z.string(),
    });
})(WorkOrderContactInfo || (WorkOrderContactInfo = {}));
exports.WorkOrderContactInfo = WorkOrderContactInfo;
