import { useContext } from "react";
import { useRepos } from "../../hooks/use-repos";
import HandymanWorkOrderRouteContext from "../../routes/worker/order/HandymanWorkOrderRouteContext";
import __ from "../../utils/utils";
import { SignedInContext } from "../auth-hocs/withWorkerCredentials";
import { AppButton } from "../common/buttons/AppButton";
import DatePicker from "../common/time/DatePicker";

const WorkOrderCheckInOutSection = () => {
  const { handyman } = useContext(SignedInContext);
  const { order, orderRes } = useContext(HandymanWorkOrderRouteContext);
  const { workOrderRepo } = useRepos();

  function onCheckInClick() {
    onCheckInOut("in");
  }
  function onCheckOutClick() {
    onCheckInOut("out");
  }

  async function onCheckInOut(type: "out" | "in") {
    try {
      await orderRes.mutate(async () => {
        if (!("geolocation" in navigator)) {
          window.modal.alert({
            title: "Position ej påslaget",
            prompt: "Du behöver ha position påslaget för påbörja ärendet",
            typeOfAlert: "error",
          });
          throw new Error("Geolocation not available");
        }

        const position = await new Promise<GeolocationPosition>(
          (resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, (er) => {
              onGeolocationError(er);
              reject(er);
            });
          }
        );

        await workOrderRepo.checkInOut(
          {
            workerId: handyman.id,
            orderId: order.orderId,
            position,
          },
          type
        );
      });
    } catch (ex) {
      //...
    }
  }

  function onGeolocationError(error: GeolocationPositionError) {
    switch (error.code) {
      case GeolocationPositionError.PERMISSION_DENIED:
        window.modal.alert({
          title: "Position ej påslaget",
          prompt: "Du behöver ha position påslaget för påbörja ärendet",
          typeOfAlert: "error",
        });
        break;
      case GeolocationPositionError.POSITION_UNAVAILABLE:
      case GeolocationPositionError.TIMEOUT:
        window.modal.alert({
          title: "Det gick inte att hämta platsinformation",
          prompt: "Vänligen försök igen senare.",
          typeOfAlert: "error",
        });
        break;
    }
  }

  return (
    <>
      <section className={__.classNames("flex w-full max-w-sm flex-col gap-4")}>
        <main className="flex w-full flex-col gap-4">
          <ButtonAndDatePickerSpan
            inputLabel="Incheckat"
            inputId={"checkIn"}
            isLoading={orderRes.isLoading && !order.checkIn}
            isAssignedThisOrder={order.allowedToHandleOrder}
            onClick={onCheckInClick}
            disabled={!!order.checkIn || order.isFinished}
            date={order.checkIn?.date}
            buttonLabel="Checka in"
            notSubmittedValue="Ej incheckat"
            inputAriaLabel="Datum för incheckning"
          />
          <ButtonAndDatePickerSpan
            inputLabel="Utcheckat"
            inputId={"checkOut"}
            isLoading={orderRes.isLoading && !!order.checkIn && !order.checkOut}
            isAssignedThisOrder={order.allowedToHandleOrder}
            onClick={onCheckOutClick}
            disabled={!order.checkIn || !!order.checkOut || order.isFinished}
            //disabled={false}
            date={order.checkOut?.date}
            buttonLabel="Checka ut"
            notSubmittedValue="Ej utcheckat"
            inputAriaLabel="Datum för utcheckning"
          />
        </main>
      </section>
      {/*    {order.checklistId && (
        <footer className="flex justify-end">
          <AppButton buttonMode="outline" >Visa checklista</AppButton>
        </footer>
      )}
 */}
    </>
  );
};

const ButtonAndDatePickerSpan = (props: {
  isAssignedThisOrder: boolean;
  inputId: string;
  onClick?(): void;
  disabled: boolean;
  date?: Date;
  buttonLabel: string;
  notSubmittedValue: string;
  inputAriaLabel: string;
  inputLabel: string;
  isLoading: boolean;
}) => {
  const { orderRes } = useContext(HandymanWorkOrderRouteContext);

  return (
    <span className="flex h-11 flex-col justify-center">
      {!props.date && (
        <AppButton
          loading={props.isLoading}
          onClick={props.onClick}
          className="mt-auto w-full text-sm"
          type="button"
          disabled={
            props.disabled || orderRes.isLoading || !props.isAssignedThisOrder
          }
        >
          {props.buttonLabel}
        </AppButton>
      )}
      {props.date && (
        <p className="flex flex-col">
          <label htmlFor={props.inputId} className="text-sm">
            {props.inputLabel}
          </label>
          <DatePicker
            type="datetime-local"
            className="w-full text-xs"
            date={props.date}
            htmlAttributes={{
              readOnly: true,
              id: props.inputId,
              "aria-label": props.inputAriaLabel,
            }}
          />
        </p>
      )}
      {/*    {(!props.date || orderRes.isLoading) && (
        <AppTextField
          htmlAttributes={{
            value: orderRes.isLoading ? "" : props.notSubmittedValue,
            id: props.inputId,
            readOnly: true,
            disabled: orderRes.isLoading,
            "aria-label": props.inputAriaLabel,
          }}
        />
      )} */}
    </span>
  );
};

export default WorkOrderCheckInOutSection;
