import { TEntries } from "../types/types";

namespace __ {
  /*
    REACT
  */
  export function classNames(...classNames: any[]): string | undefined {
    return (
      classNames.filter((str) => typeof str === "string").join(" ") || undefined
    );
  }

  /*
    ARRAYS
  */

  export function asArray<T>(value: T | T[]): T[] {
    return ([] as T[]).concat(value);
  }

  export function intersection(a1: any[], a2: any[]) {
    return a1.filter((value) => a2.includes(value));
  }

  export function isArray<T = any>(obj: any): obj is T[] {
    return Array.isArray(obj);
  }

  export function arrayCombine<T>(arrays: T[][]): T[] {
    return arrays.reduce((acc, curr) => acc.concat(curr), []);
  }

  export function join<T>(
    array: T[],
    options: {
      separator: string;
      beforeLast?: string;
      oxford?: boolean;
      custom?: (
        element: T,
        index: number,
        accumulator: string
      ) => string | undefined;
    }
  ) {
    const len = array.length;
    if (len === 0) {
      return "";
    }
    const { separator, beforeLast, oxford, custom } = options;
    return array.slice(1).reduce((accumulator, current, index) => {
      if (custom) {
        const customReturn = custom(current, index, accumulator);
        if (customReturn !== undefined) {
          return accumulator + customReturn + current;
        }
      }

      const lastElem = index === len - 2;
      if (lastElem && beforeLast) {
        if (oxford) {
          return accumulator + separator + beforeLast + current;
        } else {
          return accumulator + beforeLast + current;
        }
      } else {
        return accumulator + separator + current;
      }
    }, "" + array[0]);
  }

  /*
  OBJECTS
  */

  export function objectCombine<T extends object>(objects: T[]): T {
    return objects.reduce((acc, curr) => Object.assign(acc, curr), {} as T);
  }

  export function entries<T extends object>(obj: T): TEntries<T> {
    return Object.entries(obj) as TEntries<T>;
  }

  export function objectMap<T extends object, NewValue>(
    obj: T,
    callback: (val: T[keyof T], key: keyof T, index: number) => NewValue
  ): Record<keyof T, NewValue> {
    const entries = __.entries(obj).map(([k, v], i) => {
      return [k, callback(v, k, i)];
    });

    return Object.fromEntries(entries);
  }
  export function objectKeyMap<T extends object, NewKey extends string>(
    obj: T,
    callback: (key: keyof T, val: T[keyof T], index: number) => NewKey
  ): Record<NewKey, T[keyof T]> {
    const entries = __.entries(obj).map(([k, v], i) => {
      return [callback(k, v, i), v];
    });

    return Object.fromEntries(entries);
  }

  export function objectFilter<T extends object>(
    obj: T,
    callback: (val: T[keyof T], key: keyof T, index: number) => boolean
  ): {
    [k: string]: T[keyof T];
  } {
    const entries = __.entries(obj).filter(([k, v], i) => {
      return callback(v, k, i);
    });

    return Object.fromEntries(entries);
  }

  /*
    NULL CHECKS
    */

  type TNotNull<T> = Exclude<T, undefined | null>;

  export function notNull<T>(obj: T): obj is TNotNull<T> {
    const type = typeof obj;
    if (type === "boolean") return true;
    if (type === "number") return true;
    if (type === "string") return true;
    return !!obj;
  }

  export function isNull<T>(
    obj: T | null | undefined
  ): obj is null | undefined {
    return !notNull(obj);
  }

  /*
  STRINGS
  */

  export function capitalize<T extends string>(str: T): Capitalize<T> {
    return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>;
  }
}

export default __;
