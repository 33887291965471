import { useContext } from "react";
import { useBundledState } from "../../../hooks/hooks";
import __ from "../../../utils/utils";
import CalendarEvent from "../../../_model/helpers/calendar/CalendarEvent";
import DateHelper from "../../../_model/helpers/DateHelper";
import { CalendarContext } from "../Calendar";

interface Props {
  className?: string;
  selectedDay: DateHelper;
}

const CalendarDayViewEventList = (props: Props) => {
  const { calendarHelper, isLoading, RenderDayViewItem } =
    useContext(CalendarContext);
  const selectedEvent = useBundledState<{
    index: number;
    event: CalendarEvent;
  } | null>(null);

  function onEventClick(event: CalendarEvent, index: number) {
    if (
      selectedEvent.value &&
      selectedEvent.value.event.props.id === event.props.id
    ) {
      selectedEvent.set(null);
    } else {
      selectedEvent.set({ event, index });
    }
  }

  function getActiveEvents(): CalendarEvent[] {
    return calendarHelper.events.for(props.selectedDay.date);
  }

  return (
    <ul
      key="eventList"
      className={__.classNames(
        "flex flex-col gap-1 overflow-y-auto border border-main-bg-light p-2 transition-all",
        isLoading && "pointer-events-none bg-main-bg-dark/20",
        !isLoading && "bg-main-bg-dark/50"
      )}
    >
      {getActiveEvents().map((event, i) => {
        if (RenderDayViewItem) {
          return <RenderDayViewItem key={event.props.id} event={event} />;
        }

        const { start, end } = event.props;
        const startHelper = new DateHelper(start);
        const endHelper = new DateHelper(end);

        return (
          <li
            onClick={() => {
              if (event.props.onClick) {
                event.props.onClick();
              } else {
                onEventClick(event, i);
              }
            }}
            key={event.props.id}
            //layoutId={event.props.id}
            className={__.classNames(
              "flex max-w-full flex-col gap-2 truncate rounded-sm border border-blue-400 bg-orange-200 pl-1 shadow-md"
            )}
          >
            <span className="font-semibold">{event.props.name}</span>
            <span
              className={__.classNames(
                "italic",
                event.hasPassed && "text-red-600"
              )}
            >{`${startHelper.hourAndMinutesLabel} - ${endHelper.hourAndMinutesLabel}`}</span>
            {event.props.description && (
              <span className="truncate">{event.props.description}</span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default CalendarDayViewEventList;
