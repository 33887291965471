import { Handyman } from "@eljouren/domain/build";
import { useContext, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BiTimeFive } from "react-icons/bi";
import { FiCalendar } from "react-icons/fi";
import { useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import withSalesCredentials from "../../../components/auth-hocs/withSalesCredentials";
import { SignedInContext } from "../../../components/auth-hocs/withWorkerCredentials";
import HandymanCalendar from "../../../components/calendar/HandymanCalendar";
import LoadingContent from "../../../components/common/loaders/LoadingContent";
import AppTabs from "../../../components/common/tabs/AppTabs";
import HandymanContextProvider from "../../../components/handyman-context/HandymanContextProvider";
import PageHeader from "../../../components/layout/PageHeader";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import { useRepos } from "../../../hooks/use-repos";
import { __tsx } from "../../../utils/tsxUtils";
import { TSignedInWorkerState } from "../../../_model/repos/interfaces/IAuthRepo";
import { HandymanLandingPageContext } from "../../worker/landing/HandymanLandingPage";
import HandymanWorkingHoursTab from "../../worker/landing/workings-hours/HandymanWorkingHoursTab";

enum Tab {
  workingHours = "Arbetstider",
  calendar = "Kalender",
}

interface Props extends TSignedInWorkerState {
  className?: string;
}

const SalesTeamPartnerListSingleRoute = (props: Props) => {
  const { workerId } = useParams();
  const navigate = useNavigate();
  const { handymanRepo: workerRepo } = useRepos();

  const workerRes = useQuery(["handyman", workerId], () =>
    workerRepo.fetchHandyman(workerId || "")
  );

  useEffect(() => {
    if (!workerId) {
      navigate("/");
    }
  }, [workerId, navigate]);

  return (
    <LoadingContent renderContentSeparately loading={workerRes.isLoading}>
      <section className="mx-auto grid h-full w-full grid-rows-[auto,minmax(0,1fr)] flex-col overflow-hidden">
        <PageHeader
          heading={
            workerRes.data
              ? `${workerRes.data?.firstName} ${workerRes.data?.lastName}`
              : ""
          }
        />

        {__tsx.renderOne({ workerRes }, [
          ({ workerRes }) =>
            workerRes.isError && (
              <p className="p-4">
                Det gick inte att hämta denna hantverkare just nu. Vänligen
                försök igen senare!
              </p>
            ),
          ({ workerRes }) =>
            !!workerRes.data && (
              <AfterFetchingWorker
                handyman={workerRes.data}
              ></AfterFetchingWorker>
            ),
        ])}
      </section>
    </LoadingContent>
  );
};

const AfterFetchingWorker = (props: { handyman: Handyman.Type }) => {
  const ctx = useContext(SignedInContext);

  const [params] = useSearchParams();

  function getTab() {
    const page = params.get("sida");
    switch (page) {
      case "kalender":
        return Tab.calendar;
      default:
        return Tab.workingHours;
    }
  }

  const tab = getTab();

  return (
    <HandymanLandingPageContext.Provider
      value={{
        isViewingOwnProfile: false,
        handyman: props.handyman,
        signedInWorker: ctx.handyman,
      }}
    >
      <main className="flex w-full grow flex-col overflow-y-auto">
        <AppTabs
          tabs={Object.values(Tab)}
          className="row-start-1 md:row-start-2"
          mainClassName="p-2 md:p-3 lg:p-4"
          selectedTab={tab}
          addPadding
          icon={(tab) => {
            const size = 20;
            switch (tab) {
              case Tab.calendar:
                return <FiCalendar size={size} />;
              case Tab.workingHours:
              default:
                return <BiTimeFive size={size} />;
            }
          }}
          searchParams={(tab) => {
            let page: string;
            switch (tab) {
              case Tab.calendar:
                page = "kalender";
                break;
              case Tab.workingHours:
              default:
                page = "arbetstider";
                break;
            }

            // "sida" is Swedish for page
            return {
              sida: page,
            };
          }}
        >
          {tab === Tab.workingHours && (
            <ErrorBoundary
              fallbackRender={(props) => (
                <SomethingWentWrong
                  devError={props.error}
                  description="Något gick fel när vi skulle visa arbetstiderna. Vänligen försök igen senare!"
                />
              )}
            >
              <HandymanContextProvider
                handymanId={props.handyman.id}
                pageIndexSearchParamKey="whsIndex"
              >
                <HandymanWorkingHoursTab noEdit />
              </HandymanContextProvider>
            </ErrorBoundary>
          )}
          {tab === Tab.calendar && (
            <ErrorBoundary
              fallbackRender={(props) => (
                <SomethingWentWrong
                  devError={props.error}
                  description="Något gick fel när vi skulle visa kalendern. Vänligen försök igen senare!"
                />
              )}
            >
              <HandymanContextProvider
                handymanId={props.handyman.id}
                pageIndexSearchParamKey="csIndex"
              >
                <HandymanCalendar defaultView="grid" />;
              </HandymanContextProvider>
            </ErrorBoundary>
          )}
        </AppTabs>
      </main>
    </HandymanLandingPageContext.Provider>
  );
};

export default withSalesCredentials(SalesTeamPartnerListSingleRoute);
