import { useQuery } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import withWorkerCredentials from "../../../components/auth-hocs/withWorkerCredentials";
import LoadingContent from "../../../components/common/loaders/LoadingContent";
import { useRepos } from "../../../hooks/use-repos";
import { TWorkerSignInData } from "../../../_model/schemas-and-types/repo-schemas";
import HandymanRouteOnceSignedIn from "./HandymanRouteOnceSignedIn";

interface Props extends TWorkerSignInData {}

/*
  ToDo: Refactor into not using "WorkerRouteOnceSignedIn". Makes no sense.
*/
const HandymanRoute = (props: Props) => {
  const [params] = useSearchParams();
  const workerId: string | null = params.get("id");

  const { state: unknownState } = useLocation();
  const state = (unknownState as any) || {};
  const { handymanRepo: workerRepo } = useRepos();

  const { isLoading, data } = useQuery(
    ["handyman", workerId],
    async () => {
      if (workerId) {
        return workerRepo.fetchHandyman(workerId);
      } else {
        return undefined;
      }
    },
    { initialData: state.worker }
  );

  return (
    <LoadingContent loading={isLoading} renderContentSeparately>
      <HandymanRouteOnceSignedIn signInData={props} otherWorker={data} />
    </LoadingContent>
  );
};

export default withWorkerCredentials(HandymanRoute);
