"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Checklist = void 0;
var zod_1 = require("zod");
var Checklist;
(function (Checklist) {
    /*
      Listitem
    */
    // Domain
    Checklist.ListItemSchema = zod_1.z.object({
        id: zod_1.z.string(),
        name: zod_1.z.string(),
        type: zod_1.z.enum(["yes/no", "text", "multipleChoice"]),
        sortOrder: zod_1.z.number(),
        answer: zod_1.z.union([zod_1.z.string(), zod_1.z.boolean()]).nullable(),
        comment: zod_1.z.string().nullable(),
        allowComment: zod_1.z.boolean(),
        required: zod_1.z.boolean(),
        options: zod_1.z.string().array().nullable(),
    });
    // Domain
    Checklist.Schema = Checklist.ListItemSchema.array();
})(Checklist || (Checklist = {}));
exports.Checklist = Checklist;
