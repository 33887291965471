import { WorkOrder } from "@eljouren/domain/build";
import { useRef } from "react";
import { useContext } from "react";
import { BiCalendar } from "react-icons/bi";
import { FiMail, FiPhone, FiUser, FiX } from "react-icons/fi";
import { GiStairs } from "react-icons/gi";
import { IoMdKeypad } from "react-icons/io";
import { SiGooglemaps } from "react-icons/si";
import { AppButton } from "../../components/common/buttons/AppButton";
import AppTextButton from "../../components/common/buttons/AppTextButton";
import IconAndText, { IconText } from "../../components/common/IconAndText";
import LabelAndText from "../../components/common/LabelAndText";
import { AppLoader } from "../../components/common/loaders/AppLoader";
import WorkOrderImages from "../../components/customer-order/WorkOrderImages";
import { WorkOrderFileContext } from "../../components/order-image-wrapper/WorkOrderFileContextProvider";
import __ from "../../utils/utils";
import DateHelper from "../../_model/helpers/DateHelper";

type Image = {
  src: string;
};

interface Props {
  className?: string;
  workOrder: Pick<
    WorkOrder.CustomerType,
    | "description"
    | "additionalInformation"
    | "startDate"
    | "location"
    | "contact"
    | "confirmedByCustomer"
    | "orderId"
  >;
  images: Image[] | "useImageContext";
  allowImageHandling: boolean;
  uploadImageLabel: string;
}

/* 
  ToDo: Show payment and invoice info
*/
const CustomerWorkOrderOverviewSection = (props: Props) => {
  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const imgCtx = useContext(WorkOrderFileContext);
  const workOrder = props.workOrder;
  function getLocationString() {
    return `${workOrder.location.street}, ${workOrder.location.postalCode} ${workOrder.location.city}`;
  }

  function getDateTimeString() {
    return new DateHelper(workOrder.startDate).dateTimeInputFormat.replace(
      "T",
      " "
    );
  }

  const phoneIconTexts: IconText[] = [{ label: workOrder.contact.phone }];
  if (workOrder.contact.phone2) {
    phoneIconTexts.push({
      label: workOrder.contact.phone2,
    });
  }

  const images =
    props.images === "useImageContext"
      ? imgCtx.fileRes.query.data ?? []
      : props.images;

  function openImageDialog() {
    dialogRef.current?.showModal();
  }

  function closeImageDialog() {
    dialogRef.current?.close();
  }

  const imagesLoading =
    props.images === "useImageContext" ? imgCtx.fileRes.query.isLoading : false;
  const imagesError =
    props.images === "useImageContext" ? imgCtx.fileRes.query.isError : false;

  return (
    <section
      //    as="section"
      className={__.classNames(
        "mx-auto flex h-full w-full flex-col gap-16",
        props.className
      )}
    >
      {props.allowImageHandling && (
        <dialog ref={dialogRef} className="h-full w-full">
          <section className="h-full w-full rounded bg-white p-4">
            <header className="flex justify-between">
              <h3 className="text-lg">Hantera bilder</h3>
              <AppTextButton
                aria-label="Stäng bilddialog"
                onClick={closeImageDialog}
              >
                <FiX size={35} />
              </AppTextButton>
            </header>
            <main>
              <WorkOrderImages
                uploadLabel={props.uploadImageLabel}
                allowDeleting={props.allowImageHandling}
                allowUploading={props.allowImageHandling}
                orderId={workOrder.orderId}
              />
            </main>
          </section>
        </dialog>
      )}
      {/* 
			Nested sections - not sure what element to use.

			Divs? Fieldsets? Something else?
		*/}
      <section className={__.classNames("flex flex-col gap-2")}>
        <header>
          <h3 className="text-lg">Arbetsorder</h3>
        </header>
        <main className="flex flex-col gap-2">
          <LabelAndText
            id="orderAdditionalInformation"
            label="Utförlig uppdragsbeskrivning"
            text={workOrder.additionalInformation}
          />
        </main>
      </section>

      <section className="flex flex-col gap-2">
        <header>
          <h3 className="text-lg">Tid och plats</h3>
        </header>
        <main className="flex flex-col gap-2">
          <IconAndText
            ariaLabel="Plats för arbetsorder"
            id="orderLocation"
            text={{ label: getLocationString() }}
            Icon={(props) => <SiGooglemaps {...props} size={25} />}
          />
          {!!workOrder.location.doorCode && (
            <IconAndText
              ariaLabel="Portkod för arbetsorder"
              id="orderDoorCode"
              text={{ label: workOrder.location.doorCode }}
              Icon={(props) => <IoMdKeypad {...props} size={25} />}
            />
          )}
          {!!workOrder.location.floor && (
            <IconAndText
              ariaLabel="Våning för arbetsorder"
              id="orderFloor"
              text={{ label: workOrder.location.floor }}
              Icon={(props) => <GiStairs {...props} size={25} />}
            />
          )}
          <IconAndText
            ariaLabel="Datum och tid för arbetsorder"
            id="orderTime"
            text={{ label: getDateTimeString() }}
            Icon={(props) => <BiCalendar {...props} size={25} />}
          />
        </main>
      </section>
      <section className="flex flex-col gap-2">
        <header>
          <h3 className="text-lg">Kontakt</h3>
        </header>
        <main className="flex flex-col gap-2">
          <IconAndText
            ariaLabel="Namn på kund"
            id="orderCustomerName"
            text={{ label: workOrder.contact.fullName }}
            Icon={(props) => <FiUser {...props} size={25} />}
          />
          <IconAndText
            ariaLabel="E-post till kund"
            id="orderCustomerMail"
            text={{
              label: workOrder.contact.email,
            }}
            Icon={(props) => <FiMail {...props} size={25} />}
          />

          <IconAndText
            ariaLabel="Telefonnummer till kund"
            id="orderCustomerPhone"
            text={phoneIconTexts}
            Icon={(props) => <FiPhone {...props} size={25} />}
          />
        </main>
      </section>
      {(!!images.length ||
        props.workOrder.confirmedByCustomer ||
        props.allowImageHandling) && (
        <section className="flex flex-col gap-2">
          <header>
            <h3 className="text-lg">Bilder</h3>
          </header>
          <main className="flex w-full flex-col gap-2">
            {!!images.length && !imagesLoading && !imagesError && (
              <ul className="flex w-auto gap-4 overflow-auto rounded border p-2">
                {images.map((img, i) => (
                  <li
                    key={img.src + i}
                    className="h-24 min-h-[6rem] w-40 min-w-[10rem] rounded"
                  >
                    <img
                      alt="Bild uppladdad till arbetsorder"
                      src={img.src}
                      className="h-full w-full rounded object-cover"
                    />
                  </li>
                ))}
              </ul>
            )}
            {!images.length && !imagesError && !imagesLoading && (
              <p className="text-base">Inga bilder uppladdade till uppdraget</p>
            )}
            {imagesError && <p>Det gick inte att hämta bilderna just nu</p>}
            {imagesLoading && !imagesError && (
              <AppLoader className="mr-auto ml-3 h-6 w-6" />
            )}
          </main>
          {props.allowImageHandling && (
            <footer className="flex justify-end">
              <AppButton buttonMode="outline" onClick={openImageDialog}>
                Hantera bilder
              </AppButton>
            </footer>
          )}
        </section>
      )}
    </section>
  );
};

export default CustomerWorkOrderOverviewSection;
