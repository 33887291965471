import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useReactQueryBugQuickFix } from "../../hooks/hooks";
import { useRepos } from "../../hooks/use-repos";
import { __tsx } from "../../utils/tsxUtils";
import LoadingContent from "../common/loaders/LoadingContent";
import SomethingWentWrong from "../on-error/SomethingWentWrong";

const withCustomerGuid = (WrappedComponent: () => React.ReactElement) => () => {
  const { authRepo } = useRepos();
  const params = useParams();
  const workOrderGuid = params["workOrderGuid"] || "";

  useReactQueryBugQuickFix();

  const res = useQuery(
    ["customerSignInState", workOrderGuid],
    async () => {
      const res = await authRepo.customerAuthentication(workOrderGuid);
      return res;
    },
    {
      staleTime: Infinity,
    }
  );

  return (
    <LoadingContent loading={res.isLoading} renderContentSeparately>
      {__tsx.renderOne(res, [
        (res) =>
          res.isError && (
            <section className="mx-auto flex">
              <main>
                <SomethingWentWrong
                  devError={res.error}
                  description="Vi kan för tillfället inte säkerställa att du har rätt att se den här sidan. Vänligen försök igen senare!"
                />
              </main>
            </section>
          ),
        (res) =>
          res.data === 404 && (
            <section className="mx-auto flex">
              <main>
                <SomethingWentWrong
                  devError={res.error}
                  description="Denna order finns tyvärr inte. Du kommer åt din order genom att klicka på länken vi skickar ut till dig genom SMS eller e-post."
                />
              </main>
            </section>
          ),
        (res) => res.data === 200 && <WrappedComponent />,
      ])}
    </LoadingContent>
  );
};

export default withCustomerGuid;
