import { useContext } from "react";
import { BiCalendar } from "react-icons/bi";
import { FiMail, FiPhone, FiUser } from "react-icons/fi";
import { GiStairs } from "react-icons/gi";
import { IoMdKeypad } from "react-icons/io";
import { SiGooglemaps } from "react-icons/si";
import IconAndText from "../../../components/common/IconAndText";
import LabelAndText from "../../../components/common/LabelAndText";
import PageSection from "../../../components/common/PageSection";
import IpisFileQueryList from "../../../components/customer-order/handyman/IpisFileQueryList";
import WorkOrderCheckInOutSection from "../../../components/customer-order/WorkOrderCheckInOutSection";
import __ from "../../../utils/utils";
import DateHelper from "../../../_model/helpers/DateHelper";
import HandymanWorkOrderRouteContext from "./HandymanWorkOrderRouteContext";

interface Props {
  className?: string;
}

const HandymanWorkOrderOverviewSection = (props: Props) => {
  const { order, serviceContractFilesRes } = useContext(
    HandymanWorkOrderRouteContext
  );

  function getLocationString() {
    return `${order.location.street}, ${order.location.postalCode} ${order.location.city}`;
  }

  function getDateTimeString() {
    return new DateHelper(order.startDate).dateTimeInputFormat.replace(
      "T",
      " "
    );
  }

  function googleMapsUrl(): string {
    const address = getLocationString();
    const base = "https://www.google.com/maps/search/?";
    const params = new URLSearchParams({
      api: "1",
      query: address,
    });
    return `${base}${params}`;
  }

  const hasEndCustomerContactInfo =
    !!order.endCustomer.name || !!order.endCustomer.phone;

  return (
    <section
      className={__.classNames(
        "mx-auto grid w-full grid-cols-1 gap-4 xl:grid xl:grid-cols-2 xl:bg-bg-base-layer",

        props.className
      )}
    >
      {/* 
			Nested sections - not sure what element to use.

			Divs? Fieldsets? Something else?
		*/}
      <PageSection
        as="section"
        className={__.classNames(
          "flex flex-col gap-2"
          //!order.serviceContract && "xl:row-span-2",
          //!!order.serviceContract && "xl:row-span-1"
        )}
      >
        <header>
          <h3 className="text-lg">Arbetsorder</h3>
        </header>
        <main className="flex flex-col gap-2">
          <LabelAndText
            id="orderAdditionalInformation"
            label="Utförlig uppdragsbeskrivning"
            text={order.additionalInformation}
          />

          <LabelAndText
            id="orderTypeOfService"
            label="Tjänst"
            text={order.typeOfService}
          />
          {!!order.categories.length && (
            <LabelAndText
              id="orderCategories"
              label="Kategorier"
              text={order.categories
                .map((category) => category.name)
                .join(", ")}
            />
          )}
        </main>
      </PageSection>
      <PageSection
        as="section"
        className={__.classNames("flex flex-col gap-2 xl:col-start-1")}
      >
        <header>
          <h3 className="text-lg">Uppdragssamordnare</h3>
        </header>
        <main className="flex flex-col gap-2">
          {order.coordinator.firstName && order.coordinator.lastName && (
            <IconAndText
              ariaLabel="Namn på uppdragssamordnare"
              id="orderCoordinatorName"
              text={{
                label: `${order.coordinator.firstName} ${order.coordinator.lastName}`,
              }}
              Icon={(props) => <FiUser {...props} size={25} />}
            />
          )}

          <IconAndText
            ariaLabel="Telefonnummer till uppdragssamordnare"
            id="orderCoordinatorPhone"
            text={
              order.coordinator.phone
                ? {
                    label: order.coordinator.phone,
                    href: `tel:${order.coordinator.phone}`,
                  }
                : {
                    label: "-",
                  }
            }
            Icon={(props) => <FiPhone {...props} size={25} />}
          />

          <IconAndText
            ariaLabel="E-post till uppdragssamordnare"
            id="orderCoordinatorMail"
            text={
              order.coordinator.email
                ? {
                    label: order.coordinator.email,
                    href: `mailto:${order.coordinator.email}`,
                  }
                : { label: "-" }
            }
            Icon={(props) => <FiMail {...props} size={25} />}
          />

          <LabelAndText
            id="orderInternalDescription"
            label="Kommentar"
            text={order.internalDescription ?? "-"}
          />
        </main>
      </PageSection>
      <PageSection
        as="section"
        className="row-start-1 flex flex-col gap-2 xl:col-start-2"
      >
        <header>
          <h3 className="text-lg">Tid och plats</h3>
        </header>
        <main className="flex flex-col gap-2">
          <IconAndText
            ariaLabel="Plats för arbetsorder"
            id="orderLocation"
            text={{ label: getLocationString(), href: googleMapsUrl() }}
            Icon={(props) => <SiGooglemaps {...props} size={25} />}
          />
          {!!order.location.doorCode && (
            <IconAndText
              ariaLabel="Portkod för arbetsorder"
              id="orderDoorCode"
              text={{ label: order.location.doorCode }}
              Icon={(props) => <IoMdKeypad {...props} size={25} />}
            />
          )}
          {!!order.location.floor && (
            <IconAndText
              ariaLabel="Våning för arbetsorder"
              id="orderFloor"
              text={{ label: order.location.floor }}
              Icon={(props) => <GiStairs {...props} size={25} />}
            />
          )}
          <IconAndText
            ariaLabel="Datum och tid för arbetsorder"
            id="orderTime"
            text={{ label: getDateTimeString() }}
            Icon={(props) => <BiCalendar {...props} size={25} />}
          />
          <WorkOrderCheckInOutSection />
        </main>
      </PageSection>
      <PageSection
        as="section"
        className="flex flex-col gap-2 xl:col-start-2"
        data-has-customer-contact-info={
          hasEndCustomerContactInfo ? "true" : "false"
        }
      >
        <header>
          <h3 className="text-lg">Kund</h3>
        </header>
        <main className="flex flex-col gap-2">
          {hasEndCustomerContactInfo && (
            <>
              <IconAndText
                ariaLabel="Namn på kund"
                id="orderEndCustomerName"
                text={{ label: order.endCustomer.name ?? "-" }}
                Icon={(props) => <FiUser {...props} size={25} />}
              />

              <IconAndText
                ariaLabel="Telefonnummer till kund"
                id="orderEndCustomerPhone"
                text={{
                  label: order.endCustomer.phone ?? "-",
                  href: order.endCustomer.phone
                    ? `tel:${order.endCustomer.phone}`
                    : undefined,
                }}
                Icon={(props) => <FiPhone {...props} size={25} />}
              />
            </>
          )}
          {!hasEndCustomerContactInfo && <p>Kontakta IPIS för kunduppgifter</p>}
        </main>
      </PageSection>

      {!!order.serviceContract && (
        <PageSection as="section" className="flex flex-col gap-2 xl:col-span-2">
          <header>
            <h3 className="text-lg">Avtal</h3>
          </header>
          <main className="flex flex-col gap-2">
            <LabelAndText
              id="orderServiceContractDescription"
              label="Beskrivning"
              text={order.serviceContract.description ?? ""}
            />
            <IpisFileQueryList res={serviceContractFilesRes} />
          </main>
        </PageSection>
      )}
    </section>
  );
};

export default HandymanWorkOrderOverviewSection;
