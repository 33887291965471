"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Handyman = void 0;
var zod_1 = require("zod");
var HandymanProfession_1 = require("./HandymanProfession");
var Handyman;
(function (Handyman) {
    /*
      If we introduce other users this should be moved
    */
    var UserType;
    (function (UserType) {
        UserType["worker"] = "Worker";
        UserType["customer"] = "Customer";
    })(UserType = Handyman.UserType || (Handyman.UserType = {}));
    // Domain
    Handyman.Schema = zod_1.z.object({
        id: zod_1.z.string().min(5),
        //username: z.string(),
        firstName: zod_1.z.string(),
        lastName: zod_1.z.string(),
        email: zod_1.z.string().email(),
        phone: zod_1.z.string(),
        city: zod_1.z.string(),
        street: zod_1.z.string(),
        country: zod_1.z.string(),
        postalCode: zod_1.z.string(),
        userType: zod_1.z.literal(UserType.worker),
        companyId: zod_1.z.string(),
        companyName: zod_1.z.string(),
        companyRole: zod_1.z.enum(["handyman", "supervisor"]),
        profession: HandymanProfession_1.HandymanProfession.Schema,
        activeAreas: zod_1.z.string().array(),
        qualifications: zod_1.z.string().array(),
        permittedToViewPrices: zod_1.z.boolean(),
    });
    Handyman.EditableSchema = Handyman.Schema.pick({
        firstName: true,
        lastName: true,
        phone: true,
        activeAreas: true,
    }).partial();
})(Handyman || (Handyman = {}));
exports.Handyman = Handyman;
