import {
  addWeeks,
  eachDayOfInterval,
  endOfWeek,
  getWeek,
  isFuture,
  isToday,
  startOfWeek,
  subWeeks,
} from "date-fns";
import { sv as locale } from "date-fns/locale";
import DateHelper from "./DateHelper";

type DatePeriodSettings = {
  weeksInPeriod: number;
};

export default class DatePeriod {
  private readonly settings: DatePeriodSettings;
  e;

  private cache: { [index: string]: Date[] } = {};

  constructor(settings?: Partial<DatePeriodSettings>) {
    const defaultSettings: DatePeriodSettings = {
      weeksInPeriod: 2,
    };
    Object.assign(defaultSettings, settings || {});
    this.settings = defaultSettings;
  }

  getPeriodAtIndex(index: number): Date[] {
    /*  if (index < 0) {
      throw new Error("Invalid index");
    } */

    if (!(index in this.cache)) {
      const now = new DateHelper(new Date());
      const then = now.add("weeks", index * this.settings.weeksInPeriod);
      this.cache[index] = this.calculatePeriodFrom(then.date);
    }

    return this.cache[index];
  }
  getPeriodAtIndexWithoutPastDates(index: number): Date[] {
    return this.getPeriodAtIndex(index).filter(
      (date) => isToday(date) || isFuture(date)
    );
  }

  private getWeekIndexOfPeriodFromWeekNumber(weekNumber: number) {
    const mod = weekNumber % this.settings.weeksInPeriod;
    if (mod === 0) {
      return this.settings.weeksInPeriod - 1;
    } else {
      return mod - 1;
    }
  }

  /*
  Highly inefficient but don't think it matters
  */
  public getWeeksInPeriod(period: Date[]): number[] {
    const dict: Record<number, true> = {};
    period.forEach((date) => {
      const week = getWeek(date, { locale });
      dict[week] = true;
    });
    return Object.keys(dict).map((str) => Number(str));
  }

  private calculatePeriodFrom(date: Date): Date[] {
    const weekNumber = getWeek(date, { locale });
    const weekOfPeriod = this.getWeekIndexOfPeriodFromWeekNumber(weekNumber);

    const firstWeekOfPeriod = subWeeks(date, weekOfPeriod);
    const lastWeekOfPeriod = addWeeks(
      date,
      this.settings.weeksInPeriod - weekOfPeriod - 1
    );

    const firstDay = startOfWeek(firstWeekOfPeriod, { locale });
    const lastDay = endOfWeek(lastWeekOfPeriod, { locale });

    const days = eachDayOfInterval({ start: firstDay, end: lastDay });
    return days;
  }
}
