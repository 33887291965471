import { motion } from "framer-motion";
import { useContext } from "react";
import __ from "../../utils/utils";
import DateHelper from "../../_model/helpers/DateHelper";
import { AppLoader } from "../common/loaders/AppLoader";
import { CalendarContext } from "./Calendar";
import CalendarDayGridItem from "./CalendarDayGridItem";

interface Props {
  className?: string;
  hideWeekNumbers?: boolean;
  selectDay(day: DateHelper): void;
}

/*
  This, and everything related to this, was a mistake
  If you don't really need to change the CSS for this I would recommend not bothering
*/
const CalendarGridView = (props: Props) => {
  const { calendarHelper, isLoading } = useContext(CalendarContext);

  return (
    <motion.main
      className={__.classNames(
        "col-start-1 col-end-1 row-start-2 row-end-2 h-full",
        isLoading && "pointer-events-none"
      )}
      style={calendarHelper.gui.gridStyle}
      initial={{ opacity: 0 }}
      animate={{ opacity: isLoading ? 0.5 : 1 }}
      exit={{ opacity: 0 }}
    >
      {isLoading && (
        <AppLoader className="absolute top-1/3 left-[calc(50%-1rem)] mx-auto h-8 w-8" />
      )}
      {!props.hideWeekNumbers &&
        calendarHelper.weekNumbers.map((weekDate, i) => (
          <div
            key={`weekNumber${i}`}
            className="pt-2 pr-2 text-xs font-bold italic"
            style={{
              gridColumn: 1,
              gridRow: i + 2,
            }}
          >
            v{weekDate.week}
          </div>
        ))}
      {calendarHelper.daysOfWeek.map((day, i) => (
        <label
          key={`weekDay${i}`}
          className="text-sm font-bold"
          style={{
            gridRow: 1,
            gridColumn: i + 2,
          }}
        >
          {day.shorthand}
        </label>
      ))}
      {calendarHelper.calendarDays.map((dayHelper, i) => (
        <CalendarDayGridItem
          selectDay={props.selectDay}
          key={`dayOfMonth${dayHelper.dayOfMonth}${dayHelper.month}${dayHelper.yearLabel}`}
          index={i}
          dayHelper={dayHelper}
        />
      ))}
    </motion.main>
  );
};

export default CalendarGridView;
