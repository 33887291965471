import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDetectKeyboardOpen } from "../../../hooks/hooks";
import __ from "../../../utils/utils";

interface SharedBase<T extends string> {
  className?: string;
  mainClassName?: string;
  children?: React.ReactNode;
  selectedTab: T;
  addPadding?: boolean;
}

interface UncontrolledBase<T extends string> extends SharedBase<T> {
  tabs?: T[];
  icon?(tab: T): React.ReactNode;
  withoutControls: true;
}

interface ControlledBase<T extends string> extends SharedBase<T> {
  tabs: T[];
  icon?(tab: T): React.ReactNode;
  withoutControls?: false;
}

interface WithSearchParams<T extends string> extends ControlledBase<T> {
  searchParams: (tab: T) => Record<string, string | string[]>;
}
interface WithHref<T extends string> extends ControlledBase<T> {
  href: (tab: T) => string;
}
interface WithCallback<T extends string> extends ControlledBase<T> {
  onSelect(tab: T): void;
}
type SelfControlled<T extends string> = UncontrolledBase<T> & {
  withoutControls: true;
};
type Props<T extends string> =
  | WithSearchParams<T>
  | WithHref<T>
  | WithCallback<T>
  | SelfControlled<T>;

function AppTabs<T extends string>(props: Props<T>) {
  const keyboardOpen = useDetectKeyboardOpen();

  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();

  function onTabClick(tab: T) {
    if (props.withoutControls) {
      return;
    }
    if ("searchParams" in props) {
      setSearchParams({
        ...Object.fromEntries(params.entries()),
        ...props.searchParams(tab),
      });
    } else if ("href" in props) {
      const href = props.href(tab);
      navigate(href);
    } else {
      props.onSelect(tab);
    }
  }

  return (
    <AnimatePresence initial={false}>
      <div
        className={__.classNames(
          "grid h-full grow grid-rows-[minmax(0,1fr),auto] flex-col-reverse lg:grid-rows-[auto,minmax(0,1fr)]",
          props.className
        )}
      >
        {!props.withoutControls && !keyboardOpen && (
          <div
            role="tablist"
            className="row-start-2 grid bg-white lg:row-start-1 lg:border-t-0"
            style={{
              gridTemplateColumns: `repeat(${props.tabs.length},1fr)`,
            }}
          >
            {props.tabs.map((tab) => (
              <button
                className={__.classNames(
                  "flex grow flex-col items-center border-t-2 p-1 py-2 font-bold transition-all last:border-r-0 lg:flex-row lg:justify-center lg:gap-2 lg:border-t-0 lg:border-b-2 lg:p-2",
                  "hover:border-tab-border-selected hover:text-black",
                  props.selectedTab === tab &&
                    "border-tab-border-selected text-black",
                  props.selectedTab !== tab &&
                    "border-tab-border-not-selected text-black/60"
                )}
                key={tab}
                onClick={() => onTabClick(tab)}
                aria-selected={props.selectedTab === tab}
                role="tab"
              >
                {props.icon && props.icon(tab)}
                {tab}
              </button>
            ))}
          </div>
        )}
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            className={__.classNames(
              "row-start-1 flex h-full flex-col overflow-y-auto overflow-x-hidden lg:row-start-2",
              props.addPadding && "p-2",
              props.mainClassName
            )}
            key={props.selectedTab}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            role="tabpanel"
            aria-labelledby={props.selectedTab}
          >
            {props.children}
          </motion.div>
        </AnimatePresence>
      </div>
    </AnimatePresence>
  );
}

export default AppTabs;
