import {
  Checklist,
  ChecklistAnswers,
  WorkOrder,
  WorkOrderConfirmation,
  WorkOrderEditableFields,
  WorkOrderExtraHour,
  WorkOrderLineItem,
  WorkOrderProduct,
} from "@eljouren/domain";
import WorkOrderFinishedReport from "@eljouren/domain/build/work-order/WorkOrderFinishedReport";
import { FileMeta, IpisFile } from "@eljouren/file-schemas/build";
import { Serialize } from "@trpc/server/dist/shared/internal/serialize";
import { FullImageType } from "../../components/customer-order/WorkOrderImageUpload";
import trpcClient from "../../trpc-setup";
import { UUID } from "../../utils/UUID";
import { API } from "../api/API";
import SearchParams from "../api/SearchParams";
import IWorkOrderRepo, {
  CheckInOutParams,
  TWorkOrderConfirmationResponse,
} from "./interfaces/IWorkOrderRepo";

export class WorkOrderRepo implements IWorkOrderRepo {
  private customerOrderFetchedCallbacks: Record<
    string,
    (order: WorkOrder.Type) => void
  > = {};

  onWorkOrderFetched(callback: (order: WorkOrder.Type) => void): () => void {
    const id = UUID.generate().value;

    this.customerOrderFetchedCallbacks[id] = callback;

    return () => {
      delete this.customerOrderFetchedCallbacks[id];
    };
  }

  private convertDatesForCustomer(
    serializedOrder: Serialize<WorkOrder.CustomerType>
  ): WorkOrder.CustomerType {
    return {
      ...serializedOrder,
      // Can trpc do this automatically..?
      startDate: new Date(serializedOrder.startDate),
      endDate: new Date(serializedOrder.endDate),
    };
  }
  private convertDatesForHandyman(
    serializedOrder: Serialize<WorkOrder.HandymanWithPermissionsType>
  ): WorkOrder.HandymanWithPermissionsType {
    return {
      ...serializedOrder,
      // Can trpc do this automatically..?
      startDate: new Date(serializedOrder.startDate),
      endDate: new Date(serializedOrder.endDate),
      checkIn: serializedOrder.checkIn
        ? { date: new Date(serializedOrder.checkIn.date) }
        : null,
      checkOut: serializedOrder.checkOut
        ? { date: new Date(serializedOrder.checkOut.date) }
        : null,
    };
  }

  private notifyWorkOrderFetched(order: WorkOrder.Type) {
    Object.values(this.customerOrderFetchedCallbacks).forEach((callback) =>
      callback(order)
    );
  }

  async getByCustomerGuid(): Promise<WorkOrder.CustomerType> {
    try {
      const res = await trpcClient.workOrder.getCustomerWorkOrder.query();
      const workOrder = this.convertDatesForCustomer(res);
      this.notifyWorkOrderFetched(workOrder);
      return workOrder;
    } catch (er) {
      throw new Error("Unknown error");
    }
  }
  async getByStaffGuid(): Promise<WorkOrder.CustomerType> {
    try {
      const res = await trpcClient.workOrder.getStaffWorkOrder.query();
      const workOrder = this.convertDatesForCustomer(res);
      this.notifyWorkOrderFetched(workOrder);
      return workOrder;
    } catch (er) {
      throw new Error("Unknown error");
    }
  }

  async getHandymanWorkOrder(
    guid: string
  ): Promise<WorkOrder.HandymanWithPermissionsType> {
    try {
      const res = await trpcClient.workOrder.getHandymanWorkOrder.query({
        guid,
      });

      const workOrder = this.convertDatesForHandyman(res);
      this.notifyWorkOrderFetched(workOrder);
      return workOrder;
    } catch (er) {
      throw new Error("Unknown error");
    }
  }

  async getForHandymanBetween(args: {
    handymanId: string;
    interval: { start: Date; end: Date };
  }): Promise<WorkOrder.HandymanWithPermissionsType[]> {
    try {
      const res =
        await trpcClient.workOrder.getHandymanWorkOrdersInInterval.query({
          handymanId: args.handymanId,
          interval: {
            start: args.interval.start.toISOString(),
            end: args.interval.end.toISOString(),
          },
        });

      return res.map((withoutDates) =>
        this.convertDatesForHandyman(withoutDates)
      );
    } catch (er) {
      throw new Error("Unknown error");
    }
  }

  async reset(baseValues: { orderId: string }): Promise<boolean> {
    try {
      const base =
        process.env.REACT_APP_PROXY_URL +
        "/test/resetWorkOrder/" +
        baseValues.orderId;
      await fetch(base, { method: "PATCH", credentials: "include" });
      return true;
    } catch (er) {
      throw new Error("Unknown error");
    }
  }

  async edit(
    args: { workOrderId: string } & WorkOrderEditableFields.Type
  ): Promise<void> {
    try {
      await trpcClient.workOrder.edit.mutate(args);
    } catch (er) {
      //console.log({ er });
      throw new Error("Unknown error");
    }
  }

  async confirm(args: {
    workOrderId: string;
    values: WorkOrderConfirmation.Type;
    files: FullImageType[];
  }): Promise<TWorkOrderConfirmationResponse> {
    await trpcClient.workOrder.confirm.mutate(args.values);

    if (args.files.length) {
      try {
        await this.uploadFiles({
          workOrderId: args.workOrderId,
          files: args.files,
        });
        return {
          imagesUploaded: true,
        };
      } catch (er) {
        return { imagesUploaded: false };
      }
    } else {
      return {
        imagesUploaded: true,
      };
    }
  }

  async checkIn(params: CheckInOutParams): Promise<void> {
    return this.checkInOut(params, "in");
  }
  async checkOut(params: CheckInOutParams): Promise<void> {
    return this.checkInOut(params, "out");
  }
  async checkInOut(
    params: CheckInOutParams,
    method: "in" | "out"
  ): Promise<void> {
    try {
      await trpcClient.workOrder.checkInOrOut.mutate({
        workOrderId: params.orderId,
        longitude: params.position.coords.longitude,
        latitude: params.position.coords.latitude,
        method,
      });
    } catch (er) {
      throw er;
    }
  }

  async searchForOrders(args: {
    query: string;
    handymanId: string;
  }): Promise<WorkOrder.HandymanWithPermissionsType[]> {
    try {
      const res = await trpcClient.workOrder.search.query(args);
      return res.map((withoutDates) =>
        this.convertDatesForHandyman(withoutDates)
      );
    } catch (er) {
      throw er;
    }
  }

  async searchForMaterials(args: {
    workOrderId: string;
    query: string;
  }): Promise<WorkOrderProduct.Type[]> {
    try {
      const res = await trpcClient.workOrder.searchForMaterial.query(args);
      return res;
    } catch (er) {
      throw er;
    }
  }

  async reportMaterial(values: WorkOrderLineItem.NewEntryType): Promise<void> {
    try {
      await trpcClient.workOrder.reportMaterial.mutate(values);
    } catch (er) {
      throw er;
    }
  }

  async fetchReportedHours(args: {
    workOrderId: string;
  }): Promise<WorkOrderLineItem.Type[]> {
    try {
      const res = await trpcClient.workOrder.fetchReportedHours.query(args);
      return res.map((entry) => ({
        ...entry,
        createdDate: new Date(entry.createdDate),
      }));
    } catch (er) {
      throw er;
    }
  }

  async reportExtraHour(values: {
    workOrderId: string;
    quantity: number;
  }): Promise<void> {
    try {
      await trpcClient.workOrder.reportExtraHour.mutate(values);
    } catch (er) {
      throw er;
    }
  }

  /* 
    We're currently using remove material in place of this
  */
  async removeHour(args: {
    entryId: string;
    workOrderId: string;
  }): Promise<boolean> {
    throw new Error("Not implemented");
  }

  async fetchReportedMaterials(args: {
    workOrderId: string;
    //workerId: string;
  }): Promise<WorkOrderLineItem.Type[]> {
    try {
      const res = await trpcClient.workOrder.fetchReportedMaterials.query(args);

      const withDates: WorkOrderLineItem.Type[] = res.map((el) => ({
        ...el,
        createdDate: new Date(el.createdDate),
      }));

      return withDates;
    } catch (er) {
      throw er;
    }
  }
  async removeMaterial(args: {
    entryId: string;
    workOrderId: string;
  }): Promise<boolean> {
    try {
      const url = API.endpoint(`sobject/WorkOrderLineItem/${args.entryId}`);

      const res = await fetch(url, {
        method: "DELETE",
        headers: API.workerAuthHeader(),
      });

      if (res.status === 200) {
        return true;
      }

      throw new Error("Failed to fetch");
    } catch (er) {
      throw er;
    }
  }

  async fetchChecklist(workOrderId: string): Promise<Checklist.Type> {
    return trpcClient.workOrder.fetchChecklist.query({ workOrderId });
  }
  async reportChecklistAnswers(args: {
    workOrderId: string;
    answers: ChecklistAnswers.Type;
  }): Promise<void> {
    return trpcClient.workOrder.reportChecklistAnswers.mutate({
      workOrderId: args.workOrderId,
      answers: args.answers,
    });
  }
  /*   async fetchChecklistAnswers(args: {
    workOrderId: string;
  }): Promise<ChecklistAnswers.Type> {
    return trpcClient.workOrder.fetchChecklistAnswers.query({
      workOrderId: args.workOrderId,
    });
  }
 */
  async reportOrderFinished(args: WorkOrderFinishedReport.Type): Promise<void> {
    await trpcClient.workOrder.reportAsFinished.mutate(args);
  }

  async fetchExtraHour(args: {
    workOrderId: string;
  }): Promise<WorkOrderExtraHour.Type | undefined> {
    const res = await trpcClient.workOrder.getExtraHour.query({
      workOrderId: args.workOrderId,
    });
    return res;
  }
  async fetchQuickAddProducts(args: {
    workOrderId: string;
  }): Promise<WorkOrderExtraHour.Type[]> {
    const res = await trpcClient.workOrder.getQuickAddProducts.query({
      workOrderId: args.workOrderId,
    });
    return res;
  }

  /*
  IMAGES
  */

  private transformFileMetaDates(
    list: (IpisFile.WithMetaType & {
      meta?: FileMeta.Type & {
        createdDate: string;
      };
    })[]
  ): IpisFile.WithMetaType[] {
    const withDates = list.map((img) => {
      if (!img.meta) {
        return img;
      }
      return {
        ...img,
        meta: {
          ...img.meta,
          createdDate: new Date(img.meta.createdDate),
        },
      };
    });
    return IpisFile.WithMetaSchema.array().parse(withDates);
  }

  async getFiles(args: {
    workOrderId: string;
  }): Promise<IpisFile.WithMetaType[]> {
    try {
      const params = new URLSearchParams({
        workOrderId: args.workOrderId,
      });
      const url = API.fileEndpoint("workOrderFiles", params);
      const res = await fetch(url, {
        headers: API.getAuthHeader(),
        credentials: "include",
      });
      if (res.status === 200) {
        const json = await res.json();
        return this.transformFileMetaDates(json);
      }

      throw new Error("Failed to fetch");
    } catch (er) {
      throw er;
    }
  }

  async fetchServiceContractFiles(args: {
    workOrderId: string;
  }): Promise<IpisFile.WithMetaType[]> {
    try {
      const params = new SearchParams({
        workOrderId: args.workOrderId,
      });
      const url = API.fileEndpoint("/workOrderServiceContractFiles", params);
      const res = await fetch(url, {
        headers: API.getAuthHeader(),
        credentials: "include",
      });
      if (res.status === 200) {
        const json = await res.json();
        return this.transformFileMetaDates(json);
      }

      throw new Error("Failed to fetch");
    } catch (er) {
      throw er;
    }
  }

  async uploadFiles(args: {
    workOrderId: string;
    files: FullImageType[];
  }): Promise<void> {
    const formData = new FormData();

    args.files.forEach((file) => {
      formData.append("files", file.file);
      formData.append("meta", JSON.stringify(file.meta));
    });
    formData.append("workOrderId", args.workOrderId);

    const url = API.fileEndpoint("uploadWorkOrderFilesTest");

    try {
      const res = await fetch(url, {
        method: "POST",
        body: formData,
        headers: API.getAuthHeader(),
        credentials: "include",
      });

      if (res.status !== 200) {
        throw new Error("Failed to upload files");
      }
    } catch (er) {
      throw er;
    }
  }

  async deleteFile(image: IpisFile.ObjectType): Promise<void> {
    const params = new URLSearchParams({
      workOrderId: image.recordId,
      uploadedBy: image.uploadedBy,
      name: image.name,
      collectionType: image.collectionType,
    });

    const url = API.fileEndpoint("workOrderFile", params);

    try {
      const res = await fetch(url, {
        method: "DELETE",
        headers: API.getAuthHeader(),
        credentials: "include",
      });

      if (!res.ok) {
        throw new Error("Failed to delete file");
      }
    } catch (er) {
      throw er;
    }
  }

  async fetchRelatedFiles(args: {
    workOrderId: string;
  }): Promise<IpisFile.WithMetaType[]> {
    try {
      const params = new SearchParams({
        workOrderId: args.workOrderId,
      });
      const url = API.fileEndpoint("/workOrderRelatedFiles", params);
      const res = await fetch(url, {
        headers: API.getAuthHeader(),
        credentials: "include",
      });
      if (res.status === 200) {
        const json = await res.json();
        return this.transformFileMetaDates(json);
      }

      throw new Error("Failed to fetch");
    } catch (er) {
      throw er;
    }
  }
}
