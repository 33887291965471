import { motion } from "framer-motion";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { TailwindBreakpoint, useMediaQuery } from "../../hooks/use-media-query";
import { useRepos } from "../../hooks/use-repos";
import HandymanWorkOrderRouteContext from "../../routes/worker/order/HandymanWorkOrderRouteContext";
import __ from "../../utils/utils";
import { WorkOrder } from "@eljouren/domain";
import { AppButton } from "../common/buttons/AppButton";
import { AppFormTextArea } from "../common/text-areas/AppFormTextArea";
import PageSection from "../common/PageSection";

type FormValues = Pick<
  WorkOrder.HandymanType,
  "actionDescription" | "internalActionNote"
>;

interface Props {
  className?: string;
}

const WorkOrderHandymanControlsTextContentSection = (props: Props) => {
  const { orderRes, order } = useContext(HandymanWorkOrderRouteContext);

  const form = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      internalActionNote: order.internalActionNote,
      actionDescription: order.actionDescription,
    },
  });

  const isLg = useMediaQuery(TailwindBreakpoint.lg);

  const { workOrderRepo: customerOrderRepo } = useRepos();

  useEffect(() => {
    form.reset({
      internalActionNote: order.internalActionNote,
      actionDescription: order.actionDescription,
    });
  }, [order, form]);

  async function onSubmit(values: FormValues) {
    try {
      await orderRes.mutate(
        async () => {
          return customerOrderRepo.edit({
            workOrderId: order.orderId,
            ...values,
          });
        },
        {
          optimisticUpdate: {
            ...order,
            ...values,
          },
        }
      );
    } catch (ex) {
      window.modal.alert({
        title: "Det gick inte spara informationen just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
      });
    }
  }

  return (
    <PageSection as="section" className={__.classNames("", props.className)}>
      <form
        className="flex flex-col gap-2"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <AppFormTextArea
          name="actionDescription"
          register={form.register}
          label="Åtgärd"
          boldLabel
          htmlAttributes={{
            rows: isLg ? 6 : 4,
            placeholder:
              "Beskriv vad du har utfört hos kund. Denna information kommer att synas på kundfakturan.",
            readOnly: order.isFinished,
            maxLength: 32768,
          }}
        />
        <AppFormTextArea
          name="internalActionNote"
          boldLabel
          register={form.register}
          label="Intern kommentar"
          htmlAttributes={{
            rows: isLg ? 6 : 4,
            placeholder:
              "Denna information syns bara internt och visas inte för kund.",
            readOnly: order.isFinished,
            maxLength: 32768,
          }}
        />
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: form.formState.isDirty ? 1 : 0 }}
          className="ml-auto text-sm lg:col-span-2"
        >
          Klicka på "Spara ändringar" innan du går vidare!
        </motion.p>
        {!order.isFinished && (
          <AppButton
            loading={orderRes.isInMutationProcess}
            disabled={!form.formState.isDirty || orderRes.isLoading}
            className="ml-auto px-6 lg:col-span-2"
          >
            Spara ändringar
          </AppButton>
        )}
      </form>
    </PageSection>
  );
};

export default WorkOrderHandymanControlsTextContentSection;
