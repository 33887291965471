import { Checklist, WorkOrder } from "@eljouren/domain/build";

namespace WorkOrderMockData {
  export const customerOrder: WorkOrder.CustomerType = {
    viewedBy: "customer",
    orderId: "12345",
    serialNumber: "00000007",
    isFinished: false,
    customerId: "0017Z00001t0HXIQA2",
    description: "Belysning",
    apiStatus: "Planerad",
    additionalInformation:
      "1. Hallampa tänds när den vill vid och inte varje gång man vill. Se bild 1 och 2. Troligtvis glapp i dimmern eller att det behövs till ny. 2. Spottar i köket ovanför diskbänken är kopplad till trådlös switch från Ikea. Denna har slutat fungera. Se bild 3. Önskar att koppla spettarna mot strömbrytaren på väggen som tänder spottarna i takbelysningen i stället.",
    confirmedByCustomer: false,
    typeOfService: "EL - Planerat arbete",
    location: {
      city: "Uppsala",
      street: "Test",
      postalCode: "123123",
      country: "",
      doorCode: "12345",
      floor: "3",
    },
    invoiceInformation: {
      email: "faktura@test.se",
      city: "Uppsala",
      street: "Test",
      postalCode: "123123",
    },
    contact: {
      phone: "+46735454330",
      email: "johannes.svensson@itaros.tech",
      fullName: "Johannes Svensson",
      phone2: "+46701234566",
    },
    startDate: new Date("2022-09-10T04:00:00.000Z"),
    endDate: new Date("2022-09-10T06:00:00.000Z"),
    categories: [],
    customerIsCompany: false,
    brand: "eljouren",
    coordinator: {
      id: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    },
  };

  export const handymanOrder: WorkOrder.HandymanWithPermissionsType = {
    ...customerOrder,
    viewedBy: "handyman",
    checkIn: null,
    opportunityId: "is this still used?",
    isAssignedThisOrder: true,
    allowedToHandleOrder: true,
    internalDescription:
      "3 vån - Lindholm - finns porttelefon eller ring på mobilen +46737223786",
    internalActionNote: "Internal action note",
    actionDescription: "Action description",
    workerGuid: "workerGuid",
    productPackageId: null,
    checklist: {
      id: "asdasd",
      name: "asdasdasd",
    },
    serviceContract: {
      id: "sc1234",
      name: "Avtalsnamn",
      description: "Avtalsbeskrivning",
      allowedMaterial: null,
    },
    checkOut: null,
    endCustomer: {
      name: "",
      phone: "",
    },
  };

  export const checkList: Checklist.Type = [
    /* {
      id: "1",
      sortOrder: 0,
      type: "text",
      name: "Test 1",
      answer: null,
      options: null,
    },
    {
      id: "2",
      sortOrder: 1,
      type: "yes/no",
      name: "Test 2",
      answer: null,
      options: null,
    }, */
  ];
}

export default WorkOrderMockData;
