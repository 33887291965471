import { isValid } from "date-fns";
import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import {
  useBundledCustomSearchParamState,
  usePrevious,
} from "../../hooks/hooks";
import { useBundledSs } from "../../hooks/session-storage-hooks";
import { TailwindBreakpoint, useMediaQuery } from "../../hooks/use-media-query";
import __ from "../../utils/utils";
import CalendarEvent from "../../_model/helpers/calendar/CalendarEvent";
import CalendarHelper from "../../_model/helpers/calendar/CalendarHelper";
import { TCalendarEvent } from "../../_model/helpers/calendar/types";
import DateHelper from "../../_model/helpers/DateHelper";
import AppTextButton from "../common/buttons/AppTextButton";
import CalendarGridView from "./CalendarGridView";
import CalendarListView from "./CalendarListView";
import CalendarDayView from "./day-view/CalendarDayView";

export type TCalendarView = "grid" | "list";
export type TRenderCalendarEvent = (props: {
  event: CalendarEvent;
}) => JSX.Element;
export type ListViewFilter = {
  id: string;
  label: string;
} & (
  | {
      type: "checkbox";
      filter: (event: CalendarEvent) => boolean;
    }
  | {
      type: "text";
      filter: (event: CalendarEvent, text: string) => boolean;
    }
);

export const CalendarContext = React.createContext<{
  calendarHelper: CalendarHelper;
  pageIndex: number;
  setPageIndex: (page: number) => void;
  //selectedDay: TUseBundledStateReturn<DateHelper | null>;
  previouslySelectedDay: DateHelper | null | undefined;
  RenderListViewItem: TRenderCalendarEvent | undefined;
  RenderDayViewItem: TRenderCalendarEvent | undefined;
  listViewFilter: ListViewFilter[] | undefined;
  currentView: TCalendarView;
  isLoading: boolean;
}>({} as never);

interface Props {
  className?: string;
  /*
  Not yet supported
  */
  hideWeekNumbers?: boolean;
  events?: TCalendarEvent[];
  onIndexChanged(index: number): void;
  listViewFilter?: ListViewFilter[];
  loading?: boolean;
  weeksInPeriod?: number;
  RenderListViewItem?: TRenderCalendarEvent;
  RenderDayViewItem?: TRenderCalendarEvent;
  defaultView?: TCalendarView;
  pageIndex: number;
}

const Calendar = (props: Props) => {
  const view = useBundledSs<TCalendarView>(
    "calendarView",
    props.defaultView || "list"
  );

  /*
  The day shown on the "dayview"
  */
  const selectedDay = useBundledCustomSearchParamState<DateHelper | null>(
    /*
    "Dag" is "day" in swedish
    */
    "dag",
    null,
    {
      toString: (obj) => {
        return obj ? obj.date.getTime().toString() : null;
      },
      fromString: (str) => {
        if (!str) {
          return null;
        }
        const date = new Date(Number.parseInt(str));
        if (isValid(date)) {
          return new DateHelper(date);
        } else {
          return null;
        }
      },
    }
  );
  /*
  The previously selected day, although I'm not sure what this is used for
  */
  const previouslySelectedDay = usePrevious(selectedDay.value);

  /*
  This should be moved to state if we want to be able to change i.e. whether or not to show/hide week numbers,
  or reflect changes when the events get updated
  */
  const helper = new CalendarHelper(props.pageIndex, props.events, {
    weeksInPeriod: props.weeksInPeriod,
  });

  function toggleView() {
    if (view.value === "grid") {
      view.set("list");
    } else {
      view.set("grid");
    }
  }

  return (
    <CalendarContext.Provider
      value={{
        isLoading: !!props.loading,
        setPageIndex: props.onIndexChanged,
        currentView: view.value,
        calendarHelper: helper,
        pageIndex: props.pageIndex,
        //selectedDay,
        previouslySelectedDay,
        RenderDayViewItem: props.RenderDayViewItem,
        RenderListViewItem: props.RenderListViewItem,
        listViewFilter: props.listViewFilter,
      }}
    >
      <div
        className={__.classNames(
          "relative grid h-full grid-rows-[3rem,minmax(0,1fr)]",
          props.className
        )}
      >
        <CalendarHeader
          goBack={() => selectedDay.set(null)}
          selectedDay={selectedDay.value}
          //onActiveDateChanged={props.onIndexChanged}
          view={view.value}
          toggleView={toggleView}
        />
        <AnimatePresence initial={false} exitBeforeEnter>
          {!selectedDay.value && view.value === "grid" && (
            <CalendarGridView
              selectDay={(day) => selectedDay.set(day)}
              key={"grid"}
              {...props}
            />
          )}
          {!selectedDay.value && view.value === "list" && (
            <CalendarListView key={"list"} />
          )}

          {selectedDay.value && (
            <CalendarDayView
              key={"day"}
              selectedDay={selectedDay.value}
              goBack={() => selectedDay.set(null)}
              {...props}
            />
          )}
        </AnimatePresence>
      </div>
    </CalendarContext.Provider>
  );
};

const CalendarHeader = (props: {
  goBack(): void;
  selectedDay: DateHelper | null;
  //onActiveDateChanged?(index: number): void;
  view: TCalendarView;
  toggleView(): void;
}) => {
  const ctx = useContext(CalendarContext);
  const isLg = useMediaQuery(TailwindBreakpoint.lg);
  function goToNextPage() {
    changeIndexBy(1);
  }
  function goToPreviousPage() {
    changeIndexBy(-1);
  }

  function changeIndexBy(index: number) {
    ctx.setPageIndex(ctx.pageIndex + index);
  }

  return (
    <header className="relative flex w-full items-center justify-between gap-2 pb-2">
      {props.selectedDay && (
        <AppTextButton
          className="flex items-center gap-2"
          onClick={props.goBack}
        >
          <FiArrowLeft size={25} />
          Måndadsvy
        </AppTextButton>
      )}
      {!props.selectedDay && (
        <>
          <div className="flex items-center gap-4">
            <AppTextButton onClick={goToPreviousPage}>
              <FiArrowLeft size={30} />
            </AppTextButton>
            <span className="m-auto text-base font-bold lg:text-lg">
              {ctx.calendarHelper.label(isLg)}
            </span>
            <AppTextButton onClick={goToNextPage}>
              <FiArrowRight size={30} />
            </AppTextButton>
          </div>
          <AppTextButton onClick={props.toggleView} className="px-2">
            {props.view === "grid" && "Visa listvy"}
            {props.view === "list" && "Visa kalendervy"}
          </AppTextButton>
        </>
      )}
    </header>
  );
};

export default Calendar;
