"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Company = void 0;
var zod_1 = require("zod");
var Company;
(function (Company) {
    Company.Schema = zod_1.z.object({
        id: zod_1.z.string().min(5),
        name: zod_1.z.string().min(2),
        shorthand: zod_1.z.string().optional(),
    });
})(Company || (Company = {}));
exports.Company = Company;
