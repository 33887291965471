import { useEffect } from "react";
import __ from "../utils/utils";
import { Observable } from "../_model/observables/Observable";
import { useBind } from "./observable-hooks";

type MediaQueryObject = {
  orientation?: "landscape" | "portrait";
} & (
  | {
      minWidth: number;
      maxWidth?: number;
    }
  | {
      minWidth?: number;
      maxWidth: number;
    }
);

export enum TailwindBreakpoint {
  sm = 640,
  md = 768,
  lg = 1024,
  xl = 1280,
  xxl = 1536,
}

function constructQueryString(obj: MediaQueryObject): string {
  return __.entries(obj)
    .filter((entry) => entry !== undefined)
    .map((entry) => {
      if (entry === undefined) {
        return "";
      }

      const [key, val] = entry;
      switch (key) {
        case "minWidth":
          return `(min-width: ${val}px)`;
        case "maxWidth":
          return `(max-width: ${val}px)`;
        case "orientation":
          return `(orientation: ${val})`;
        default:
          return "";
      }
    })
    .join(" AND ");
}

function queryObjectFromTailwindBreakpoint(
  breakpoint: TailwindBreakpoint
): MediaQueryObject {
  return {
    minWidth: breakpoint,
  };
}

const useMediaQueryObservables: { [query: string]: Observable<boolean> } = {};

export function useMediaQuery(
  query: TailwindBreakpoint | MediaQueryObject,
  context: string | false = false
): boolean {
  const obj =
    typeof query === "object"
      ? query
      : queryObjectFromTailwindBreakpoint(query);

  const queryString = constructQueryString(obj);
  const list =
    typeof window !== "undefined" ? window.matchMedia(queryString) : undefined;

  function getObservable() {
    if (!(queryString in useMediaQueryObservables)) {
      const matches = !!list?.matches;
      useMediaQueryObservables[queryString] = new Observable(matches);
    }
    const obs = useMediaQueryObservables[queryString];
    if (list) {
      obs.set(list.matches);
    }
    return obs;
  }

  const matches = useBind(getObservable());

  const onChange = (query: MediaQueryListEvent): any => {
    /* if (context) {
      console.log({ context, match: query.matches });
    } */
    getObservable().set(query.matches);
  };

  useEffect(() => {
    if (list) {
      if (!!list.addEventListener) {
        list.addEventListener("change", onChange);
      } else {
        list.addListener(onChange);
      }
    }

    return () => {
      if (list) {
        if (!!list.removeEventListener) {
          list.removeEventListener("change", onChange);
        } else {
          list.removeListener(onChange);
        }
      }
    };
  });

  return matches;
}

/* export function useServerSideMediaQuery(
  query: TailwindBreakpoint | MediaQueryObject,
  defaultValue: boolean = false
): {
  matches: boolean;
  onClient: boolean;
} {
  const matches = useMediaQuery(query);
  const initialRender = useBundledState<boolean>(false);
  useEffect(() => {
    initialRender.set(true);
  }, []);

  return {
    matches: initialRender.value ? matches : defaultValue,
    onClient: initialRender.value,
  };
}
 */
