import WorkOrderFinishedReport from "@eljouren/domain/build/work-order/WorkOrderFinishedReport";
import { zodResolver } from "@hookform/resolvers/zod";
import { AnimatePresence } from "framer-motion";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useBundledState } from "../../hooks/hooks";
import { useRepos } from "../../hooks/use-repos";
import HandymanWorkOrderChecklist from "../../routes/worker/order/HandymanWorkOrderChecklist";
import HandymanWorkOrderMarkAsFinishedModal from "../../routes/worker/order/HandymanWorkOrderMarkAsFinishedModal";
import HandymanWorkOrderRouteContext from "../../routes/worker/order/HandymanWorkOrderRouteContext";
import __ from "../../utils/utils";
import WorkOrderReimbursementCalculator from "../../_model/helpers/WorkOrderReimbursementCalculator";
import { SignedInContext } from "../auth-hocs/withWorkerCredentials";
import { AppButton } from "../common/buttons/AppButton";

interface Props {
  className?: string;
}

const WorkOrderHandymanControlsFooter = (props: Props) => {
  const showCheckList = useBundledState(false);
  const { handyman } = useContext(SignedInContext);
  const {
    orderRes,
    order,
    checklistRes,
    reportedMaterialRes,
    reportedHoursRes,
  } = useContext(HandymanWorkOrderRouteContext);
  const { workOrderRepo: customerOrderRepo } = useRepos();

  const form = useForm<WorkOrderFinishedReport.Type>({
    mode: "onChange",
    defaultValues: {
      workOrderId: order.orderId,
      rebooking: "no",
    },
    resolver: zodResolver(WorkOrderFinishedReport.Schema),
  });

  useEffect(() => {
    form.watch();
  });
  const showModal = useBundledState(false);

  const calc = new WorkOrderReimbursementCalculator({
    materialData: reportedMaterialRes.query.data,
    hoursData: reportedHoursRes.query.data,
  });

  async function onSubmit(values: WorkOrderFinishedReport.Type) {
    try {
      showModal.set(false);
      await orderRes.mutate(
        () =>
          customerOrderRepo.reportOrderFinished({
            ...values,
          }),
        {
          optimisticUpdate: {
            ...order,
            apiStatus: "Klarmarkerad av hantverkare",
          },
        }
      );
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte klarmarkera arbetet just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
      });
    }
  }

  let checklistCompleted: boolean;
  if (checklistRes.isLoading || !checklistRes.query.data) {
    checklistCompleted = false;
  } else {
    checklistCompleted = checklistRes.query.data.every((el) => {
      if (!el.required) {
        return true;
      }

      return !!el.answer;
    });
  }

  return (
    <>
      <footer
        className={__.classNames(
          "flex flex-col items-end gap-4",
          props.className
        )}
      >
        {handyman.permittedToViewPrices && (
          <section className="flex flex-col items-end gap-1">
            <h3 className="text-base font-normal">
              Total ersättning för arbetet
            </h3>
            <p className="font-bold">{calc.forAll}:-</p>
          </section>
        )}
        {!!orderRes.query.data?.checklist && (
          <AppButton
            buttonMode="outline"
            className="flex-col gap-0"
            onClick={() => showCheckList.set(true)}
          >
            <span className="text-xs">Visa checklista för</span>
            <span>{orderRes.query.data.checklist.name}</span>
          </AppButton>
        )}
        {!checklistCompleted && (
          <span className="text-sm">
            Fyll i alla obligatoriska fält i checklistan för att kunna
            klarmarkera
          </span>
        )}
        <AppButton
          onClick={() => showModal.set(true)}
          disabled={
            orderRes.isLoading || order.isFinished || !checklistCompleted
          }
          loading={checklistRes.isLoading}
        >
          Klarmarkera arbete
        </AppButton>
      </footer>
      <AnimatePresence>
        {showModal.value && (
          <HandymanWorkOrderMarkAsFinishedModal
            form={form}
            onSubmit={onSubmit}
            onClose={() => showModal.set(false)}
          />
        )}
        {showCheckList.value && (
          <HandymanWorkOrderChecklist
            onGoBack={() => showCheckList.set(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default WorkOrderHandymanControlsFooter;
