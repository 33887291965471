import { FileMetaInput, IpisFile } from "@eljouren/file-schemas/build";
import { zodResolver } from "@hookform/resolvers/zod";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { FieldErrors, useForm, UseFormReturn } from "react-hook-form";
import { FiArrowLeft, FiTrash } from "react-icons/fi";
import ReactImageUploading, { ImageType } from "react-images-uploading";
import { z } from "zod";
import { useBundledState, useLoading } from "../../hooks/hooks";
import __ from "../../utils/utils";
import { UUID } from "../../utils/UUID";
import { AppButton } from "../common/buttons/AppButton";
import AppTextButton from "../common/buttons/AppTextButton";
import LoadingContent from "../common/loaders/LoadingContent";
import { AppFormTextArea } from "../common/text-areas/AppFormTextArea";
import { AppFormTextField } from "../common/text-fields/AppFormTextField";
import IpisFileIcon from "../files/IpisFileIcon";

export const OrderFinalFormValuesSchema = z.object({
  gdprConfirmed: z.literal(true),
});

export type TOrderFinalFormValues = z.infer<typeof OrderFinalFormValuesSchema>;

interface Props {
  className?: string;
  onSubmit(images: FullImageType[]): void;
  onGoBack?(): void;
  requireAtLeastOneImage?: boolean;
  defaultValues?: ImageTypeWithId[];
  uploadLabel: string;
}

type ImageTypeWithId = ImageType & {
  guid: string;
};
type ImageTypeWithOptionalId = ImageType & {
  guid?: string;
};
const customNameMinLength = 3;
const customNameMaxLength = 35;
const descriptionMaxLength = 250;
const FormSchema = z.object({
  meta: z.record(
    z.object({
      customName: z.string().min(customNameMinLength).max(customNameMaxLength),
      description: z.string().max(descriptionMaxLength).optional(),
    })
  ),
});
type FormValues = z.infer<typeof FormSchema>;

export type FullImageType = ImageTypeWithId & { meta: FileMetaInput.Type } & {
  file: File;
};

const WorkOrderImageUpload = (props: Props) => {
  const [files, setImages] = useState<ImageTypeWithId[]>(
    props.defaultValues ?? []
  );
  const metaForm = useForm<FormValues>({
    //mode: "all",
    defaultValues: {
      meta: {},
    },
    resolver: zodResolver(FormSchema),
  });
  const showError = useBundledState(false);
  const { isLoading } = useLoading();

  metaForm.watch();

  function onImageChange(imageList: ImageTypeWithOptionalId[]) {
    const map: { [dataUrl: string]: ImageTypeWithId } = {};
    let foundDuplicatePictures = false;
    imageList.forEach((img) => {
      if (img.dataURL && img.dataURL in map) {
        foundDuplicatePictures = true;
      } else if (img.dataURL) {
        if (!img.guid) {
          img.guid = UUID.generate().value;
        }
        map[img.dataURL] = img as ImageTypeWithId;
      }
    });

    const meta = metaForm.getValues("meta");
    Object.keys(meta).forEach((key) => {
      if (!(key in map)) {
        delete meta[key];
      }
    });

    if (foundDuplicatePictures) {
      window.modal.alert({
        title: "Minst en av de valda filerna finns redan i listan",
        prompt: "Dubletterna kommer att ignoreras",
        typeOfAlert: "error",
      });
    }

    setImages(Object.values(map));
  }

  function onImageRemoveAllClick(e: React.MouseEvent) {
    metaForm.reset({ meta: {} });
    e.preventDefault();
    setImages([]);
  }

  async function onSubmit(values: FormValues) {
    const fullImages: FullImageType[] = files
      .filter((img): img is ImageTypeWithId & { file: File } => !!img.file)
      .map((img) => {
        const meta = values.meta[img.guid] ?? {};
        return { ...img, meta };
      });

    props.onSubmit(fullImages);
  }

  async function onError(error: FieldErrors<FormValues>) {
    console.log({ error });
  }

  const maxSizeInBytes = 10 * Math.pow(10, 6);
  const maxSizeStr = "10mb";

  return (
    <>
      {showError.value && (
        <OnError reset={() => showError.set(false)}></OnError>
      )}
      {!showError.value && (
        <LoadingContent loading={isLoading}>
          <form
            className={__.classNames(
              "grid h-full grid-rows-[auto,minmax(0,1fr),auto] gap-3 p-1 pb-3 sm:p-4",
              props.className
            )}
            onSubmit={metaForm.handleSubmit(onSubmit, onError)}
          >
            <header className="flex flex-col">
              <h3 className="text-xl">Ladda upp filer</h3>
              <p className="text-base">
                Ladda gärna upp filer som är relevanta för uppdraget{" "}
                {`(max ${maxSizeStr} per fil)`}
              </p>
            </header>
            <main className="flex grow flex-col gap-1">
              <ReactImageUploading
                multiple
                value={files}
                onChange={onImageChange}
                onError={(err) => {
                  if (err?.maxFileSize) {
                    window.modal.alert({
                      title: "Fil för stor",
                      prompt: `Max storlek för uppladdning är ${maxSizeStr}`,
                      typeOfAlert: "error",
                    });
                  }
                }}
                allowNonImageType={true}
                acceptType={IpisFile.AllowedExtensions.options}
                maxFileSize={maxSizeInBytes}
              >
                {({ onImageUpload, onImageRemove }) => {
                  //onImageRemoveAllReference = onImageRemoveAll;

                  return (
                    <>
                      <AppButton
                        //style={isDragging ? { color: "red" } : undefined}
                        onClick={(e) => {
                          e.preventDefault();
                          onImageUpload();
                        }}
                        buttonMode="outline"
                      >
                        Klicka här för att välja filer
                      </AppButton>
                      <ul
                        //{...dragProps}
                        className="grid min-h-[300px] w-full grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-3 overflow-y-auto rounded border p-3"
                      >
                        <AnimatePresence initial>
                          {files.map((file, index) => (
                            <Item
                              form={metaForm}
                              key={file.dataURL}
                              metaKey={file.guid}
                              file={file}
                              deleteImage={() => onImageRemove(index)}
                            />
                          ))}
                        </AnimatePresence>
                      </ul>
                    </>
                  );
                }}
              </ReactImageUploading>
              <AppTextButton
                onClick={onImageRemoveAllClick}
                className="ml-auto p-2 text-base"
              >
                Återställ filer
              </AppTextButton>
            </main>
            <footer className="grid grid-cols-2 justify-end gap-2 pt-8">
              <AppButton
                className="col-span-2 lg:col-span-1 lg:col-start-2"
                disabled={props.requireAtLeastOneImage && !files.length}
              >
                {props.uploadLabel}
              </AppButton>
              <AppTextButton
                className="col-span-2 flex items-center gap-2 p-2 lg:col-span-1 lg:col-start-2 lg:ml-auto"
                onClick={props.onGoBack}
              >
                <FiArrowLeft size={30} />
                Gå tillbaka
              </AppTextButton>
            </footer>
          </form>
        </LoadingContent>
      )}
    </>
  );
};

const OnError = (props: { reset(): void }) => (
  <>
    <p>Det gick inte att ladda upp bilder just nu</p>
    <button onClick={props.reset}>Försök igen</button>
  </>
);

const Item = (props: {
  file: ImageType;
  deleteImage: () => void;
  form: UseFormReturn<FormValues>;
  metaKey: string;
}) => {
  const baseKey: `meta.${string}` = `meta.${props.metaKey}`;
  const nameKey: `meta.${string}.customName` = `${baseKey}.customName`;
  const descriptionKey: `meta.${string}.description` = `${baseKey}.description`;
  const nameValue = props.form.getValues(nameKey);

  useEffect(() => {
    if (nameValue === undefined && props.file.file) {
      const split = props.file.file.name.split(".");
      const name = split.slice(0, -1).join(".");
      props.form.setValue(nameKey, name);
    }
  }, [nameValue, nameKey, props.form, props.file]);

  const errors = props.form.formState.errors ?? {};

  const mimeType = props.file.file?.type;
  const isImage = mimeType?.includes("image");
  return (
    <motion.li
      layout
      className="relative flex w-full flex-col gap-2"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {isImage && (
        <img
          src={props.file.dataURL}
          alt="Bild vald för att laddas upp"
          className="h-[180px] w-full rounded object-cover "
        />
      )}
      {!isImage && (
        <span className="flex h-[180px] w-full items-center justify-center rounded border">
          <IpisFileIcon size={90} mimeType={mimeType} />
        </span>
      )}
      <AppFormTextField
        register={props.form.register}
        errorMessage={errors.meta?.[props.metaKey]?.customName?.message}
        name={nameKey}
        htmlAttributes={{
          placeholder: "Namn",
          minLength: customNameMinLength,
          maxLength: customNameMaxLength,
        }}
      />
      <AppFormTextArea
        register={props.form.register}
        errorMessage={errors.meta?.[props.metaKey]?.description?.message}
        name={descriptionKey}
        htmlAttributes={{
          placeholder: "Beskrivning",
          rows: 2,
          maxLength: descriptionMaxLength,
        }}
      />
      <AppButton
        onClick={(e) => {
          e.preventDefault();
          props.deleteImage();
        }}
      >
        <span>Ta bort</span>
        <FiTrash size={20} />
      </AppButton>
    </motion.li>
  );
};

export default WorkOrderImageUpload;
