export namespace __tsx {
  export function renderOne<T>(
    args: T,
    renderFunctions: ((props: T) => false | React.ReactElement)[]
  ): React.ReactNode {
    for (let i = 0; i < renderFunctions.length; i++) {
      const element = renderFunctions[i](args);
      if (element) {
        return element;
      }
    }

    return undefined;
  }
}
