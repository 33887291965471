"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrder = void 0;
var zod_1 = require("zod");
var Brand_1 = require("../Brand");
var CheckInOut_1 = require("./CheckInOut");
var WorkOrderCategory_1 = require("./WorkOrderCategory");
var WorkOrderContactInfo_1 = require("./WorkOrderContactInfo");
var WorkOrderLocation_1 = require("./WorkOrderLocation");
var WorkOrderStatus_1 = require("./WorkOrderStatus");
var WorkOrder;
(function (WorkOrder) {
    WorkOrder.AllowedMaterialEnum = zod_1.z.enum([
        "searchAndCustom",
        "quickAddServiceContract",
        "quickAddTypeOfService",
    ]);
    /*
    Fields accessible without signing in as a handyman, as long as you have access to the GUID
    need to query the fields
    */
    WorkOrder.SharedFieldsSchema = zod_1.z.object({
        orderId: zod_1.z.string(),
        serialNumber: zod_1.z.string(),
        customerId: zod_1.z.string(),
        description: zod_1.z.string(),
        additionalInformation: zod_1.z.string(),
        confirmedByCustomer: zod_1.z.boolean(),
        location: WorkOrderLocation_1.WorkOrderLocation.Schema,
        contact: WorkOrderContactInfo_1.WorkOrderContactInfo.Schema,
        invoiceInformation: zod_1.z.object({
            email: zod_1.z.string().nullable(),
            city: zod_1.z.string().nullable(),
            street: zod_1.z.string().nullable(),
            postalCode: zod_1.z.string().nullable(),
        }),
        startDate: zod_1.z.date(),
        endDate: zod_1.z.date(),
        categories: WorkOrderCategory_1.WorkOrderCategory.Schema.array(),
        apiStatus: WorkOrderStatus_1.WorkOrderStatus.Schema,
        typeOfService: zod_1.z.string(),
        isFinished: zod_1.z.boolean(),
        customerIsCompany: zod_1.z.boolean(),
        brand: Brand_1.Brand.Schema,
        coordinator: zod_1.z.object({
            id: zod_1.z.string(),
            firstName: zod_1.z.string().nullable(),
            lastName: zod_1.z.string().nullable(),
            email: zod_1.z.string().nullable(),
            phone: zod_1.z.string().nullable(),
        }),
    });
    WorkOrder.CustomerSchema = WorkOrder.SharedFieldsSchema.merge(zod_1.z.object({
        viewedBy: zod_1.z.literal("customer"),
    }));
    WorkOrder.HandymanSchema = WorkOrder.SharedFieldsSchema.extend({
        viewedBy: zod_1.z.literal("handyman"),
        assignedTo: zod_1.z
            .object({
            handymanId: zod_1.z.string(),
            handymanName: zod_1.z.string(),
            companyId: zod_1.z.string(),
        })
            .optional(),
        /*
            Consider putting internalDescription in the coordinator object
          */
        internalDescription: zod_1.z.string().nullable(),
        checklist: zod_1.z
            .object({
            id: zod_1.z.string(),
            name: zod_1.z.string(),
        })
            .nullable(),
        internalActionNote: zod_1.z.string(),
        actionDescription: zod_1.z.string(),
        workerGuid: zod_1.z.string(),
        productPackageId: zod_1.z.string().nullable(),
        checkIn: CheckInOut_1.CheckInOut.Schema.nullable(),
        checkOut: CheckInOut_1.CheckInOut.Schema.nullable(),
        /*
        ToDo: Remove this as it is SF specific
        */
        opportunityId: zod_1.z.string(),
        serviceContract: zod_1.z
            .object({
            id: zod_1.z.string(),
            name: zod_1.z.string(),
            description: zod_1.z.string().nullable(),
            allowedMaterial: WorkOrder.AllowedMaterialEnum.array().nullable(),
        })
            .nullable(),
        endCustomer: zod_1.z.object({
            name: zod_1.z.string().nullable(),
            phone: zod_1.z.string().nullable(),
        }),
    });
    WorkOrder.HandymanWithPermissionsSchema = WorkOrder.HandymanSchema.merge(zod_1.z.object({
        allowedToHandleOrder: zod_1.z.boolean(),
        isAssignedThisOrder: zod_1.z.boolean(),
    }));
    WorkOrder.Schema = zod_1.z.discriminatedUnion("viewedBy", [
        WorkOrder.CustomerSchema,
        //HandymanSchema,
        WorkOrder.HandymanWithPermissionsSchema,
    ]);
})(WorkOrder || (WorkOrder = {}));
exports.WorkOrder = WorkOrder;
