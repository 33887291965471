"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFile = void 0;
var UploaderSource_1 = require("../shared-schemas/UploaderSource");
var zod_1 = require("zod");
var FileMeta_1 = require("./FileMeta");
var CollectionType_1 = require("../shared-schemas/CollectionType");
var IpisFile;
(function (IpisFile) {
    IpisFile.AllowedExtensions = zod_1.z.enum([
        // Images
        "jpg",
        "jpeg",
        "png",
        "webp",
        "heic",
        // Documents
        "pdf",
        "doc",
        "docx",
        "xsl",
        "xlsx",
        "ppt",
        "pptx",
        "txt",
        "rtf",
    ]);
    IpisFile.OnlySrcSchema = zod_1.z.string();
    IpisFile.ObjectSchema = zod_1.z.object({
        src: zod_1.z.string(),
        name: zod_1.z.string(),
        uploadedBy: UploaderSource_1.UploaderSource.Schema,
        recordId: zod_1.z.string(),
        ext: zod_1.z.string(),
        guid: zod_1.z.string(),
        collectionType: CollectionType_1.CollectionType.Schema,
    });
    IpisFile.WithMetaSchema = IpisFile.ObjectSchema.extend({
        meta: FileMeta_1.FileMeta.Schema.optional(),
    });
})(IpisFile || (IpisFile = {}));
exports.IpisFile = IpisFile;
