import withStaffGuid from "../../components/auth-hocs/withStaffGuid";
import __ from "../../utils/utils";

const StaffTestRoute = () => {
  return (
    <div className={__.classNames("text-xl text-red-400")}>
      Staff test route
    </div>
  );
};

export default withStaffGuid(StaffTestRoute);
