import { WorkOrderConfirmation } from "@eljouren/domain/build";
import { zodResolver } from "@hookform/resolvers/zod";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { FiArrowLeft } from "react-icons/fi";
import { ImageListType } from "react-images-uploading";
import { z } from "zod";
import { AppButton } from "../../../components/common/buttons/AppButton";
import AppTextButton from "../../../components/common/buttons/AppTextButton";
import GDPRLinkAndConfirmationCheckbox from "../../../components/gdpr/GDPRConfirmationCheckbox";
import __ from "../../../utils/utils";
import CustomerWorkOrderContext from "../CustomerWorkOrderContext";
import CustomerWorkOrderOverviewSection from "../CustomerWorkOrderOverviewSection";

export const CustomerWorkOrderOverviewFormSchema = z.object({
  gdprConfirmed: z.literal(true),
});

export type CustomerWorkOrderOverviewFormValues = z.infer<
  typeof CustomerWorkOrderOverviewFormSchema
>;

interface Props {
  className?: string;
  values: Omit<WorkOrderConfirmation.Type, "gdprConfirmed">;
  images: ImageListType;
  onSubmit(values: Pick<WorkOrderConfirmation.Type, "gdprConfirmed">): void;
  onGoBack(): void;
}

const CustomerWorkOrderOverviewFormStep = (props: Props) => {
  const { workOrder } = useContext(CustomerWorkOrderContext);
  const form = useForm<CustomerWorkOrderOverviewFormValues>({
    resolver: zodResolver(CustomerWorkOrderOverviewFormSchema),
  });

  return (
    <form
      className={__.classNames("flex flex-col gap-12", props.className)}
      onSubmit={form.handleSubmit(props.onSubmit)}
    >
      {/* <header></header> */}
      <main className="p-2">
        <CustomerWorkOrderOverviewSection
          uploadImageLabel="Ladda upp"
          images={props.images
            .filter((img): img is { dataURL: string } => !!img.dataURL)
            .map((img) => ({ src: img.dataURL }))}
          workOrder={{
            ...props.values,
            orderId: workOrder.orderId,
            startDate: workOrder.startDate,
            confirmedByCustomer: false,
          }}
          allowImageHandling={false}
        />
      </main>
      <footer className="flex flex-col gap-2 p-2">
        <GDPRLinkAndConfirmationCheckbox form={form} />
        <AppButton>Bekräfta</AppButton>
        <AppTextButton className="flex gap-3 p-2" onClick={props.onGoBack}>
          <FiArrowLeft size={25} /> Gå tillbaka
        </AppTextButton>
      </footer>
    </form>
  );
};

export default CustomerWorkOrderOverviewFormStep;
