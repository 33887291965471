import { Handyman } from "@eljouren/domain";
import React, { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSearchParams } from "react-router-dom";
import AppTabs from "../../../components/common/tabs/AppTabs";
import HandymanContextProvider from "../../../components/handyman-context/HandymanContextProvider";
import PageHeader from "../../../components/layout/PageHeader";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import __ from "../../../utils/utils";
import HandymanCalendarRoute from "./calendar/HandymanCalendarRoute";
import HandymanDashboard from "./dashboard/HandymanDashboard";
import HandymanWorkingHoursTab from "./workings-hours/HandymanWorkingHoursTab";

export const HandymanLandingPageContext = React.createContext<{
  handyman: Handyman.Type;
  signedInWorker: Handyman.Type;
  isViewingOwnProfile: boolean;
}>({} as never);

interface Props {
  className?: string;
}

export enum HandymanLandingPageTab {
  dashboard = "Översikt",
  workingHours = "Arbetstider",
  calendar = "Kalender",
}

const HandymanLandingPage = (props: Props) => {
  const { isViewingOwnProfile, handyman } = useContext(
    HandymanLandingPageContext
  );

  const [params] = useSearchParams();

  function getTab() {
    const page = params.get("sida");
    switch (page) {
      case "arbetstider":
        return HandymanLandingPageTab.workingHours;
      case "kalender":
        return HandymanLandingPageTab.calendar;
      default:
        return HandymanLandingPageTab.dashboard;
    }
  }

  const tab = getTab();

  return (
    <section
      className={__.classNames(
        "mx-auto grid h-full w-full grid-rows-[auto,minmax(0,1fr)] md:drop-shadow-sm",
        props.className
      )}
    >
      <PageHeader
        heading={tab}
        subheading={
          !isViewingOwnProfile
            ? `För ${handyman.firstName} ${handyman.lastName}`
            : undefined
        }
      />

      <AppTabs
        tabs={Object.values(HandymanLandingPageTab)}
        selectedTab={tab}
        mainClassName="p-2 sm:p-4"
        /*  icon={(tab) => {
          const size = 20;
          switch (tab) {
            case HandymanLandingPageTab.workingHours:
              return <BiTimeFive size={size} />;
            case HandymanLandingPageTab.calendar:
              return <FiCalendar size={size} />;
            case HandymanLandingPageTab.dashboard:
            default:
              return <FiEye size={size} />;
          }
        }} */
        searchParams={(tab) => {
          let page: string;
          switch (tab) {
            case HandymanLandingPageTab.workingHours:
              page = "arbetstider";
              break;
            case HandymanLandingPageTab.calendar:
              page = "kalender";
              break;
            case HandymanLandingPageTab.dashboard:
            default:
              page = "oversikt";
          }

          // "sida" is Swedish for page
          return {
            sida: page,
          };
        }}
      >
        {tab === HandymanLandingPageTab.dashboard && <HandymanDashboard />}
        {tab === HandymanLandingPageTab.workingHours && (
          <HandymanContextProvider
            handymanId={handyman.id}
            pageIndexSearchParamKey="whIndex"
          >
            <HandymanWorkingHoursTab />
          </HandymanContextProvider>
        )}
        {tab === HandymanLandingPageTab.calendar && (
          <ErrorBoundary
            fallbackRender={(props) => (
              <SomethingWentWrong
                devError={props.error}
                description="Något gick fel när vi skulle visa kalendern. Vänligen försök igen senare!"
              />
            )}
          >
            <HandymanContextProvider
              handymanId={handyman.id}
              pageIndexSearchParamKey="cIndex"
            >
              <HandymanCalendarRoute />
            </HandymanContextProvider>
          </ErrorBoundary>
        )}
      </AppTabs>
    </section>
  );
};

export default HandymanLandingPage;
