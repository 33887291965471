import { Handyman } from "@eljouren/domain/build";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useBrand } from "../../../hooks/brand-hooks";
import AppRoutes from "../../../routes";
import { GlobalContext } from "../../../top-level-contexts";
import __ from "../../../utils/utils";
import EljourenLogo from "./EljourenLogo";
import IpisLogo from "./IpisLogo";
import RorjourenLogo from "./RorjourenLogo";

interface Props {
  wrapperClassName?: string;
  logoClassName?: string;
  onlyIcon?: boolean;
  performAnimation?: boolean;
  delay?: number;
  useShadow?: boolean;
  dontUseLink?: boolean;
  center?: boolean;
  withColor?: boolean;
}

const Wrapper = (wrapperProps: {
  worker: Handyman.Type | null | undefined;
  children?: React.ReactNode;
  className?: string;
  dontUseLink: boolean;
  style?: React.CSSProperties;
}) => {
  if (wrapperProps.worker && !wrapperProps.dontUseLink) {
    return (
      <Link
        to={AppRoutes.partner.root()}
        style={wrapperProps.style}
        className={wrapperProps.className}
      >
        {wrapperProps.children}
      </Link>
    );
  } else {
    return (
      <span className={wrapperProps.className} style={wrapperProps.style}>
        {wrapperProps.children}
      </span>
    );
  }
};

const Logo = (props: Props) => {
  const {
    signInState: { handyman: worker },
  } = useContext(GlobalContext);
  const brand = useBrand();

  return (
    <Wrapper
      className={__.classNames(
        "flex h-full max-h-full w-full max-w-full justify-center overflow-visible",
        //!props.className && "h-20 w-44",
        props.wrapperClassName
      )}
      worker={worker}
      dontUseLink={!!props.dontUseLink}
    >
      {brand === "rorjouren" && (
        <RorjourenLogo
          key={"plumber"}
          center={props.center}
          className={props.logoClassName}
          withColor={props.withColor}
        />
      )}
      {brand === "eljouren" && (
        <EljourenLogo
          key="electrician"
          onlyIcon={props.onlyIcon}
          className={props.logoClassName}
          withColor={props.withColor}
        />
      )}
      {brand === "ipis" && (
        <IpisLogo
          key="unauthenticated"
          className={props.logoClassName}
          withColor={props.withColor}
        />
      )}
    </Wrapper>
  );
};

export default Logo;
