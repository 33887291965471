import { useContext } from "react";
import HandymanWorkOrderRouteContext from "../../../routes/worker/order/HandymanWorkOrderRouteContext";
import IpisFileQueryList from "./IpisFileQueryList";

interface Props {
  className?: string;
}

const HandymanWorkOrderRelatedFilesList = (props: Props) => {
  const ctx = useContext(HandymanWorkOrderRouteContext);
  return <IpisFileQueryList res={ctx.relatedFilesRes} />;
};

export default HandymanWorkOrderRelatedFilesList;
