import {
  addDays,
  addHours,
  addMilliseconds,
  addMinutes,
  addMonths,
  addSeconds,
  addWeeks,
  addYears,
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  formatDistance,
  getHours,
  getMinutes,
  getMonth,
  getWeek,
  getYear,
  isSameMonth,
  isWeekend,
  Locale,
  setHours,
  setMinutes,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  isAfter,
  isBefore,
} from "date-fns";
import { sv } from "date-fns/locale";

export type DateHelperTimeUnit =
  | "ms"
  | "seconds"
  | "minutes"
  | "hours"
  | "days"
  | "months"
  | "weeks"
  | "years";

export default class DateHelper {
  protected readonly locale: Locale;
  readonly date: Date;

  constructor(date?: Date, locale?: Locale) {
    this.date = date || new Date();
    this.locale = locale || sv;
  }

  /*
  FINAL
  */

  get year(): number {
    return getYear(this.date);
  }

  get month(): number {
    return getMonth(this.date);
  }

  get week(): number {
    return getWeek(this.date, { locale: this.locale });
  }

  get hours(): number {
    return getHours(this.date);
  }

  get minutes(): number {
    return getMinutes(this.date);
  }

  get dayOfMonth(): number {
    return Number.parseInt(format(this.date, "d", { locale: this.locale }));
  }

  differenceInMonths(date: Date): number {
    return differenceInMonths(this.date, date);
  }

  get isWeekend(): boolean {
    return isWeekend(this.date);
  }

  isBefore(date: Date): boolean {
    return isBefore(this.date, date);
  }
  isAfter(date: Date): boolean {
    return isAfter(this.date, date);
  }

  /*
  FORMATS
  */

  get monthLabel(): string {
    return format(this.date, "LLLL", { locale: this.locale });
  }

  get yearLabel(): string {
    return format(this.date, "y", { locale: this.locale });
  }

  get hourLabel(): string {
    return format(this.date, "HH", { locale: this.locale });
  }

  get hourAndMinutesLabel(): string {
    return this.timeInputFormat;
  }

  get dateTimeInputFormat(): string {
    return format(this.date, "yyyy-MM-dd'T'HH:mm", { locale: this.locale });
  }
  get timeInputFormat(): string {
    return format(this.date, "HH:mm", { locale: this.locale });
  }

  get dateInputFormat(): string {
    return format(this.date, "yyyy-MM-dd", { locale: this.locale });
  }

  format(formatStr: string): string {
    return format(this.date, formatStr, { locale: this.locale });
  }

  formatDistance(other: Date): string {
    return formatDistance(this.date, other, { locale: this.locale });
  }

  differenceIn(
    timeUnit: DateHelperTimeUnit,
    date: Date,
    roundingMethod: "ceil" | "floor" | "round" | "trunc" = "trunc"
  ): number {
    switch (timeUnit) {
      case "ms":
        return differenceInMilliseconds(this.date, date);
      case "seconds":
        return differenceInSeconds(this.date, date, { roundingMethod });
      case "minutes":
        return differenceInMinutes(this.date, date, { roundingMethod });
      case "hours":
        return differenceInHours(this.date, date, { roundingMethod });
      case "days":
        return differenceInDays(this.date, date);
      case "weeks":
        return differenceInWeeks(this.date, date, { roundingMethod });
      case "months":
        return differenceInMonths(this.date, date);
      case "years":
        return differenceInYears(this.date, date);
    }
  }

  /*
  RECURSIVE
  */

  get startOfMonth(): DateHelper {
    return new DateHelper(startOfMonth(this.date), this.locale);
  }
  get startOfYear(): DateHelper {
    return new DateHelper(startOfYear(this.date), this.locale);
  }
  get endOfYear(): DateHelper {
    return new DateHelper(endOfYear(this.date), this.locale);
  }
  get endOfMonth(): DateHelper {
    return new DateHelper(endOfMonth(this.date), this.locale);
  }

  get startOfWeek(): DateHelper {
    return new DateHelper(
      startOfWeek(this.date, { locale: this.locale }),
      this.locale
    );
  }

  get endOfWeek(): DateHelper {
    return new DateHelper(
      endOfWeek(this.date, { locale: this.locale }),
      this.locale
    );
  }
  get startOfDay(): DateHelper {
    return new DateHelper(startOfDay(this.date), this.locale);
  }
  get endOfDay(): DateHelper {
    return new DateHelper(endOfDay(this.date), this.locale);
  }

  setHours(hours: number): DateHelper {
    return new DateHelper(setHours(this.date, hours), this.locale);
  }

  setMinutes(hours: number): DateHelper {
    return new DateHelper(setMinutes(this.date, hours), this.locale);
  }

  /*
  RECURSIVE CUSTOMISATIONS
  */

  add(type: DateHelperTimeUnit, amount: number): DateHelper {
    switch (type) {
      case "ms":
        return new DateHelper(addMilliseconds(this.date, amount));
      case "seconds":
        return new DateHelper(addSeconds(this.date, amount));
      case "minutes":
        return new DateHelper(addMinutes(this.date, amount));
      case "hours":
        return new DateHelper(addHours(this.date, amount));
      case "days":
        return new DateHelper(addDays(this.date, amount));
      case "weeks":
        return new DateHelper(addWeeks(this.date, amount));
      case "months":
        return new DateHelper(addMonths(this.date, amount));
      case "years":
        return new DateHelper(addYears(this.date, amount));
    }

    //throw new Error(`Invalid modtype '${type}'`);
  }

  sub(type: DateHelperTimeUnit, amount: number): DateHelper {
    return this.add(type, -amount);
  }

  /*
  COMPARISONS
  */

  isSameMonth(comparisonDate: Date) {
    return isSameMonth(this.date, comparisonDate);
  }
}
