import { HTTPHeaders } from "@trpc/client";
import jwtDecode from "jwt-decode";
import SearchParams from "./SearchParams";

export namespace API {
  export function base(): string {
    return process.env.REACT_APP_API_URL || "";
  }

  export function endpoint(url: string, search?: SearchParams) {
    const withoutLeadingAndTrailingSlashes = url.replace(/^\/|\/$/g, "");

    if (search) {
      return `${base()}/${withoutLeadingAndTrailingSlashes}/?${search}`;
    } else {
      return `${base()}/${withoutLeadingAndTrailingSlashes}`;
    }
  }
  export function fileBase(): string {
    return process.env.REACT_APP_PROXY_URL || "";
    //return process.env.REACT_APP_FILE_URL || "";
  }

  export function fileEndpoint(url: string, search?: URLSearchParams) {
    const withoutLeadingAndTrailingSlashes = url.replace(/^\/|\/$/g, "");

    if (search) {
      return `${fileBase()}/${withoutLeadingAndTrailingSlashes}/?${search}`;
    } else {
      return `${fileBase()}/${withoutLeadingAndTrailingSlashes}`;
    }
  }

  export function nonSignedInAuthHeader(): HTTPHeaders & HeadersInit {
    const jwt = localStorage.getItem("noCredentialsJwt");
    if (!jwt) {
      throw new Error("Customer not authenticated");
    }

    return {
      Authorization: `Bearer ${jwt}`,
    };
  }
  export function isAuthenticatedWithoutUser(): boolean {
    return !!localStorage.getItem("noCredentialsJwt");
  }

  export function setNonSignedInJwt(jwt: string): void {
    removeWorkerJwt();
    const token = jwtDecode<{ workorderId: string }>(jwt);
    localStorage.setItem("orderId", token.workorderId);
    localStorage.setItem("noCredentialsJwt", jwt);
  }
  export function removeNonSignedInJwt(): void {
    localStorage.removeItem("orderId");
    localStorage.removeItem("noCredentialsJwt");
  }

  export function workerAuthHeader(): HTTPHeaders & HeadersInit {
    const jwt = localStorage.getItem("workerJwt");
    if (!jwt) {
      throw new Error("Worker not authenticated");
    }

    return {
      Authorization: `Bearer ${jwt}`,
    };
  }
  export function workerIsAuthenticated(): boolean {
    const jwt = localStorage.getItem("workerJwt");
    const userId = getUserId();
    const isAuthenticated = !!jwt && !!userId;
    if (jwt && !userId) {
      removeWorkerJwt();
    }
    return isAuthenticated;
  }

  export function setWorkerJwt(jwt: string): void {
    const token = jwtDecode<{ userId: string }>(jwt);
    localStorage.setItem("userId", token.userId);
    localStorage.setItem("workerJwt", jwt);
  }
  export function removeWorkerJwt(): void {
    localStorage.removeItem("userId");
    localStorage.removeItem("workerJwt");
  }

  export function getUserId(): string {
    return localStorage.getItem("userId") || "";
  }
  export function getOrderId(): string {
    return localStorage.getItem("orderId") || "";
  }

  export function getOptionalAuthHeader() {
    if (workerIsAuthenticated()) {
      return workerAuthHeader();
    }

    if (isAuthenticatedWithoutUser()) {
      return nonSignedInAuthHeader();
    }

    return undefined;
  }

  export function onFailedAuth() {
    removeWorkerJwt();
    removeNonSignedInJwt();
  }
  export function getAuthHeader() {
    return workerIsAuthenticated()
      ? workerAuthHeader()
      : nonSignedInAuthHeader();
  }

  export function removeAllJwts() {
    removeNonSignedInJwt();
    removeWorkerJwt();
  }
}
