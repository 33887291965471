import { WorkOrder } from "@eljouren/domain/build";
import { useContext } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import withWorkerCredentials, {
  SignedInContext,
} from "../../../components/auth-hocs/withWorkerCredentials";
import LoadingContent from "../../../components/common/loaders/LoadingContent";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import useMutatableQuery from "../../../hooks/use-mutatable-query";
import useQueryWrapper from "../../../hooks/use-query-wrapper";
import { useRepos } from "../../../hooks/use-repos";
import { TWorkerSignInData } from "../../../_model/schemas-and-types/repo-schemas";
import HandymanWorkOrderPage from "./HandymanWorkOrderPage";
import HandymanWorkOrderRouteContext from "./HandymanWorkOrderRouteContext";

interface Props extends TWorkerSignInData {}

const HandymanWorkOrderRoute = (props: Props) => {
  const { workerGuid } = useParams();
  const { workOrderRepo } = useRepos();
  const { handyman } = useContext(SignedInContext);
  const queryClient = useQueryClient();

  const res = useMutatableQuery({
    queryKey: ["workOrder", workerGuid],
    queryFn: () => {
      return workOrderRepo.getHandymanWorkOrder(workerGuid!);
    },
    retry: 1,
    initialData: () => {
      const data = queryClient.getQueriesData(["workOrders", handyman.id]);
      const workOrders = data
        .map(([queryKey, workOrders]) => workOrders)
        .flat();

      const safeParse =
        WorkOrder.HandymanWithPermissionsSchema.array().safeParse(workOrders);

      if (safeParse.success) {
        const workOrder = safeParse.data.find(
          (wo) => wo.workerGuid === workerGuid
        );
        return workOrder;
      }

      return undefined;
    },
  });

  const order = res.query.data;
  const allowedMaterial = order?.serviceContract?.allowedMaterial;
  const allowQuickAddMaterial = !!(
    order &&
    (!order.serviceContract ||
      allowedMaterial?.includes("quickAddServiceContract") ||
      allowedMaterial?.includes("quickAddTypeOfService"))
  );
  const allowSearchAndCustomMaterial = !!(
    order &&
    (!order.serviceContract || allowedMaterial?.includes("searchAndCustom"))
  );

  const checklistRes = useMutatableQuery({
    queryKey: [
      "workOrderChecklist",
      res.query.data?.orderId,
      !!res.query.data?.checkIn,
      res.query.data?.allowedToHandleOrder,
    ],
    queryFn: async () => {
      if (!res.query.data?.checklist || !res.query.data?.allowedToHandleOrder) {
        return [];
      }

      return workOrderRepo.fetchChecklist(res.query.data.orderId);
    },
  });

  const serviceContractFilesRes = useQueryWrapper({
    queryFn: async () => {
      const order = res.query.data;
      if (!order || !order.serviceContract) {
        return [];
      }

      const result = await workOrderRepo.fetchServiceContractFiles({
        workOrderId: order.orderId,
      });
      return result;
    },
    queryKey: [res.query.data?.orderId, "serviceContractFiles"],
  });

  const relatedFilesRes = useQueryWrapper({
    queryFn: async () => {
      const order = res.query.data;
      if (!order) {
        return [];
      }

      return workOrderRepo.fetchRelatedFiles({
        workOrderId: order.orderId,
      });
    },
    queryKey: [res.query.data?.orderId, "relatedFiles"],
  });

  const extraHourRes = useQueryWrapper({
    queryKey: ["extraHour", res.query.data],
    queryFn: () => {
      const data = res.query.data;
      if (!data?.orderId) {
        return undefined;
      }
      if (!data.allowedToHandleOrder) {
        return undefined;
      }

      return workOrderRepo.fetchExtraHour({
        workOrderId: data.orderId,
      });
    },
  });
  const quickAddProductsRes = useQueryWrapper({
    queryKey: ["quickAddProducts", res.query.data, allowQuickAddMaterial],
    queryFn: () => {
      const data = res.query.data;
      if (!data?.orderId) {
        return [];
      }
      if (!data.allowedToHandleOrder) {
        return [];
      }
      if (!allowQuickAddMaterial) {
        return [];
      }
      return workOrderRepo.fetchQuickAddProducts({
        workOrderId: data.orderId,
      });
    },
  });

  const reportedMaterialRes = useMutatableQuery({
    queryKey: ["reportedMaterials", res.query.data?.orderId],
    queryFn: () => {
      const data = res.query.data;
      if (!data?.orderId) {
        return [];
      }
      if (!data.allowedToHandleOrder) {
        return [];
      }
      return workOrderRepo.fetchReportedMaterials({
        workOrderId: data.orderId,
      });
    },
  });

  const reportedHoursRes = useMutatableQuery({
    queryKey: ["reportedHours", res.query.data?.orderId],
    queryFn: () => {
      const data = res.query.data;
      if (!data?.orderId) {
        return [];
      }
      if (!data.allowedToHandleOrder) {
        return [];
      }
      return workOrderRepo.fetchReportedHours({
        workOrderId: data.orderId,
      });
    },
  });

  const showError =
    res.query.isError || (!res.query.isLoading && !res.query.data);

  return (
    <LoadingContent
      loading={res.query.isLoading && !res.query.data}
      renderContentSeparately
    >
      {showError && (
        <SomethingWentWrong
          devError={res.query.error}
          description="Denna order går inte att hämta just nu, vilket förmodligen betyder att den inte finns."
        />
      )}
      {!showError && res.query.data && (
        <HandymanWorkOrderRouteContext.Provider
          value={{
            orderRes: res,
            order: res.query.data,
            checklistRes,
            serviceContractFilesRes,
            relatedFilesRes,
            reportedHoursRes,
            reportedMaterialRes,
            quickAddProductsRes,
            extraHourRes,
            allowQuickAddMaterial,
            allowSearchAndCustomMaterial,
          }}
        >
          <HandymanWorkOrderPage />
        </HandymanWorkOrderRouteContext.Provider>
      )}
    </LoadingContent>
  );
};

export default withWorkerCredentials(HandymanWorkOrderRoute);
