import { IpisFile } from "@eljouren/file-schemas/build";
import { motion } from "framer-motion";
import __ from "../../../utils/utils";
import IpisFileListItem from "./IpisFileListItem";

interface Props {
  className?: string;
  data: IpisFile.WithMetaType[] | undefined;
}

const IpisFileDataList = (props: Props) => {
  if (!props.data?.length) {
    return <span className="flex p-1">Det finns inga filer att visa</span>;
  }

  /* 
    	ToDo: 
      		* Cool animations :sunglasses:
  	*/

  return (
    <motion.ul
      className={__.classNames(
        "flex h-full w-full flex-col gap-2 overflow-y-auto",
        props.className
      )}
    >
      {props.data.map((file) => (
        <IpisFileListItem key={file.name} file={file} />
      ))}
    </motion.ul>
  );
};

export default IpisFileDataList;
