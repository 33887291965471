import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import AppHeader from "./components/layout/AppHeader";
import { AppMain } from "./components/layout/AppMain";
import BrandLogic from "./components/layout/BrandLogic";
import Modal from "./components/notifications/Modal";
import { useBind } from "./hooks/observable-hooks";
import { useRepos } from "./hooks/use-repos";
import { GlobalContext } from "./top-level-contexts";

const queryClient = new QueryClient();
function fixAppHeightForMobileDevices() {
  const doc = document.documentElement;
  doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
}

window.addEventListener("resize", fixAppHeightForMobileDevices);
fixAppHeightForMobileDevices();

const { fetch: origFetch } = window;

let dateForLastHighLoadToast: Date | undefined;

window.fetch = async (...args) => {
  const response = await origFetch(...args);

  if (response.status === 503) {
    if (dateForLastHighLoadToast) {
      const now = new Date();
      const duration = Number(now) - Number(dateForLastHighLoadToast);
      const fiveMinutesInMs = 1000 * 60 * 5;
      if (duration < fiveMinutesInMs) {
        // Return early
        return response;
      }
    }

    dateForLastHighLoadToast = new Date();
    window.modal.toast({
      id: "high-load-toast",
      title: "Hög belastning",
      prompt:
        "Det är ovanligt hög belastning just nu och vissa saker kan därför tillfälligt sluta fungera.",
      toastType: "error",
      timeVisibleInMs: 5000,
    });
  }
  return response;
};

interface Props {
  children?: React.ReactNode;
}

function App(props: Props) {
  const { authRepo, workOrderRepo: customerOrderRepo } = useRepos();
  const signInState = useBind(authRepo.signedInState);

  /*
    To show the right logo based on brand.
    Not really a fan of this.
  */
  useEffect(() => {
    const unsub = customerOrderRepo.onWorkOrderFetched((order) =>
      authRepo.notifyCustomerOrderFetched(order)
    );
    return unsub;
  });

  /*
    Clear all data when the user signs out 
  */
  useEffect(() => {
    if (!signInState.isSignedIn) {
      queryClient.clear();
    }
  }, [signInState.isSignedIn]);

  /* 
    So that the profile route can use the cached data
  */
  useEffect(() => {
    queryClient.setQueryData("signInStateProfile", signInState.handyman);
  }, [signInState.isSignedIn, signInState.handyman]);

  const location = useLocation();

  return (
    <GlobalContext.Provider
      value={{
        signInState,
        location,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <AppHeader />
        <AnimatePresence>
          <AppMain>
            <Outlet />
            {props.children}
          </AppMain>
        </AnimatePresence>

        <BrandLogic />
        <Modal />
      </QueryClientProvider>
    </GlobalContext.Provider>
  );
}

export default App;
