import { useContext, useEffect, useRef } from "react";
import AppPages from "../../../../components/common/pages/AppPages";
import HandymanContext from "../../../../components/handyman-context/HandymanContext";
import { useImmutable, useNumber } from "../../../../hooks/hooks";
import {
  TailwindBreakpoint,
  useMediaQuery,
} from "../../../../hooks/use-media-query";
import __ from "../../../../utils/utils";
import DatePeriod from "../../../../_model/helpers/AvailabilityPeriod";
import HandymanWorkingHoursFormSection from "./HandymanWorkingHoursFormSection";
import HandymanWorkingHoursSelectDatesSection from "./HandymanWorkingHoursSelectDatesSection";

interface Props {
  noEdit?: boolean;
}

const HandymanWorkingHoursTab = (props: Props) => {
  const isLg = useMediaQuery(TailwindBreakpoint.lg);
  const ctx = useContext(HandymanContext);
  const pageIndex = useNumber(0, { max: 1 });
  const period = useRef(new DatePeriod({ weeksInPeriod: 2 })).current;
  const selectedDates = useImmutable<Record<string, true>>({});

  /*
  Including selectedDates in dependency array will cause infinite loop as the reference will be new after overriding  
  */
  useEffect(() => {
    selectedDates.override({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.page]);

  if (isLg) {
    return (
      <main
        className={__.classNames("grid gap-4", !props.noEdit && "grid-cols-2")}
      >
        <HandymanWorkingHoursSelectDatesSection
          period={period}
          noEdit={!!props.noEdit}
          selectedDates={selectedDates}
          //nextPage={() => pageIndex.increment()}
        />

        {!props.noEdit && (
          <HandymanWorkingHoursFormSection
            selectedDates={selectedDates}
            //goBack={() => pageIndex.decrement()}
          />
        )}
      </main>
    );
  } else {
    return (
      <AppPages pageIndex={pageIndex.value}>
        {pageIndex.value === 0 && (
          <HandymanWorkingHoursSelectDatesSection
            period={period}
            noEdit={!!props.noEdit}
            selectedDates={selectedDates}
            nextPage={() => pageIndex.increment()}
          />
        )}
        {pageIndex.value === 1 && (
          <HandymanWorkingHoursFormSection
            selectedDates={selectedDates}
            goBack={() => pageIndex.decrement()}
          />
        )}
      </AppPages>
    );
  }
};

export default HandymanWorkingHoursTab;
