import { Handyman, HandymanProvince } from "@eljouren/domain/build";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { FaSignOutAlt } from "react-icons/fa";
import { FiArrowLeft } from "react-icons/fi";
import { useQuery } from "react-query";
import { useRepos } from "../../../hooks/use-repos";
import { __tsx } from "../../../utils/tsxUtils";
import __ from "../../../utils/utils";
import { AppButton } from "../../common/buttons/AppButton";
import AppTextButton from "../../common/buttons/AppTextButton";
import { AppFormCheckbox } from "../../common/checkboxes/AppFormCheckbox";
import { AppLoader } from "../../common/loaders/AppLoader";
import PageSection from "../../common/PageSection";
import ProfileFormMergedInput from "./ProfileFormMergedInput";

interface Props {
  className?: string;
  user: Handyman.Type;
  loading?: boolean;
  updateProfileInfo(worker: Handyman.Type): Promise<void>;
}

const ProfileFormSection = (props: Props) => {
  const { authRepo, handymanRepo } = useRepos();

  const provinceRes = useQuery(["provinces"], () =>
    handymanRepo.fetchProvincesWithCounties()
  );

  const form = useForm<Handyman.Type>({
    resolver: zodResolver(Handyman.Schema),
    defaultValues: props.user,
  });

  useEffect(() => {
    form.reset(props.user);
  }, [props.user]);

  async function onSubmit(values: Handyman.Type) {
    props.updateProfileInfo(values);
  }

  function onSignOut(e: React.MouseEvent) {
    e.preventDefault();
    authRepo.signOut();
  }

  const dialogRef = useRef<HTMLDialogElement | null>(null);

  function openDialog() {
    //@ts-ignore
    dialogRef.current?.showModal();
  }

  function closeDialog() {
    //@ts-ignore
    dialogRef.current?.close();
  }

  function saveActiveAreas(activeAreas: string[]) {
    closeDialog();
    props.updateProfileInfo({
      ...props.user,
      activeAreas: activeAreas.sort(),
    });
  }

  return (
    <PageSection
      as="section"
      className={__.classNames("flex h-full flex-col", props.className)}
    >
      <header className="flex flex-col items-center justify-center py-6">
        <h3 className="text-lg font-bold text-black">
          {props.user.firstName} {props.user.lastName}
        </h3>
        <span className="text-sm">{props.user.email}</span>
      </header>
      <form
        className={__.classNames(
          "mx-auto flex w-full max-w-xl flex-col gap-4 p-2 py-4 xs:p-6 md:gap-6 md:p-6",
          props.loading && "pointer-events-none opacity-50",
          props.className
        )}
      >
        <main className="flex flex-col gap-2">
          <ProfileFormMergedInput
            label="Adress"
            value={`${props.user.street}, ${props.user.postalCode} ${props.user.city}`}
            //canEdit
            id="addressProfileFormInput"
            paths={[
              { label: "Gata", path: "street" },
              { label: "Postnummer", path: "postalCode" },
              { label: "Stad", path: "city" },
            ]}
            //onSave={form.handleSubmit(onSubmit)}
            form={form}
            canEdit={false}
          />
          <ProfileFormMergedInput
            label="Mobil"
            value={props.user.phone}
            canEdit
            id="phoneProfileFormInput"
            paths={["phone"]}
            form={form}
            onSave={form.handleSubmit(onSubmit)}
          />

          <ProfileFormMergedInput
            label="Aktiva områden"
            value={props.user.activeAreas.join(", ")}
            canEdit
            id="activeAreasProfileFormInput"
            onEdit={openDialog}
          />
        </main>
      </form>
      <footer className="my-auto mx-auto flex w-full max-w-xl flex-col gap-2 p-4">
        <AppTextButton
          onClick={onSignOut}
          className="flex items-center gap-3 text-sm"
        >
          <FaSignOutAlt
            size={30}
            className="rounded-full bg-red-100 p-2 text-red-400"
          />
          Logga ut
        </AppTextButton>
      </footer>
      <dialog
        ref={dialogRef}
        className="h-full max-h-[70vh] w-full max-w-md bg-white/95 transition-all"
      >
        {/* 
            Might have to pass in closeDialog and props, if we should follow best practices
          */}
        {__tsx.renderOne(provinceRes, [
          (res) =>
            res.isError && <p>Det gick inte att hämta områden just nu</p>,
          (res) => res.isLoading && <AppLoader />,
          (res) =>
            !!res.data && (
              <ActiveAreasForm
                {...props}
                goBack={closeDialog}
                provinces={res.data}
                save={saveActiveAreas}
              />
            ),
        ])}
      </dialog>
    </PageSection>
  );
};

type TActiveAreasFormValues = { activeAreas: string[] };

const ActiveAreasForm = (props: {
  user: Handyman.Type;
  goBack: () => void;
  provinces: HandymanProvince.Type[];
  save(areas: string[]): void;
}) => {
  const form = useForm<TActiveAreasFormValues>({
    defaultValues: {
      activeAreas: props.user.activeAreas,
    },
  });

  function onSubmit(values: TActiveAreasFormValues) {
    props.save(values.activeAreas);
  }

  return (
    <form
      className="grid h-full grid-rows-hmf flex-col gap-2 overflow-hidden"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <h2 className="text-2xl">Aktiva områden</h2>
      <main className="flex flex-col gap-4 overflow-y-auto">
        {props.provinces.map((province) => (
          <details key={province.name}>
            <summary className="cursor-pointer text-xl font-semibold">
              {province.name}
            </summary>
            <fieldset className="flex flex-col gap-2 py-2">
              {province.counties.sort().map((county) => (
                <AppFormCheckbox
                  key={county}
                  label={county}
                  type="checkbox"
                  register={form.register}
                  //@ts-ignore
                  name={`activeAreas[]`}
                  value={county}
                />
              ))}
            </fieldset>
          </details>
        ))}
      </main>

      <footer className="flex flex-col items-end justify-end gap-2">
        <AppButton>Spara aktiva områden</AppButton>
        <AppTextButton className="flex gap-2" onClick={props.goBack}>
          <FiArrowLeft size={25} />
          Gå tillbaka
        </AppTextButton>
      </footer>
    </form>
  );
};

export default ProfileFormSection;
