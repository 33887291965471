"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorLog = void 0;
var zod_1 = require("zod");
var ErrorLogSignInInfo_1 = require("./ErrorLogSignInInfo");
var ErrorLogType_1 = require("./ErrorLogType");
var ErrorLog;
(function (ErrorLog) {
    var BaseSchema = zod_1.z.object({
        id: zod_1.z.string(),
        clientId: zod_1.z.string(),
        date: zod_1.z.date(),
    });
    ErrorLog.MoreThanOneContactAdditionalInformation = zod_1.z.object({
        email: zod_1.z.string(),
        contacts: zod_1.z
            .object({
            id: zod_1.z.string(),
            firstName: zod_1.z.string(),
            lastName: zod_1.z.string(),
        })
            .array(),
    });
    ErrorLog.MoreThanOneContactLinkedToEmailSchema = BaseSchema.extend({
        type: zod_1.z.literal(ErrorLogType_1.ErrorLogType.Enum.moreThanOneContactLinkedToEmail),
        signInInfo: ErrorLogSignInInfo_1.ErrorLogSignInInfo.NotSignedInSchema,
        additionalInformation: ErrorLog.MoreThanOneContactAdditionalInformation,
    });
    ErrorLog.Schema = zod_1.z.union([
        ErrorLog.MoreThanOneContactLinkedToEmailSchema,
        BaseSchema.extend({ type: zod_1.z.literal("test") }),
    ]);
})(ErrorLog || (ErrorLog = {}));
exports.ErrorLog = ErrorLog;
