import { useContext } from "react";
import __ from "../../utils/utils";
import DateHelper from "../../_model/helpers/DateHelper";
import { CalendarContext } from "./Calendar";

interface Props {
  className?: string;
  dayHelper: DateHelper;
  index: number;
  selectDay(day: DateHelper): void;
}

const CalendarDayGridItem = (props: Props) => {
  const { calendarHelper } = useContext(CalendarContext);
  const { dayHelper } = props;

  /*
  Perhaps move into the helper class
    */
  const isFirstColumn = props.index % 7 === 0;
  const isFirstRow = props.index < 7;

  return (
    <div
      onClick={() => props.selectDay(props.dayHelper)}
      className={__.classNames(
        "flex h-full w-full cursor-pointer flex-col border p-1 text-xs hover:bg-main-bg-light/20",
        !isFirstColumn && "border-l-transparent",
        !isFirstRow && "border-t-transparent"
      )}
      style={{
        gridColumn: calendarHelper.gui.getGridColumn(dayHelper.date),
        gridRow: calendarHelper.gui.getGridRow(dayHelper.date),
      }}
    >
      <span
        style={calendarHelper.gui.gridItemHeadingStyle(
          false
          //calendarHelper.isSameMonth(props.dayHelper.date)
        )}
      >
        {dayHelper.dayOfMonth}
      </span>
      <ul className="flex grow flex-col gap-1 overflow-hidden">
        {calendarHelper.events.for(dayHelper.date).map((event) => {
          const { start, end } = event.props;
          const startHelper = new DateHelper(start);
          const endHelper = new DateHelper(end);
          return (
            <li
              key={event.props.id}
              style={calendarHelper.gui.gridItemEventStyle(event)}
              className="flex flex-col items-center text-sm "
            >
              <span className="italic">{startHelper.hourAndMinutesLabel}</span>
              <span className="text-[0.35rem] leading-[0.45rem]">|</span>
              <span className="italic">{endHelper.hourAndMinutesLabel}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CalendarDayGridItem;
