import { Brand } from "@eljouren/domain";
import { useContext } from "react";
import { GlobalContext } from "../top-level-contexts";

export function useBrand(): Brand.Type {
  const { signInState } = useContext(GlobalContext);

  if (!signInState.isSignedIn) {
    //console.log("use brand returning ipis because not signed in");
    return "ipis";
  }

  if (signInState.signedInAs === "worker") {
    const { profession } = signInState.handyman;
    const plumber =
      profession === "plumber" || profession === "plumber-spolbil";
    if (plumber) {
      return "rorjouren";
    }
    return "eljouren";
  } else {
    //console.log("returning sign in state brand", signInState);
    return signInState.brand;
  }
}
