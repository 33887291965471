"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileMeta = void 0;
var zod_1 = require("zod");
var FileMeta;
(function (FileMeta) {
    FileMeta.Schema = zod_1.z.object({
        recordId: zod_1.z.string(),
        description: zod_1.z.string().optional().nullable(),
        customName: zod_1.z.string().optional().nullable(),
        createdDate: zod_1.z.date(),
        mimeType: zod_1.z.string().optional().nullable(),
    });
})(FileMeta || (FileMeta = {}));
exports.FileMeta = FileMeta;
