import { TRepoContextValue } from "../../top-level-contexts";
import IErrorLogRepo from "../../_model/repos/interfaces/IErrorLogRepo";
import WorkingAuthRepoMock from "./auth-repo/WorkingAuthRepoMock";
import WorkingCompanyRepoMock from "./company-repo/WorkingCompanyRepoMock";
import WorkingHandymanRepoMock from "./handyman-repo/WorkingHandymanRepoMock";
import WorkingWorkOrderRepoMock from "./work-order-repo/WorkingWorkOrderRepoMock";

const SimulatedRepoContextValue: TRepoContextValue = {
  authRepo: new WorkingAuthRepoMock(),
  companyRepo: new WorkingCompanyRepoMock(),
  workOrderRepo: new WorkingWorkOrderRepoMock(),
  handymanRepo: new WorkingHandymanRepoMock(),
  // Todo,
  get errorLogRepo(): IErrorLogRepo {
    throw new Error("Not implemented");
  },
};

export default SimulatedRepoContextValue;
