import React from "react";
import withSalesGuid from "../../../components/auth-hocs/withSalesGuid";
import LoadingContent from "../../../components/common/loaders/LoadingContent";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import { useRepos } from "../../../hooks/use-repos";
import { WorkOrder } from "@eljouren/domain";

import SalesTeamOrderPage from "./SalesTeamOrderPage";
import { useQuery, UseQueryResult } from "react-query";

export const SalesOrderRouteContext = React.createContext<{
  orderRes: UseQueryResult<WorkOrder.CustomerType>;
}>({} as never);

const SalesTeamOrderRoute = () => {
  const { workOrderRepo } = useRepos();
  const res = useQuery("workOrderByStaffGuid", () =>
    workOrderRepo.getByStaffGuid()
  );

  const showError = res.isError || (!res.isLoading && !res.data);

  return (
    <LoadingContent
      loading={res.isFetching && !res.data}
      renderContentSeparately
    >
      {showError && (
        <SomethingWentWrong
          devError={res.error}
          description="Denna order går inte att hämta just nu, vilket förmodligen betyder att den inte finns."
        />
      )}
      {!showError && res.data && (
        <SalesOrderRouteContext.Provider value={{ orderRes: res }}>
          <SalesTeamOrderPage order={res.data} />
        </SalesOrderRouteContext.Provider>
      )}
    </LoadingContent>
  );
};

export default withSalesGuid(SalesTeamOrderRoute);
