import { useEffect, useState } from "react";
import {
  Observable,
  PrivateObservable,
} from "../_model/observables/Observable";

export function useBind<T>(
  obs: PrivateObservable<T> | Observable<T>,
  onChange?: (newValue: T, previousValue: T) => void
): T {
  const [state, setState] = useState(obs.value);

  useEffect(() => {
    const unsubscribe = obs.subscribeWithInitial((newValue: T) => {
      if (onChange) {
        onChange(newValue, state);
      }
      setState(newValue);
    });

    return unsubscribe;
  });

  return state;
}

export function useBindWithPrevious<T>(
  obs: PrivateObservable<T> | Observable<T>
): {
  current: T;
  previous?: T;
} {
  const [state, setState] = useState<{
    current: T;
    previous?: T;
  }>({ current: obs.value });

  useEffect(() => {
    const unsubscribe = obs.subscribeWithInitial(
      (newValue: T, oldValue?: T) => {
        setState({
          current: newValue,
          previous: oldValue,
        });
      }
    );

    return unsubscribe;
  });

  return state;
}
