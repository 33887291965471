import {
  Handyman,
  HandymanProfession,
  HandymanProvince,
  HandymanWorkingHours,
} from "@eljouren/domain/build";
import trpcClient from "../../trpc-setup";
import __ from "../../utils/utils";
import DateHelper from "../helpers/DateHelper";
import IHandymanRepo from "./interfaces/IHandymanRepo";

export default class HandymanRepo implements IHandymanRepo {
  async fetchHandyman(handymanId: string): Promise<Handyman.Type> {
    return trpcClient.handyman.single.query({ handymanId });
  }

  /*
  Needs to be paginated
  */
  async fetchByProfession(
    profession: HandymanProfession.Type
  ): Promise<Handyman.Type[]> {
    return trpcClient.handyman.byProfession.query({ profession });
  }

  async updateProfileInfo(handyman: Handyman.EditableType): Promise<boolean> {
    return trpcClient.handyman.updateProfile.mutate(handyman);
  }

  async fetchProvincesWithCounties(): Promise<HandymanProvince.Type[]> {
    return trpcClient.handyman.provinces.query();
  }

  async fetchWorkingHours(args: {
    handymanId: string;
    interval: { start: Date; end: Date };
  }): Promise<HandymanWorkingHours.DictIndexedByStartOfDayType> {
    const res = await trpcClient.handyman.workingHours.query({
      handymanId: args.handymanId,
      interval: {
        start: args.interval.start.toISOString(),
        end: args.interval.end.toISOString(),
      },
    });

    const withDates: HandymanWorkingHours.DictIndexedByStartOfDayType = {};

    res.forEach((wh) => {
      const start = new Date(wh.start);
      const end = new Date(wh.end);
      const startHelper = new DateHelper(start);
      const key = startHelper.startOfDay.date.getTime();
      withDates[key] = {
        ...wh,
        start,
        end,
      };
    });

    return withDates;
  }
  async saveWorkingHours(args: {
    handymanId: string;
    workingHours: HandymanWorkingHours.DictIndexedByStartOfDayType;
  }): Promise<void> {
    /*
    Lmao, this type annotation
    */
    const withStringDates: Parameters<
      typeof trpcClient["handyman"]["saveWorkingHours"]["mutate"]
    >["0"]["workingHours"] = __.objectMap(args.workingHours, (val) => {
      return {
        status: val.status,
        start: val.start.toISOString(),
        end: val.end.toISOString(),
      };
    });

    await trpcClient.handyman.saveWorkingHours.mutate({
      handymanId: args.handymanId,
      workingHours: withStringDates,
    });
  }
}
