import { AnimatePresence, motion, useReducedMotion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { usePrevious } from "../../../hooks/hooks";
import __ from "../../../utils/utils";
import AppTextButton from "../buttons/AppTextButton";

const variants = {
  enter: (options: { forward?: boolean; reducedMotion?: boolean }) => {
    let x: number;
    if (options.reducedMotion) {
      x = 0;
    } else {
      x = options.forward ? 1000 : -1000;
    }

    return {
      x,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (options: { forward?: boolean; reducedMotion?: boolean }) => {
    let x: number;
    if (options.reducedMotion) {
      x = 0;
    } else {
      x = !options.forward ? 1000 : -1000;
    }
    return {
      zIndex: 0,
      x,
      opacity: 0,
    };
  },
};

interface Props {
  className?: string;
  preventForwardNavigation?: boolean;
  pageIndex: number;
  minWidth?: number | string;
  minHeight?: number | string;
  mainGridRow?: number;
  pagination?: {
    minIndex?: number;
    maxIndex: number;
    setPageIndex: (index: number) => void;
  };
  heading?: string | ((pageIndex: number) => string);
  onlyMain?: boolean;
  children?: React.ReactNode;
  //mainClassName?: string;
}

const AppPages: React.FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const { current } = ref;
    if (current) {
      current.scrollIntoView();
    }
  }, [props.pageIndex]);

  if (props.onlyMain) {
    return <AppPageMain {...props} />;
  }

  const increment = () => {
    if (!props.preventForwardNavigation) {
      props.pagination?.setPageIndex(props.pageIndex + 1);
    }
  };

  const decrement = () => {
    props.pagination?.setPageIndex(props.pageIndex - 1);
  };

  const getHeading = () => {
    if (!props.heading) {
      return "";
    } else if (typeof props.heading === "string") {
      return props.heading;
    } else {
      return props.heading(props.pageIndex);
    }
  };

  return (
    <AnimatePresence initial={false}>
      {/* 
      Adding grid-cols-1, to explicitly define the one column grid and potentially prevent overflow,
      makes the grid have two columns, and I can't for the life of me figure out why

      Removing the class for now
    */}
      <motion.div
        ref={ref}
        className={__.classNames(
          "grid grid-rows-[auto_minmax(0,1fr)]",
          props.className,
          // Not purgable and therefor wont work
          props.minWidth && `min-w-[${props.minWidth}]`,
          props.minHeight && `min-w-[${props.minHeight}]`,
          (props.pagination || props.heading) && "gap-4"
        )}
      >
        {!!props.heading ||
          (!!props.pagination && (
            <header>
              {!!props.heading && (
                <AnimatePresence>
                  <motion.h3
                    key={props.pageIndex + "-heading"}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, filter: "blur(0)" }}
                    exit={{ opacity: 0, filter: "blur(1px)" }}
                  >
                    {getHeading()}
                  </motion.h3>
                </AnimatePresence>
              )}
              {!!props.pagination && (
                <span className="m-auto mr-0 flex items-center gap-2">
                  <AppTextButton
                    disabled={
                      props.pageIndex === (props.pagination.minIndex || 0)
                    }
                    onClick={decrement}
                  >
                    Previous
                  </AppTextButton>

                  <AppTextButton
                    disabled={
                      props.pageIndex === props.pagination.maxIndex ||
                      props.preventForwardNavigation
                    }
                    onClick={increment}
                  >
                    Next
                  </AppTextButton>
                  <span className="flex items-center text-sm italic">
                    {props.pageIndex + 1} / {props.pagination.maxIndex + 1}
                  </span>
                </span>
              )}
            </header>
          ))}

        <AppPageMain {...props} />
      </motion.div>
    </AnimatePresence>
  );
};

const AppPageMain = (props: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const previousIndex = usePrevious(props.pageIndex);
  const reducedMotion = useReducedMotion();

  /* useLayoutEffect(() => {
    const { current } = ref;
    if (current) {
      current.scrollIntoView();
    }
  }, [props.pageIndex]); */

  function custom(): {
    forward: boolean;
    reducedMotion: boolean;
  } {
    return {
      forward: props.pageIndex >= (previousIndex || 0),
      reducedMotion: !!reducedMotion,
    };
  }

  return (
    <AnimatePresence initial={false} custom={custom()}>
      <motion.div
        ref={ref}
        className={__.classNames(
          `col-start-1 col-end-1 h-full`,
          props.onlyMain && props.className
        )}
        style={{
          gridRowStart: props.mainGridRow ?? 2,
          gridRowEnd: props.mainGridRow ?? 2,
        }}
        key={props.pageIndex}
        custom={custom()}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 220, damping: 30, duration: 0.35 },
          opacity: { duration: 0.2 },
        }}
      >
        {props.children}
      </motion.div>
    </AnimatePresence>
  );
};

export default AppPages;
