import { WorkOrder } from "@eljouren/domain";
import { useContext } from "react";
import { BiCheck, BiImage } from "react-icons/bi";
import { FaHandshake } from "react-icons/fa";
import { SiGooglemaps } from "react-icons/si";
import { Link } from "react-router-dom";
import AppRoutes from "../../routes";
import __ from "../../utils/utils";
import DateHelper from "../../_model/helpers/DateHelper";
import WorkOrderFileContextProvider, {
  WorkOrderFileContext,
} from "../order-image-wrapper/WorkOrderFileContextProvider";

interface Props {
  className?: string;
  order: WorkOrder.HandymanType;
  goBackTo?: string;
  goBackToLabel?: string;
  //noShadow?: boolean;
}

const HandymanWorkOrderListItem = (props: Props) => {
  return (
    <WorkOrderFileContextProvider orderId={props.order.orderId}>
      <WrappedWithImages {...props} />
    </WorkOrderFileContextProvider>
  );
};

const WrappedWithImages = (props: Props) => {
  const { fileRes: imageRes } = useContext(WorkOrderFileContext);
  const hasPassed = new DateHelper(props.order.endDate).isBefore(new Date());

  function getImageCountString() {
    if (imageRes.isLoading) {
      return "...";
    }

    const workerLength = imageRes.query.data?.length || 0;

    return `x${workerLength}`;
  }

  function addressString(): string | false {
    const { city, street, postalCode } = props.order.location;
    if (!city || !street || !postalCode) {
      return false;
    }
    return `${street}, ${postalCode} ${city}`;
  }

  const startHelper = new DateHelper(props.order.startDate);
  const endHelper = new DateHelper(props.order.endDate);

  function googleMapsUrl(): string {
    const address = addressString();
    if (!address) {
      return "";
    }
    const base = "https://www.google.com/maps/search/?";
    const params = new URLSearchParams({
      api: "1",
      query: address,
    });
    return `${base}${params}`;
  }

  const address = addressString();

  return (
    <li
      key={props.order.orderId}
      className={__.classNames(
        "flex flex-col rounded border border-gray-300 hover:shadow",
        props.className
      )}
      //onClick={props.event.props.onClick}
      //style={{ background: props.background }}
    >
      <Link
        to={AppRoutes.partner.customerOrder(props.order.workerGuid)}
        state={{
          order: props.order,
          goBackTo: props.goBackTo,
          goBackToLabel: props.goBackToLabel,
        }}
        className="flex cursor-pointer flex-col gap-2 p-3 pb-0 lg:p-6 lg:pb-0"
      >
        <header className="flex flex-col gap-1">
          <span className="grid grid-cols-[minmax(0,1fr),auto,auto,auto] gap-1">
            <h4 className="truncate text-base">{props.order.description}</h4>

            <div className="flex items-center">
              <BiImage size={20} />
              <span className="text-sm">{getImageCountString()}</span>
            </div>
            {!!props.order.serviceContract && (
              <FaHandshake size={25} className="text-orange-400" />
            )}
            {props.order.isFinished && (
              <BiCheck size={25} className="text-green-600" />
            )}
          </span>
          <span className="text-sm">#{props.order.serialNumber}</span>
          <span className="text-sm">
            {[
              props.order.typeOfService,
              ...props.order.categories.map((cat) => cat.name),
            ].join(", ")}
          </span>

          <div className="flex flex-col justify-between lg:flex-row lg:items-center">
            <span
              className={__.classNames(
                "text-sm italic",
                hasPassed && "text-red-600"
              )}
            >{`${startHelper.hourAndMinutesLabel} - ${endHelper.hourAndMinutesLabel}`}</span>
          </div>
        </header>

        <span className="text-sm">{props.order.additionalInformation}</span>
      </Link>
      <span className="flex flex-col p-3 lg:px-6 lg:pb-6">
        {!address && (
          <span className={__.classNames("flex text-sm")}>
            {"Okänd address"}
          </span>
        )}
        {address && (
          <a
            className={__.classNames(
              "mr-auto flex items-center gap-2 text-sm hover:underline lg:mr-0 lg:ml-auto lg:p-0"
            )}
            href={googleMapsUrl()}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <SiGooglemaps size={15} />
            <span>{address}</span>
          </a>
        )}
      </span>
    </li>
  );
};

export default HandymanWorkOrderListItem;
