import { motion } from "framer-motion";
import React, { useRef } from "react";
import { useOnOutsideClick } from "../../../hooks/hooks";
import __ from "../../../utils/utils";

const variants = {
  open: { clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" },
  closed: { clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)" },
  exit: {
    // Animating to same as open apparently doesn't work
    clipPath: "polygon(0 1%, 100% 1%, 100% 1%, 0 1%)",
  },
};

interface Props {
  className?: string;
  children?: React.ReactNode;
  onOutsideClick?(): void;
  toggleRef?: React.MutableRefObject<HTMLElement | null>;
}

const ContextLikeMenu: React.FC<Props> = (props) => {
  const ref = useRef<HTMLElement | null>(null);

  useOnOutsideClick(
    ref,
    () => {
      props.onOutsideClick && props.onOutsideClick();
    },
    props.toggleRef
  );

  return (
    <motion.aside
      className={__.classNames(
        "absolute z-50 flex w-[max(auto,150%)] max-w-[150%] origin-top overflow-visible bg-bg-base-layer p-2",
        props.className
      )}
      ref={ref}
      variants={variants}
      initial="closed"
      animate="open"
      exit="exit"
      transition={{
        duration: 0.3,
      }}
    >
      {props.children}
    </motion.aside>
  );
};

export default ContextLikeMenu;
