import {
  Checklist,
  ChecklistAnswers,
  WorkOrder,
  WorkOrderConfirmation,
  WorkOrderEditableFields,
  WorkOrderExtraHour,
  WorkOrderLineItem,
} from "@eljouren/domain/build";
import WorkOrderFinishedReport from "@eljouren/domain/build/work-order/WorkOrderFinishedReport";
import { IpisFile, UploaderSource } from "@eljouren/file-schemas/build";
import { ImageListType } from "react-images-uploading";
import { FullImageType } from "../../../components/customer-order/WorkOrderImageUpload";
import IWorkOrderRepo, {
  CheckInOutParams,
  TWorkOrderConfirmationResponse,
} from "../../../_model/repos/interfaces/IWorkOrderRepo";
import WorkOrderMockData from "./WorkOrderMockData";

/* 
	Functionality will be assed as we go along, as needed
*/
export default class WorkingWorkOrderRepoMock implements IWorkOrderRepo {
  onWorkOrderFetched(callback: (order: WorkOrder.Type) => void): void {
    //...
  }
  async getByCustomerGuid(): Promise<WorkOrder.CustomerType> {
    return WorkOrderMockData.customerOrder;
  }
  async getByStaffGuid(): Promise<WorkOrder.CustomerType> {
    return WorkOrderMockData.customerOrder;
  }
  async getHandymanWorkOrder(
    guid: string
  ): Promise<WorkOrder.HandymanWithPermissionsType> {
    return WorkOrderMockData.handymanOrder;
  }
  getForHandymanBetween(args: {
    handymanId: string;
    interval: { start: Date; end: Date };
  }): Promise<WorkOrder.HandymanWithPermissionsType[]> {
    throw new Error("Method not implemented.");
  }
  reset(args: { orderId: string }): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  edit(
    args: { workOrderId: string } & WorkOrderEditableFields.Type
  ): Promise<void> {
    throw new Error("Method not implemented.");
  }
  confirm(args: {
    workOrderId: string;
    values: WorkOrderConfirmation.Type;

    files: ImageListType;
  }): Promise<TWorkOrderConfirmationResponse> {
    throw new Error("Method not implemented.");
  }
  async checkIn(params: CheckInOutParams): Promise<void> {
    const order = WorkOrderMockData.handymanOrder;
    order.checkIn = {
      date: new Date(),
    };
  }
  async checkOut(params: CheckInOutParams): Promise<void> {
    const order = WorkOrderMockData.handymanOrder;
    order.checkOut = {
      date: new Date(),
    };
  }
  checkInOut(params: CheckInOutParams, type: "in" | "out"): Promise<void> {
    if (type === "in") {
      return this.checkIn(params);
    } else {
      return this.checkOut(params);
    }
  }
  async reportChecklistAnswers(args: {
    workOrderId: string;
    answers: ChecklistAnswers.Type;
  }): Promise<void> {}
  async fetchChecklist(checklistId: string): Promise<Checklist.Type> {
    return WorkOrderMockData.checkList;
  }

  reportOrderFinished(args: WorkOrderFinishedReport.Type): Promise<void> {
    throw new Error("Method not implemented.");
  }
  searchForOrders(args: {
    handymanId: string;
    query: string;
  }): Promise<WorkOrder.HandymanWithPermissionsType[]> {
    throw new Error("Method not implemented.");
  }
  fetchExtraHour(args: {
    workOrderId: string;
  }): Promise<WorkOrderExtraHour.Type> {
    throw new Error("Method not implemented.");
  }
  fetchQuickAddProducts(args: {
    workOrderId: string;
  }): Promise<WorkOrderExtraHour.Type[]> {
    throw new Error("Method not implemented.");
  }
  searchForMaterials(args: {
    workOrderId: string;
    query: string;
  }): Promise<{ unitPrice?: number | undefined; name: string; id: string }[]> {
    throw new Error("Method not implemented.");
  }
  reportMaterial(values: WorkOrderLineItem.NewEntryType): Promise<void> {
    throw new Error("Method not implemented.");
  }
  reportExtraHour(values: {
    workOrderId: string;
    quantity: number;
  }): Promise<void> {
    throw new Error("Method not implemented.");
  }
  fetchReportedMaterials(args: {
    workOrderId: string;
  }): Promise<WorkOrderLineItem.Type[]> {
    throw new Error("Method not implemented.");
  }
  fetchReportedHours(args: {
    workOrderId: string;
  }): Promise<WorkOrderLineItem.Type[]> {
    throw new Error("Method not implemented.");
  }
  removeMaterial(args: {
    entryId: string;
    workOrderId: string;
  }): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  removeHour(args: { entryId: string; workOrderId: string }): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  async getFiles(args: {
    workOrderId: string;
  }): Promise<IpisFile.WithMetaType[]> {
    return [];
  }
  uploadFiles(args: {
    workOrderId: string;
    files: FullImageType[];
    uploadAs: UploaderSource.Type;
  }): Promise<void> {
    throw new Error("Method not implemented.");
  }
  deleteFile(image: IpisFile.ObjectType): Promise<void> {
    throw new Error("Method not implemented.");
  }

  public fetchServiceContractFiles = (args: { workOrderId: string }) => {
    throw new Error("Method not implemented.");
  };
  fetchRelatedFiles(args: {
    workOrderId: string;
  }): Promise<IpisFile.WithMetaType[]> {
    throw new Error("Method not implemented.");
  }
}
