import {
  Checklist,
  WorkOrder,
  WorkOrderExtraHour,
  WorkOrderLineItem,
} from "@eljouren/domain";
import { IpisFile } from "@eljouren/file-schemas/build";
import React from "react";
import { UseMutatableQueryResult } from "../../../hooks/use-mutatable-query";
import { UseQueryWrapperResult } from "../../../hooks/use-query-wrapper";

const HandymanWorkOrderRouteContext = React.createContext<{
  orderRes: UseMutatableQueryResult<WorkOrder.HandymanWithPermissionsType>;
  order: WorkOrder.HandymanWithPermissionsType;
  checklistRes: UseMutatableQueryResult<Checklist.Type>;
  serviceContractFilesRes: UseQueryWrapperResult<IpisFile.WithMetaType[]>;
  relatedFilesRes: UseQueryWrapperResult<IpisFile.WithMetaType[]>;
  reportedMaterialRes: UseMutatableQueryResult<WorkOrderLineItem.Type[]>;
  reportedHoursRes: UseMutatableQueryResult<WorkOrderLineItem.Type[]>;
  extraHourRes: UseQueryWrapperResult<WorkOrderExtraHour.Type | undefined>;
  quickAddProductsRes: UseQueryWrapperResult<WorkOrderExtraHour.Type[]>;
  allowQuickAddMaterial: boolean;
  allowSearchAndCustomMaterial: boolean;
}>({} as never);

export default HandymanWorkOrderRouteContext;
