"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderHousingType = void 0;
var zod_1 = require("zod");
var WorkOrderHousingType;
(function (WorkOrderHousingType) {
    WorkOrderHousingType.Schema = zod_1.z.discriminatedUnion("housingType", [
        zod_1.z.object({
            housingType: zod_1.z.literal("house"),
            propertyDesignation: zod_1.z.string().optional(),
        }),
        zod_1.z.object({
            housingType: zod_1.z.literal("condomonium"),
            housingCooperativeOrgNo: zod_1.z.string().optional(),
            apartmentNumber: zod_1.z.string().optional(),
        }),
        zod_1.z.object({
            housingType: zod_1.z.literal("rentalApartment"),
        }),
        zod_1.z.object({
            housingType: zod_1.z.literal("insuranceMatter"),
        }),
    ]);
})(WorkOrderHousingType || (WorkOrderHousingType = {}));
exports.WorkOrderHousingType = WorkOrderHousingType;
