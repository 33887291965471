"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderStatus = void 0;
var zod_1 = require("zod");
var WorkOrderStatus;
(function (WorkOrderStatus) {
    /*
    This is essentially the Api-schema and it might be worth converting
    */
    WorkOrderStatus.Schema = zod_1.z.enum([
        "Planerad",
        "Bokad",
        "Bekräftad",
        "Completed",
        "Canceled",
        "Klarmarkerad av hantverkare",
    ]);
})(WorkOrderStatus || (WorkOrderStatus = {}));
exports.WorkOrderStatus = WorkOrderStatus;
