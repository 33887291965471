import { FiArrowLeft } from "react-icons/fi";
import { useNavigate, useSearchParams } from "react-router-dom";
import __ from "../../../utils/utils";

interface Props {
  className?: string;
  label?: string;
  overrideLink?: string;
}

const HistoryBackButton = (props: Props) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const goBackTo = params.get("goBackTo");
  return (
    <button
      className={__.classNames(
        "group flex items-center gap-2 p-2",
        props.className
      )}
      onClick={(e) => {
        e.preventDefault();
        if (props.overrideLink) {
          navigate(props.overrideLink);
        } else if (goBackTo) {
          navigate(goBackTo);
        } else {
          navigate(-1);
        }
      }}
    >
      <FiArrowLeft size={30} className="scale-90 group-hover:scale-100" />
      {props.label}
    </button>
  );
};

export default HistoryBackButton;
