import { HandymanProfession } from "@eljouren/domain/build";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { z } from "zod";
import withSalesCredentials from "../../../components/auth-hocs/withSalesCredentials";
import { AppLoader } from "../../../components/common/loaders/AppLoader";
import PageSection from "../../../components/common/PageSection";
import PaginationControls from "../../../components/common/pagination/PaginationControls";
import { AppFormTextField } from "../../../components/common/text-fields/AppFormTextField";
import PageHeader from "../../../components/layout/PageHeader";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import { useRepos } from "../../../hooks/use-repos";
import AppRoutes from "../../../routes";
import { __tsx } from "../../../utils/tsxUtils";
import Paginator from "../../../_model/helpers/Paginator";
import { TSignedInWorkerState } from "../../../_model/repos/interfaces/IAuthRepo";
import { WorkerAssociationSchema } from "../../../_model/repos/interfaces/IHandymanRepo";
import SalesTeamFilterSelect from "./SalesTeamFilterSelect";
import SalesTeamPartnerListItem from "./SalesTeamPartnerListItem";

const ValidValues = z.enum(["el", "vvs", "spol"]);
const Transform = ValidValues.transform<HandymanProfession.Type>((value) => {
  switch (value) {
    case "el":
      return "electrician";
    case "spol":
      return "plumber-spolbil";
    case "vvs":
      return "plumber";
  }
});

interface Props extends TSignedInWorkerState {
  className?: string;
}

const SalesTeamPartnerListAssociationRoute = (props: Props) => {
  const { workerAssociation } = useParams();
  const navigate = useNavigate();

  const [params, setSearchParams] = useSearchParams();
  const areaFilter = params.get("omrade");
  const qualificationFilter = params.get("kompetens");
  const pageStr = params.get("sida");
  const pageIndex = pageStr ? Number.parseInt(pageStr) : 0;
  const form = useForm<{ filter: string }>({
    defaultValues: {
      filter: "",
    },
  });

  //const areaFilter = useBundledState<false | string>(false);
  const { handymanRepo } = useRepos();

  const partnerRes = useQuery(["partners", workerAssociation], async () => {
    const res = await handymanRepo.fetchByProfession(
      Transform.parse(workerAssociation)
    );
    // Sorting here should by done in the backend query - this is just a quick fix
    res.sort((a, b) =>
      `${a.firstName} ${a.lastName}`
        .toLowerCase()
        .localeCompare(`${b.firstName} ${b.lastName}`.toLowerCase())
    );
    return res;
  });

  function previousPage() {
    setSearchParams({
      ...Object.fromEntries(params.entries()),
      ...{
        sida: (pageIndex - 1).toString(),
      },
    });
  }

  function nextPage() {
    setSearchParams({
      ...Object.fromEntries(params.entries()),
      ...{
        sida: (pageIndex + 1).toString(),
      },
    });
  }

  const paginator = new Paginator({
    pageIndex: pageIndex,
    elementsPerPage: 5,
    totalCountOfElements: "countData",
    data: getFilteredData(),
  });

  function setAreaFilter(value: string) {
    setSearchParams({
      ...Object.fromEntries(params.entries()),
      ...{
        omrade: value,
        sida: "0",
      },
    });
  }
  function setQualificationFilter(value: string) {
    setSearchParams({
      ...Object.fromEntries(params.entries()),
      ...{
        kompetens: value,
        sida: "0",
      },
    });
  }

  function isFilteredByArea(): boolean {
    return !!areaFilter && areaFilter !== "default";
  }
  function isFilteredByQualification(): boolean {
    return !!qualificationFilter && qualificationFilter !== "default";
  }
  function isFiltered(): boolean {
    return (
      isFilteredByArea() ||
      isFilteredByQualification() ||
      !!form.watch("filter")
    );
  }

  useEffect(() => {
    if (!WorkerAssociationSchema.safeParse(workerAssociation).success) {
      navigate("/");
    }
  }, []);

  function getFilteredData() {
    if (!partnerRes.data) {
      return [];
    }
    const filtered = isFiltered();

    return partnerRes.data.filter((worker) => {
      if (!filtered) {
        return true;
      }
      let includesArea = true;
      let includesQualification = true;
      let matchesFreeText = true;
      if (isFilteredByArea() && areaFilter) {
        includesArea = worker.activeAreas.includes(areaFilter);
      }
      if (isFilteredByQualification() && qualificationFilter) {
        includesQualification =
          worker.qualifications.includes(qualificationFilter);
      }
      if (form.watch("filter").length > 0) {
        matchesFreeText = form
          .watch("filter")
          .split(" ")
          .every((str) => {
            const lc = str.toLowerCase();
            const {
              permittedToViewPrices,
              qualifications,
              activeAreas,
              ...rest
            } = worker;

            const matchesStrValues = [
              ...Object.values(rest),
              ...qualifications,
              ...activeAreas,
            ].some((el) => {
              if (typeof el === "string") {
                return el.toLowerCase().includes(lc);
              }
              return false;
            });

            return matchesStrValues;
          });
      }
      return includesArea && includesQualification && matchesFreeText;
    });
  }

  function getUniqueCities(): string[] {
    if (!partnerRes.data) {
      return [];
    }
    const set = new Set(
      partnerRes.data
        .filter((worker) => !!worker.city)
        .flatMap((worker) => worker.activeAreas)
    );
    return Array.from(set).sort();
  }
  function getUniqueQualifications(): string[] {
    if (!partnerRes.data) {
      return [];
    }
    const set = new Set(
      partnerRes.data
        .filter((worker) => !!worker.city)
        .flatMap((worker) => worker.qualifications)
    );
    return Array.from(set).sort();
  }

  function getHeading() {
    const res = WorkerAssociationSchema.safeParse(workerAssociation);
    if (!res.success) {
      return "";
    }
    switch (res.data) {
      case "el":
        return "Elektriker";
      case "vvs":
        return "VVS";
      case "spol":
        return "Spolbilar";
    }
  }

  return (
    <section className="mx-auto grid h-full w-full grid-cols-1 grid-rows-[auto,minmax(0,1fr)] gap-2 gap-x-4">
      <PageHeader
        overrideLink={AppRoutes.salesTeam.partnerList()}
        heading={getHeading()}
        className="md:col-span-3"
      />

      <main className="grid gap-2 p-2 md:grid-cols-3">
        <PageSection as="section" className="flex flex-col gap-2 md:col-span-2">
          {__tsx.renderOne({ res: partnerRes }, [
            ({ res }) => res.isLoading && <AppLoader />,
            ({ res }) =>
              res.isError && (
                <SomethingWentWrong
                  devError={res.error}
                  description="Det gick inte att hämta partners just nu. Vänligen försök igen senare."
                />
              ),
            ({ res }) => res.isLoading && <AppLoader />,
            ({ res }) =>
              !!res.data && (
                <>
                  <PaginationControls
                    className="ml-auto"
                    canGoBack={paginator.canGoBack}
                    canGoForward={paginator.canGoForward}
                    pageIndex={paginator.pageIndex}
                    maxPageIndex={paginator.limits.maxPageIndex}
                    onGoBack={previousPage}
                    onGoForward={nextPage}
                  />
                  <AppFormTextField
                    register={form.register}
                    name="filter"
                    htmlAttributes={{
                      placeholder: "Filtrera",
                    }}
                  />
                  <ul className="flex flex-col gap-2 overflow-auto pt-0">
                    {paginator.currentData.map((worker) => (
                      <SalesTeamPartnerListItem
                        key={worker.id}
                        handyman={worker}
                      />
                    ))}
                  </ul>
                </>
              ),
          ])}
        </PageSection>
        <PageSection
          as="aside"
          className="flex h-full grow flex-col gap-2 p-4 md:gap-4"
        >
          <SalesTeamFilterSelect
            className="md:col-start-2 md:row-span-2 md:row-start-1 md:p-0"
            id="citySelect"
            label="Område"
            values={getUniqueCities()}
            defaultValue={"default"}
            currentValue={areaFilter}
            onChange={setAreaFilter}
          />
          <SalesTeamFilterSelect
            className="md:col-start-2 md:row-start-3 md:p-0"
            id="qualificationSelect"
            label="Kompetens"
            values={getUniqueQualifications()}
            defaultValue={"default"}
            currentValue={qualificationFilter}
            onChange={setQualificationFilter}
          />
        </PageSection>
      </main>
    </section>
  );
};

export default withSalesCredentials(SalesTeamPartnerListAssociationRoute);
