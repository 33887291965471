import { IpisFile } from "@eljouren/file-schemas/build";
import __ from "../../../utils/utils";
import DateHelper from "../../../_model/helpers/DateHelper";
import IpisFileIcon from "../../files/IpisFileIcon";

interface Props {
  className?: string;
  file: IpisFile.WithMetaType;
}

const IpisFileListItem = (props: Props) => {
  const description = props.file.meta?.description;
  const date = props.file.meta?.createdDate;

  let translatedUploaderSource: string;
  switch (props.file.uploadedBy) {
    case "staff":
      translatedUploaderSource = "personal";
      break;
    case "worker":
      translatedUploaderSource = "hantverkare";
      break;
    case "customer":
      translatedUploaderSource = "kund";
      break;
  }

  return (
    <li className={__.classNames(props.className, "w-full overflow-hidden")}>
      <a
        className={__.classNames(
          "flex w-full flex-col gap-2 rounded border p-2 transition-all hover:shadow"
        )}
        href={props.file.src}
        target="_blank"
        rel="noreferrer"
      >
        <span className="grid grid-cols-[auto,minmax(0,1fr)] items-center gap-2">
          <IpisFileIcon size={25} mimeType={props.file.meta?.mimeType} />
          <span className="text-base md:text-lg">
            {props.file.meta?.customName ?? props.file.name}
          </span>
        </span>
        <span className="flex flex-col justify-between gap-2">
          {description && (
            <p className="mr-auto w-full overflow-hidden break-words rounded bg-main-bg-light/5 p-2 text-sm">
              {description}
            </p>
          )}
          <span
            className={__.classNames(
              "flex w-full items-center justify-between lg:ml-auto"
            )}
          >
            {date && (
              <span className={__.classNames("text-sm italic")}>
                {new DateHelper(date).dateInputFormat}
              </span>
            )}
            <span className="ml-auto text-xs md:text-sm">
              Av {translatedUploaderSource}
            </span>
          </span>
        </span>
      </a>
    </li>
  );
};

export default IpisFileListItem;
