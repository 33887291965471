import { WorkOrderExtraHour, WorkOrderLineItem } from "@eljouren/domain/build";
import { useForm } from "react-hook-form";
import { FiPlusCircle } from "react-icons/fi";
import __ from "../../../utils/utils";
import { UUID } from "../../../utils/UUID";
import AppTextButton from "../../common/buttons/AppTextButton";
import { AppFormTextField } from "../../common/text-fields/AppFormTextField";

export type QuickAddFormValues = {
  quantity: number;
};

export type QuickAddSubmitValues = {
  optimisticLineItem: WorkOrderLineItem.Type;
  values: QuickAddFormValues;
  material: WorkOrderExtraHour.Type;
};

interface Props {
  className?: string;
  material: WorkOrderExtraHour.Type;
  disabled?: boolean;
  defaultQuantity?: number;
  onSubmit: (args: QuickAddSubmitValues) => void;
  type: "material" | "hour";
  step: number;
  min: number;
  inputId: string;
}

const HandymanQuickAddMaterialForm = (props: Props) => {
  const form = useForm<QuickAddFormValues>({
    defaultValues: {
      quantity: props.defaultQuantity ?? 1,
    },
  });

  const unit = props.type === "material" ? "st" : "h";

  async function onSubmit(values: QuickAddFormValues) {
    form.reset();

    const material = props.material;

    const optimisticLineItem: WorkOrderLineItem.Type = {
      id: UUID.generate().value,
      productId: material.id,
      name: material.name,
      quantity: values.quantity,
      unitPrice: material.unitPrice,
      isCustom: false,
      addedByHandyman: true,
      unit,
      createdDate: new Date(),
    };

    props.onSubmit({ values, optimisticLineItem, material: props.material });
  }

  return (
    <form
      className={__.classNames(
        props.className,
        "grid grid-cols-[minmax(0,1fr),auto] gap-2 border-b pb-2"
      )}
    >
      <h3 className="col-span-2 text-base font-semibold xs:col-span-1 xs:text-lg">
        {props.material.name}
      </h3>
      <fieldset
        data-quick-add-type={props.type}
        className={__.classNames(
          "col-span-2 ml-auto flex items-center gap-2 xs:col-span-1 xs:col-start-2"
        )}
      >
        <AppFormTextField
          register={form.register}
          name="quantity"
          className="w-20"
          htmlAttributes={{
            type: "number",
            id: props.inputId,
            disabled: props.disabled,
            min: props.min,
            step: props.step,
          }}
        />

        <label htmlFor={props.inputId}>{unit}</label>
        <AppTextButton
          disabled={props.disabled}
          type="submit"
          onClick={form.handleSubmit(onSubmit)}
          aria-label={"Lägg till extratimmar"}
        >
          <FiPlusCircle size={35} />
        </AppTextButton>
      </fieldset>
    </form>
  );
};

export default HandymanQuickAddMaterialForm;
