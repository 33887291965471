import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import withSalesCredentials from "../../../components/auth-hocs/withSalesCredentials";
import PageHeader from "../../../components/layout/PageHeader";
import AppRoutes from "../../../routes";
import { TSignedInWorkerState } from "../../../_model/repos/interfaces/IAuthRepo";

interface Props extends TSignedInWorkerState {
  className?: string;
}

const SalesTeamPartnerListRoute = (props: Props) => {
  return (
    <section className="mx-auto h-full w-full bg-white">
      <PageHeader heading={"Partners"} />
      <main className="p-4 lg:p-6">
        <ul className="flex flex-col gap-2">
          <LinkListItem
            href={AppRoutes.salesTeam.partnerListWithAssociation("vvs")}
            label={"VVS"}
          ></LinkListItem>
          <LinkListItem
            href={AppRoutes.salesTeam.partnerListWithAssociation("spol")}
            label={"Spolbil"}
          ></LinkListItem>
          <LinkListItem
            href={AppRoutes.salesTeam.partnerListWithAssociation("el")}
            label={"El"}
          ></LinkListItem>
        </ul>
      </main>
    </section>
  );
};

const LinkListItem = (props: { href: string; label: string }) => {
  return (
    <li className="flex w-full cursor-pointer items-center justify-between rounded border bg-bg-base-layer transition-all  hover:bg-main-bg-light hover:text-white">
      <Link
        to={props.href}
        className="flex w-full items-center justify-between p-3"
      >
        <span className="text-lg font-semibold">{props.label}</span>
        <FaArrowRight size={20} />
      </Link>
    </li>
  );
};

export default withSalesCredentials(SalesTeamPartnerListRoute);
