"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var zod_1 = require("zod");
var WorkOrderFinishedReport;
(function (WorkOrderFinishedReport) {
    WorkOrderFinishedReport.Schema = zod_1.z.union([
        zod_1.z.object({
            workOrderId: zod_1.z.string(),
            rebooking: zod_1.z.literal("yes"),
            description: zod_1.z.string().min(1).max(255),
            timeEstimateInHours: zod_1.z.number().positive(),
            newDateBooked: zod_1.z.literal(true),
            date: zod_1.z.union([
                zod_1.z.string(),
                zod_1.z.date().transform(function (date) { return date.toISOString(); }),
            ]),
        }),
        zod_1.z.object({
            workOrderId: zod_1.z.string(),
            rebooking: zod_1.z.literal("yes"),
            description: zod_1.z.string().min(1).max(255),
            timeEstimateInHours: zod_1.z.number().positive(),
            newDateBooked: zod_1.z.literal(false),
            date: zod_1.z
                .union([zod_1.z.string(), zod_1.z.date().transform(function (date) { return date.toISOString(); })])
                .optional(),
        }),
        zod_1.z.object({
            workOrderId: zod_1.z.string(),
            rebooking: zod_1.z.literal("no"),
            date: zod_1.z
                .union([zod_1.z.string(), zod_1.z.date().transform(function (date) { return date.toISOString(); })])
                .optional(),
            description: zod_1.z.string().optional(),
            newDateBooked: zod_1.z.boolean().optional(),
        }),
    ]);
})(WorkOrderFinishedReport || (WorkOrderFinishedReport = {}));
exports.default = WorkOrderFinishedReport;
