import { motion } from "framer-motion";
import { useContext } from "react";
import __ from "../../../utils/utils";
import DateHelper from "../../../_model/helpers/DateHelper";
import { AppLoader } from "../../common/loaders/AppLoader";
import { CalendarContext } from "../Calendar";
import CalendarDayViewEventList from "./CalendarDayViewEventList";

interface Props {
  className?: string;
  hideWeekNumbers?: boolean;
  goBack(): void;
  selectedDay: DateHelper;
}

const CalendarDayView = (props: Props) => {
  const { isLoading } = useContext(CalendarContext);

  return (
    <motion.main
      className={__.classNames(
        "col-start-1 col-end-1 row-start-2 row-end-2 grid overflow-y-auto"
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: isLoading ? 0.5 : 1 }}
      exit={{ opacity: 0 }}
    >
      {isLoading && (
        <AppLoader className="absolute top-1/3 left-[calc(50%-1rem)] mx-auto h-8 w-8" />
      )}
      <section
        className={__.classNames(
          "grid h-full cursor-pointer grid-rows-[auto,minmax(0,1fr)] gap-2 p-1"
        )}
      >
        {/* Day of month */}
        <h3 className={__.classNames("text-2xl")}>
          {props.selectedDay.dayOfMonth} {props.selectedDay.monthLabel},{" "}
          {props.selectedDay.yearLabel}
        </h3>
        <CalendarDayViewEventList selectedDay={props.selectedDay} />
      </section>
    </motion.main>
  );
};

export default CalendarDayView;
