import React, { useContext } from "react";
import { TailwindBreakpoint, useMediaQuery } from "../../hooks/use-media-query";
import { GlobalContext } from "../../top-level-contexts";
import __ from "../../utils/utils";
import Logo from "../common/brand/Logo";
import AppNavLinks from "./AppNavLinks";
import AppSidebar from "./AppSidebar";

interface Props {
  className?: string;
}

export const AppHeader: React.FC<Props> = (props) => {
  const isLg = useMediaQuery(TailwindBreakpoint.lg, "AppHeader, isLg");
  const auth = useContext(GlobalContext);
  return (
    <>
      {isLg && auth.signInState.isSignedIn && (
        <header
          className={__.classNames(
            "col-start-2 row-start-1 hidden h-full w-full items-center gap-8 bg-gradient-to-br from-main-bg-light via-main-bg-light to-main-bg-dark lg:flex lg:w-72 lg:flex-col lg:py-16",
            props.className
          )}
        >
          <nav className="w-full grow">
            <AppNavLinks />
          </nav>
          <h1 className="flex w-full max-w-[250px]">
            <Logo wrapperClassName="" />
          </h1>
        </header>
      )}
      {!isLg && <AppSidebar isLg={isLg} />}
    </>
  );
};

export default AppHeader;
