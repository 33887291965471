import __ from "../../utils/utils";

interface Props {
  id: string;
  className?: string;
  label: string;
  text: string;
}

const LabelAndText = (props: Props) => {
  return (
    <div className={__.classNames("flex flex-col", props.className)}>
      <label className="text-sm" htmlFor={props.id}>
        {props.label}
      </label>
      <pre
        className="whitespace-pre-wrap rounded bg-bg-base-layer p-2"
        id={props.id}
      >
        {props.text.length === 0 ? "-" : props.text}
      </pre>
    </div>
  );
};

export default LabelAndText;
