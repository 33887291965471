import { zodResolver } from "@hookform/resolvers/zod";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useBundledState, useLoading } from "../../hooks/hooks";
import { useRepos } from "../../hooks/use-repos";
import __ from "../../utils/utils";
import { AppButton } from "../common/buttons/AppButton";
import AppTabs from "../common/tabs/AppTabs";
import { AppFormTextField } from "../common/text-fields/AppFormTextField";

enum Page {
  signIn = "Logga in",
  reset = "Återställ",
}

const SignInFormSchema = z.object({
  usernameOrEmail: z.string().min(1),
  password: z.string().min(1),
});

type SignInFormData = z.infer<typeof SignInFormSchema>;

const ResetFormSchema = z.object({
  email: z.string().min(1),
});

type ResetFormData = z.infer<typeof ResetFormSchema>;

interface Props {
  className?: string;
}

const SignInForm = (props: Props) => {
  const page = useBundledState(Page.signIn);
  const currentEmailRef = useRef<string | undefined>(undefined);

  return (
    /* Consider using another element */
    <div
      className={__.classNames(
        "grid h-full w-full grid-rows-[auto,minmax(0,1fr)] gap-4 p-2 text-white lg:gap-8 lg:bg-main-bg-light",
        props.className
      )}
    >
      <aside className="pt-8 lg:pt-16">
        <img
          alt="Logga för Aii"
          src="/logos/aii-logo.png"
          className="mx-auto mb-8 w-36 max-w-full"
        />
      </aside>

      <AppTabs
        selectedTab={page.value}
        withoutControls={true}
        className="h-full"
        mainClassName="h-full"
      >
        {page.value === Page.signIn && (
          <SignInSection
            {...props}
            goToResetPage={(currentEmail: string | undefined) => {
              currentEmailRef.current = currentEmail;
              page.set(Page.reset);
            }}
            defaultEmail={currentEmailRef.current}
          />
        )}
        {page.value === Page.reset && (
          <ResetSection
            {...props}
            goToSignInPage={() => page.set(Page.signIn)}
            defaultValue={currentEmailRef.current}
          />
        )}
      </AppTabs>
    </div>
  );
};

interface SignInProps extends Props {
  goToResetPage(currentEmail: string): void;
  defaultEmail: string | undefined;
}

const SignInSection = (props: SignInProps) => {
  const { register, handleSubmit, getValues } = useForm<SignInFormData>({
    mode: "onChange",
    resolver: zodResolver(SignInFormSchema),
    defaultValues: {
      usernameOrEmail: props.defaultEmail,
    },
  });
  const { isLoading, loadWhilePromise } = useLoading();
  const { authRepo } = useRepos();
  const showWrongCredentials = useBundledState(false);
  const onSubmit = async () => {
    showWrongCredentials.set(false);
    const { usernameOrEmail, password } = getValues();
    try {
      const res = await loadWhilePromise(
        authRepo.signInWithCredentials(usernameOrEmail, password)
      );

      if (!res) {
        showWrongCredentials.set(true);
      }
    } catch (er) {
      window.modal.alert({
        title: "Okänt fel",
        prompt: "Det gick inte att logga in just nu",
        typeOfAlert: "error",
      });
    }
  };

  return (
    <SharedSection className={props.className}>
      <form className="flex h-full w-full flex-col gap-6">
        <header className="mx-auto flex w-[300px] max-w-full flex-col">
          <h2 className="text-3xl tracking-wide">Välkommen till Aii</h2>
          <h3 className="text-base text-white/90">
            Ange dina uppgifter för att logga in
          </h3>
        </header>
        <main className="flex w-[300px] max-w-full flex-col gap-4">
          <AppFormTextField
            register={register}
            className="col-span-2"
            label="E-post"
            name="usernameOrEmail"
            htmlAttributes={{
              type: "email",
              disabled: isLoading,
            }}
          />
          <AppFormTextField
            register={register}
            className={"col-span-2"}
            name="password"
            label="Lösenord"
            htmlAttributes={{
              type: "password",
              disabled: isLoading,
            }}
          />
          {showWrongCredentials.value && (
            <span className="bold ml-auto text-red-600">
              Fel inloggningsuppgifter
            </span>
          )}
          <AppButton
            className="col-start-2 p-0 text-sm"
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
            //disabled={!formState.isValid}
            colorSchema="brandLightBlue"
          >
            Logga in
          </AppButton>
        </main>
      </form>
      <footer className="mt-auto flex justify-center">
        <button
          className="text-white hover:underline"
          onClick={() => {
            props.goToResetPage(getValues("usernameOrEmail"));
          }}
        >
          Glömt lösenord?
        </button>
      </footer>
    </SharedSection>
  );
};

interface ResetProps extends Props {
  goToSignInPage(): void;
  defaultValue: string | undefined;
}

const ResetSection = (props: ResetProps) => {
  const { register, handleSubmit } = useForm<ResetFormData>({
    mode: "onChange",
    resolver: zodResolver(ResetFormSchema),
    defaultValues: {
      email: props.defaultValue,
    },
  });
  const { isLoading, loadWhilePromise } = useLoading();
  const { authRepo } = useRepos();
  const showText = useBundledState<"success" | "failure" | false>(false);
  const onSubmit = async (values: ResetFormData) => {
    try {
      await loadWhilePromise(
        authRepo.invokeResetPasswordFlow({ email: values.email })
      );
      showText.set("success");
    } catch (er) {
      showText.set("failure");
    }
  };

  return (
    <SharedSection className={props.className}>
      <form className="flex h-full w-full flex-col gap-6">
        <header className="mx-auto flex w-[300px] max-w-full flex-col">
          <h2 className="text-3xl tracking-wide">Glömt lösenord</h2>
        </header>
        <main className="flex w-[300px] max-w-full flex-col gap-4">
          <AppFormTextField
            register={register}
            className="col-span-2"
            label="E-post"
            name="email"
            htmlAttributes={{
              type: "email",
              disabled: isLoading,
            }}
          />

          <AppButton
            className="col-start-2 p-0 text-sm"
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
            //disabled={!formState.isValid}
            colorSchema="brandLightBlue"
          >
            Skicka återställningslänk
          </AppButton>
          {showText.value === "success" && (
            <span>Vänligen kolla din e-post!</span>
          )}
          {showText.value === "failure" && (
            <span className="text-red-500">
              Det gick inte att återställa lösenordet just nu. Vänligen försök
              igen senare!
            </span>
          )}
        </main>
      </form>
      <footer className="mt-auto flex justify-center">
        <button
          className="text-white hover:underline"
          onClick={props.goToSignInPage}
        >
          Logga in istället
        </button>
      </footer>
    </SharedSection>
  );
};

interface SharedSectionElementProps {
  className?: string;
  children?: React.ReactNode;
}

const SharedSection = (props: SharedSectionElementProps) => {
  return (
    <section
      className={__.classNames(
        "z-10 grid h-full w-full grid-rows-[minmax(350px,1fr),auto] items-center justify-center gap-y-6 overflow-auto rounded p-2 pb-8 text-white lg:bg-main-bg-light lg:pb-16",
        props.className
      )}
    >
      {props.children}
    </section>
  );
};

export default SignInForm;
