import { motion } from "framer-motion";
import { useContext, useEffect } from "react";
import {
  TUseBundledStateReturn,
  TUseImmutableReturn,
  useImmutable,
} from "../../hooks/hooks";
import { useBundledSs } from "../../hooks/session-storage-hooks";
import __ from "../../utils/utils";
import CalendarEvent from "../../_model/helpers/calendar/CalendarEvent";
import DateHelper from "../../_model/helpers/DateHelper";
import { AppLoader } from "../common/loaders/AppLoader";
import { CalendarContext } from "./Calendar";

interface Props {
  className?: string;
}

const CalendarListView = (props: Props) => {
  const customFiltersById = useImmutable<Record<string, boolean | string>>(
    getSavedCustomFilters()
  );
  const showDaysWithEvents = useBundledSs("showDaysWithEvents", false, {
    decode: (str) => (str === "true" ? true : false),
    encode: (bool) => (bool ? "true" : "false"),
  });
  const { listViewFilter, isLoading } = useContext(CalendarContext);

  function getSavedCustomFilters() {
    const saved = sessionStorage.getItem("customListViewFilters");
    if (saved) {
      return JSON.parse(saved);
    } else {
      return {};
    }
  }

  useEffect(() => {
    function saveCustomFilter() {
      sessionStorage.setItem(
        "customListViewFilters",
        JSON.stringify(customFiltersById.dict)
      );
    }
    saveCustomFilter();
  }, [customFiltersById.dict]);

  const Events = (props: {
    showDaysWithEvents: TUseBundledStateReturn<boolean>;
    customFiltersById: TUseImmutableReturn<Record<string, boolean | string>>;
  }) => {
    const { calendarHelper, RenderListViewItem, listViewFilter } =
      useContext(CalendarContext);
    let mostRecentMonth: number | undefined;

    const applyCustomFilters = (events: CalendarEvent[]) => {
      if (listViewFilter) {
        let filteredEvents = [...events];
        props.customFiltersById.entries.forEach(([key, val]) => {
          if (val) {
            const customFilter = listViewFilter?.find(
              (filter) => filter.id === key
            );
            if (customFilter) {
              filteredEvents = filteredEvents.filter((event) => {
                if (customFilter.type === "checkbox") {
                  return customFilter.filter(event);
                }

                if (customFilter.type === "text") {
                  return customFilter.filter(event, val.toString());
                }

                return true;
              });
            }
          }
        });
        return filteredEvents;
      }

      return events;
    };

    let daysToShow = calendarHelper.calendarDays.filter((dayHelper) => {
      const filteredEvents = applyCustomFilters(
        calendarHelper.events.for(dayHelper.date)
      );
      return showDaysWithEvents.value || filteredEvents.length;
    });

    return (
      <>
        {!showDaysWithEvents.value && !daysToShow.length && (
          <p className="p-2">
            Det finns inga dagar med evenemang under detta tidsspan
          </p>
        )}
        {daysToShow.map((dayHelper) => {
          const changeOfMonth = mostRecentMonth !== dayHelper.month;
          mostRecentMonth = dayHelper.month;

          const label = changeOfMonth
            ? `${dayHelper.dayOfMonth} ${dayHelper.monthLabel}`
            : `${dayHelper.dayOfMonth}`;

          return (
            <li
              className="flex flex-col justify-between gap-2 p-2"
              key={dayHelper.date.getTime()}
            >
              <header className="col-span-1">
                <span
                  className={__.classNames(
                    "text-sm",
                    changeOfMonth && "font-bold"
                  )}
                >
                  {label}
                </span>
              </header>
              <main className="col-span-3">
                <ul className="flex w-full flex-col gap-2">
                  {applyCustomFilters(
                    calendarHelper.events.for(dayHelper.date)
                  ).map((event) => {
                    if (RenderListViewItem) {
                      return (
                        <RenderListViewItem
                          key={event.props.id}
                          event={event}
                        />
                      );
                    }

                    const startHelper = new DateHelper(event.props.start);
                    const endHelper = new DateHelper(event.props.end);

                    return (
                      <li
                        key={event.props.id}
                        className="flex w-full cursor-pointer flex-col rounded border-2 border-orange-200 p-2"
                        onClick={event.props.onClick}
                      >
                        <header>
                          <h4 className="text-base">{event.props.name}</h4>
                          <span
                            className={__.classNames(
                              "text-sm italic",
                              event.hasPassed && "text-red-600"
                            )}
                          >{`${startHelper.hourAndMinutesLabel} - ${endHelper.hourAndMinutesLabel}`}</span>
                        </header>

                        <main>
                          <p className="text-sm">{event.props.description}</p>
                        </main>
                      </li>
                    );
                  })}
                </ul>
              </main>
            </li>
          );
        })}
      </>
    );
  };

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: isLoading ? 0.5 : 1 }}
      exit={{ opacity: 0 }}
      className={__.classNames(
        "col-start-1 col-end-1 row-start-2 row-end-2 flex h-full max-h-[800px] flex-col",
        isLoading && "pointer-events-none",
        props.className
      )}
    >
      {isLoading && (
        <AppLoader className="absolute top-1/3 left-[calc(50%-1rem)] mx-auto h-8 w-8" />
      )}
      <header className="flex w-full flex-col">
        <p className="flex gap-3 p-2">
          <input
            className="cursor-pointer"
            type="checkbox"
            id="showDaysWithoutEvents"
            checked={showDaysWithEvents.value}
            onChange={(e) => showDaysWithEvents.set(e.target.checked)}
          />
          <label
            htmlFor="showDaysWithoutEvents"
            className="cursor-pointer text-sm"
          >
            Visa dagar utan arbetsordrar
          </label>
        </p>
        <>
          {listViewFilter &&
            listViewFilter.map((filter) => {
              if (filter.type === "checkbox") {
                return (
                  <p className="flex gap-3 p-2" key={filter.id}>
                    <input
                      className="cursor-pointer"
                      type="checkbox"
                      id={filter.id}
                      checked={!!customFiltersById.dict[filter.id]}
                      onChange={(e) =>
                        customFiltersById.modify(
                          (dict) => (dict[filter.id] = e.target.checked)
                        )
                      }
                    />
                    <label
                      htmlFor={filter.id}
                      className="cursor-pointer text-sm"
                    >
                      {filter.label}
                    </label>
                  </p>
                );
              }

              if (filter.type === "text") {
                return (
                  <p className="flex flex-col p-2" key={filter.id}>
                    <label
                      htmlFor={filter.id}
                      className="cursor-pointer text-base"
                    >
                      {filter.label}
                    </label>
                    <input
                      type="text"
                      id={filter.id}
                      className="rounded border p-2"
                      onChange={(e) =>
                        customFiltersById.modify(
                          (dict) => (dict[filter.id] = e.target.value)
                        )
                      }
                    />
                  </p>
                );
              }

              return <></>;
            })}
        </>
      </header>
      <main>
        <ul>
          <Events
            showDaysWithEvents={showDaysWithEvents}
            customFiltersById={customFiltersById}
          />
        </ul>
      </main>
    </motion.main>
  );
};

export default CalendarListView;
