"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileMetaInput = void 0;
var zod_1 = require("zod");
var FileMetaInput;
(function (FileMetaInput) {
    FileMetaInput.Schema = zod_1.z.object({
        customName: zod_1.z.string().optional(),
        description: zod_1.z.string().optional(),
    });
})(FileMetaInput || (FileMetaInput = {}));
exports.FileMetaInput = FileMetaInput;
