import { UseFormReturn } from "react-hook-form";

interface Props {
  className?: string;
  form: UseFormReturn<any>;
}

const GDPRLinkAndConfirmationCheckbox = (props: Props) => {
  return (
    <>
      <a
        href="https://svenskaeljouren.se/allm%C3%A4nna-villkor"
        className="col-span-2 text-base italic text-blue-700"
        target={"_blank"}
        rel="noreferrer"
      >
        Länk till Eljouren 247 AB:s allmänna villkor och integritetspolicy.
      </a>
      <p className="col-span-2 flex gap-3 rounded border border-transparent p-2 py-3 focus-within:border-main-bg-dark">
        <label htmlFor="confirmCheckbox" className="cursor-pointer">
          Jag godkänner Eljouren 247 AB:s allmänna villkor och accepterar att de
          lagrar och bearbetar mina uppgifter.
        </label>
        <input
          type="checkbox"
          id="confirmCheckbox"
          {...props.form.register("gdprConfirmed")}
          className="m-auto h-10 w-10 cursor-pointer"
          required
        />
      </p>
    </>
  );
};

export default GDPRLinkAndConfirmationCheckbox;
