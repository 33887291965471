import {
  createTRPCProxyClient,
  httpBatchLink,
  HTTPHeaders,
} from "@trpc/client";
import { TDomainAppRouter } from "../../server/src/routes/domain/DomainAppRouter";
import { API } from "./_model/api/API";

const trpcClient = createTRPCProxyClient<TDomainAppRouter>({
  links: [
    httpBatchLink({
      url: process.env.REACT_APP_PROXY_URL + "/trpc",
      headers: () => {
        const header: HTTPHeaders | undefined = API.getOptionalAuthHeader();
        return header ?? {};
      },
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: "include",
        });
      },
      /* headers: () => {
        const header: HTTPHeaders | undefined = API.getOptionalAuthHeader();
        return header ?? {};
      }, */
    }),
  ],
});

export default trpcClient;
