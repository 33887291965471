import { IpisFile } from "@eljouren/file-schemas/build";
import isEqual from "lodash.isequal";
import { createContext, useContext } from "react";
import { z } from "zod";
import useMutatableQuery, {
  UseMutatableQueryResult,
} from "../../hooks/use-mutatable-query";
import { useRepos } from "../../hooks/use-repos";
import { GlobalContext } from "../../top-level-contexts";
import { FullImageType } from "../customer-order/WorkOrderImageUpload";
export const WorkOrderFileContext = createContext<{
  fileRes: UseMutatableQueryResult<IpisFile.WithMetaType[]>;
  uploadFileMutation: (files: FullImageType[]) => Promise<void>;
  deleteFileMutation: (file: IpisFile.ObjectType) => Promise<void>;
  downloadFile: (file: IpisFile.WithMetaType) => Promise<void>;
}>({} as never);

interface Props {
  children?: React.ReactNode;
  orderId: string;
}

const WorkOrderFileContextProvider = (props: Props) => {
  const { signInState } = useContext(GlobalContext);
  const { workOrderRepo } = useRepos();
  const fileRes = useMutatableQuery({
    queryKey: ["workOrderFiles", props.orderId, signInState.signedInAs],
    queryFn: () => workOrderRepo.getFiles({ workOrderId: props.orderId }),
  });

  const uploadFileMutation = async (files: FullImageType[]) => {
    return fileRes.mutate(() =>
      workOrderRepo.uploadFiles({ workOrderId: props.orderId, files })
    );
  };

  const deleteFileMutation = async (obj: IpisFile.ObjectType) => {
    const ComparisonSchema = z.object({
      recordId: z.string(),
      name: z.string(),
      uploadedBy: z.string(),
    });
    return fileRes.mutate(() => workOrderRepo.deleteFile(obj), {
      optimisticUpdate: fileRes.query.data?.filter((el) => {
        try {
          const a = ComparisonSchema.parse(el);
          const b = ComparisonSchema.parse(obj);
          return !isEqual(a, b);
        } catch (er) {
          return true;
        }
      }),
    });
  };

  const downloadFile = async (obj: IpisFile.WithMetaType) => {
    try {
      const file = await fetch(obj.src);
      const blob = await file.blob();
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      const name = obj.meta?.customName || obj.guid;
      a.download = name + obj.ext;
      a.click();

      document.body.removeChild(a);
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att ladda ner filen just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
      });
    }
  };

  return (
    <WorkOrderFileContext.Provider
      value={{
        fileRes,
        uploadFileMutation,
        deleteFileMutation,
        downloadFile,
      }}
    >
      {props.children}
    </WorkOrderFileContext.Provider>
  );
};

export default WorkOrderFileContextProvider;
