import { WorkOrder } from "@eljouren/domain";
import { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AppLoader } from "../../../components/common/loaders/AppLoader";
import WorkOrderImages from "../../../components/customer-order/WorkOrderImages";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import __ from "../../../utils/utils";

import WorkOrderFileContextProvider from "../../../components/order-image-wrapper/WorkOrderFileContextProvider";
import { SalesOrderRouteContext } from "./SalesTeamOrderRoute";

interface Props {
  className?: string;
  order: WorkOrder.CustomerType;
}

const SalesTeamOrderPage = (props: Props) => {
  const { orderRes } = useContext(SalesOrderRouteContext);

  return (
    <section
      className={__.classNames(
        "mx-auto flex h-full w-full flex-col gap-2 overflow-auto px-2 pt-1",
        props.className
      )}
    >
      <header className="grid grid-cols-4 border-b-2 px-2 py-4 pb-1">
        <h3 className="col-span-3 text-base">{props.order.description}</h3>
        <h4 className="col-span-3 text-sm font-normal italic">
          #{props.order.serialNumber}
        </h4>
        {props.order.isFinished && (
          <h4 className="col-span-3 text-sm text-green-600">Klarmarkerad</h4>
        )}
        {orderRes.isLoading && (
          <AppLoader className="col-start-4 row-span-2 row-start-2 my-auto ml-auto h-6 w-6 p-2" />
        )}
      </header>
      <ErrorBoundary
        fallbackRender={(props) => (
          <SomethingWentWrong
            devError={props.error}
            onTryAgain={() => orderRes.refetch()}
            tryAgainLabel="Hämta på nytt"
            description="Det gick inte att hämta detta uppdrag just nu."
          />
        )}
      >
        <main className="grow overflow-auto">
          <WorkOrderFileContextProvider orderId={props.order.orderId}>
            <WorkOrderImages
              uploadLabel="Ladda upp"
              orderId={props.order.orderId}
              className="h-full"
              allowDeleting={true}
              allowUploading={true}
            />
          </WorkOrderFileContextProvider>
        </main>
      </ErrorBoundary>
    </section>
  );
};

export default SalesTeamOrderPage;
