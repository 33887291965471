"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderInvoice = void 0;
var zod_1 = require("zod");
var WorkOrderInvoice;
(function (WorkOrderInvoice) {
    WorkOrderInvoice.Schema = zod_1.z.discriminatedUnion("invoiceMethod", [
        zod_1.z.object({
            invoiceMethod: zod_1.z.literal("email"),
            email: zod_1.z.string().min(1),
            additionalInformation: zod_1.z.string().optional(),
        }),
        zod_1.z.object({
            invoiceMethod: zod_1.z.literal("postal"),
            //street: z.string().min(1),
            //postalCode: z.string().min(1),
            //city: z.string().min(1),
            additionalInformation: zod_1.z.string().optional(),
        }),
    ]);
})(WorkOrderInvoice || (WorkOrderInvoice = {}));
exports.WorkOrderInvoice = WorkOrderInvoice;
