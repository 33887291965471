"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderLocation = void 0;
var zod_1 = require("zod");
var WorkOrderLocation;
(function (WorkOrderLocation) {
    WorkOrderLocation.Schema = zod_1.z.object({
        city: zod_1.z.string(),
        street: zod_1.z.string(),
        country: zod_1.z.string(),
        postalCode: zod_1.z.string(),
        doorCode: zod_1.z.string().optional(),
        floor: zod_1.z.string().optional(),
    });
})(WorkOrderLocation || (WorkOrderLocation = {}));
exports.WorkOrderLocation = WorkOrderLocation;
