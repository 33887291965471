import { WorkOrderLineItem, WorkOrderProduct } from "@eljouren/domain";
import { zodResolver } from "@hookform/resolvers/zod";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FiPlusCircle, FiTrash } from "react-icons/fi";
import { useBundledState } from "../../hooks/hooks";
import { useRepos } from "../../hooks/use-repos";
import __ from "../../utils/utils";
import WorkOrderReimbursementCalculator from "../../_model/helpers/WorkOrderReimbursementCalculator";

import { z } from "zod";
import HandymanWorkOrderRouteContext from "../../routes/worker/order/HandymanWorkOrderRouteContext";
import { UUID } from "../../utils/UUID";
import { SignedInContext } from "../auth-hocs/withWorkerCredentials";
import { AppButton } from "../common/buttons/AppButton";
import AppTextButton from "../common/buttons/AppTextButton";
import { AppLoader } from "../common/loaders/AppLoader";
import SearchFieldSlideDown from "../common/search/SearchFieldSlideDown";
import { AppFormTextField } from "../common/text-fields/AppFormTextField";
import HandymanQuickAddMaterialForm, {
  QuickAddSubmitValues,
} from "./handyman/HandymanQuickAddMaterialForm";

enum Page {
  freeChoice,
  quickAdd,
  none,
}

const FormSchema = z.object({
  productId: z.string().optional(),
  quantity: z.number(),
  workOrderId: z.string(),
  subject: z.string(),
  unitPrice: z.number(),
});

type FormType = z.infer<typeof FormSchema>;

interface Props {
  className?: string;
}

const CustomerOrderExtraMaterialSection = (props: Props) => {
  const { workOrderRepo } = useRepos();
  const {
    order,
    relatedFilesRes,
    reportedMaterialRes,
    quickAddProductsRes,
    allowQuickAddMaterial,
    allowSearchAndCustomMaterial,
  } = useContext(HandymanWorkOrderRouteContext);
  const productTakenFromList = useBundledState(false);

  const contract = order.serviceContract;

  const [page, setPage] = useState(getDefaultPage());

  const form = useForm<FormType>({
    defaultValues: {
      quantity: 1,
      workOrderId: order.orderId,
    },
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => {
    form.setValue("workOrderId", order.orderId);
  }, [order, form]);

  useEffect(() => {
    if (allowSearchAndCustomMaterial && allowQuickAddMaterial) {
      if (quickAddProductsRes.data?.length) {
        setPage(Page.quickAdd);
      }
    }
  }, [
    quickAddProductsRes.isLoading,
    quickAddProductsRes.data,
    allowQuickAddMaterial,
    allowSearchAndCustomMaterial,
  ]);

  /* 
    ToDo: Make control flow make more sense when I'm not as tired as I am now
  */
  function getDefaultPage(): Page {
    if (!order.serviceContract?.allowedMaterial) {
      return Page.none;
    }
    if (allowQuickAddMaterial) {
      if (quickAddProductsRes.data?.length || !allowSearchAndCustomMaterial) {
        return Page.quickAdd;
      } else {
        return Page.freeChoice;
      }
    }
    return Page.freeChoice;
  }

  function onProductClick(product: WorkOrderProduct.Type) {
    form.setValue("subject", product.name);
    form.setValue("productId", product.id);
    form.setValue("unitPrice", product.unitPrice || 0);
    form.setValue("quantity", 1);
    productTakenFromList.set(true);
  }

  async function onSubmit(values: FormType) {
    if (!reportedMaterialRes.query.data) {
      return;
    }
    onReset();

    let entry: WorkOrderLineItem.NewEntryType;
    const materialBase: Omit<WorkOrderLineItem.Type, "isCustom" | "productId"> =
      {
        id: UUID.generate().value,
        name: values.subject,
        quantity: values.quantity,
        unitPrice: values.unitPrice,
        addedByHandyman: true,
        unit: "st",
        createdDate: new Date(),
      };
    const newData = [...reportedMaterialRes.query.data];

    /*
    ToDo: Clean up
    */
    if (productTakenFromList.value && values.productId) {
      entry = {
        productId: values.productId,
        quantity: values.quantity,
        workOrderId: values.workOrderId,
      };
      const prevProduct = newData.find(
        (el) => el.productId === values.productId
      );
      if (prevProduct) {
        prevProduct.quantity += values.quantity;
      } else {
        newData.push({
          productId: values.productId,
          isCustom: false,
          ...materialBase,
        });
      }
    } else {
      entry = {
        subject: values.subject,
        unitPrice: values.unitPrice,
        quantity: values.quantity,
        workOrderId: values.workOrderId,
      };
      newData.push({
        isCustom: true,
        ...materialBase,
      });
    }

    try {
      await reportedMaterialRes.mutate(
        () => workOrderRepo.reportMaterial(entry),
        { optimisticUpdate: newData }
      );
      /*
          (Previously: It would be better if this happens automatically when material are added, but the related files
          are in a higher context than the materials.)

          ToDo: 
          * These are now the same context, which means we could make this happen automatically.
      */
      relatedFilesRes.refetch();
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att lägga till material just nu.",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
      });
    }
  }

  function onError(errors: any) {
    //console.log({ errors });
  }
  function onReset() {
    form.reset();
    productTakenFromList.set(false);
  }

  async function onQuickAddProduct(args: QuickAddSubmitValues) {
    if (!reportedMaterialRes.query.data) {
      return;
    }

    const newData = [...reportedMaterialRes.query.data];

    const prevProduct = newData.find((el) => el.productId === args.material.id);
    if (prevProduct) {
      prevProduct.quantity += args.values.quantity;
    } else {
      newData.push(args.optimisticLineItem);
    }

    const entry: WorkOrderLineItem.NewEntryType = {
      productId: args.material.id,
      quantity: args.values.quantity,
      workOrderId: order.orderId,
    };

    try {
      await reportedMaterialRes.mutate(
        () => workOrderRepo.reportMaterial(entry),
        { optimisticUpdate: newData }
      );
      /*
            (Previously: It would be better if this happens automatically when material are added, but the related files
            are in a higher context than the materials.)
  
            ToDo: 
            * These are now the same context, which means we could make this happen automatically.
        */
      relatedFilesRes.refetch();
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att lägga till material just nu.",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
      });
    }
  }

  async function onTrash(entry: WorkOrderLineItem.Type) {
    const optimisticUpdate = reportedMaterialRes.query.data
      ? reportedMaterialRes.query.data.filter((el) => el.id !== entry.id)
      : undefined;

    try {
      await reportedMaterialRes.mutate(
        () =>
          workOrderRepo.removeMaterial({
            entryId: entry.id,
            workOrderId: order.orderId,
          }),
        {
          optimisticUpdate,
        }
      );
      /*
          It would be better if this happens automatically when material are added, but the related files
          are in a higher context than the materials. 
      */
      relatedFilesRes.refetch();
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att ta bort material just nu.",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
      });
    }
  }

  const disabled = order.isFinished;

  return (
    <section className={__.classNames("flex flex-col gap-2 ", props.className)}>
      <header className="grid items-center xs:grid-cols-[minmax(0,1fr),auto]">
        <h3 className="text-xl">Material</h3>
        {page === Page.quickAdd && allowSearchAndCustomMaterial && (
          <AppButton
            id="extraMaterialToggleButton"
            buttonMode="outline"
            onClick={() => {
              setPage(Page.freeChoice);
            }}
          >
            Välj fritt bland material
          </AppButton>
        )}
        {page === Page.freeChoice &&
          allowQuickAddMaterial &&
          !!quickAddProductsRes.data?.length && (
            <AppButton
              id="extraMaterialToggleButton"
              buttonMode="outline"
              onClick={() => {
                setPage(Page.quickAdd);
              }}
            >
              Visa lista över snabbval
            </AppButton>
          )}
      </header>
      <main className="flex flex-col">
        {page === Page.quickAdd && (
          <section
            className="rounded-t-lg bg-bg-base-layer p-2 md:p-4"
            id="materialQuickAddSection"
          >
            {/* ToDo: Show loading indicator for quickadd */}
            <main className="flex flex-col gap-2">
              {quickAddProductsRes.isLoading && <AppLoader />}
              {quickAddProductsRes.isError && (
                <p className="p-2 text-red-600">
                  Det gick inte att hämta snabbvalsprodukter just nu
                </p>
              )}
              {quickAddProductsRes.data?.map((material) => {
                return (
                  <HandymanQuickAddMaterialForm
                    key={material.id + "quickAddForm"}
                    disabled={disabled || reportedMaterialRes.isLoading}
                    onSubmit={onQuickAddProduct}
                    type="material"
                    material={material}
                    defaultQuantity={1}
                    step={1}
                    min={1}
                    inputId={material.id + "QuantityInput"}
                  />
                );
              })}
            </main>
          </section>
        )}
        {page === Page.freeChoice && !order.isFinished && (
          <section
            className="flex flex-col gap-2"
            id="materialFreeChoiceSection"
          >
            <SearchFieldSlideDown
              disabled={disabled}
              placeholder="Sök på material"
              fetch={async (query: string) =>
                workOrderRepo.searchForMaterials({
                  query,
                  workOrderId: order.orderId,
                })
              }
              id="materialSearchField"
              Render={(props) => {
                if (props.data && props.data.length) {
                  return (
                    <ul className="flex flex-col gap-1">
                      {props.data.map((product) => {
                        return (
                          <li
                            className="flex cursor-pointer flex-col rounded border-2 border-transparent p-1 hover:border-black"
                            key={product.id}
                            onClick={() => {
                              props.close();
                              onProductClick(product);
                            }}
                          >
                            <span className="text-sm font-bold">
                              {product.name}
                            </span>
                            {product.unitPrice !== undefined && (
                              <span className="text-sm italic">
                                {product.unitPrice}:- / st
                              </span>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  );
                } else {
                  return <span>Inga resultat</span>;
                }
              }}
            />
            <form
              onSubmit={form.handleSubmit(onSubmit, onError)}
              className="grid grid-cols-2 grid-rows-[auto,auto,40px,auto] gap-2 rounded-t-lg bg-bg-base-layer p-2 md:p-4"
            >
              <AppFormTextField
                register={form.register}
                name="subject"
                label="Namn"
                className="col-span-2"
                htmlAttributes={{
                  readOnly: productTakenFromList.value,
                  required: true,
                  disabled: disabled,
                }}
              />
              <AppFormTextField
                register={form.register}
                className="col-span-2"
                name="unitPrice"
                label="Pris per enhet"
                htmlAttributes={{
                  readOnly: productTakenFromList.value,
                  required: true,
                  type: "number",
                  disabled: disabled,
                }}
              />

              <fieldset className="col-span-2 flex w-full items-center gap-2">
                <AppFormTextField
                  register={form.register}
                  name="quantity"
                  className="ml-auto w-20"
                  htmlAttributes={{
                    type: "number",
                    id: "extraMaterialQuantity",
                    disabled: disabled,
                    min: 1,
                    //readOnly: productTakenFromList.value,
                  }}
                />
                <label htmlFor="extraMaterialQuantity">st</label>
                <AppTextButton
                  disabled={disabled}
                  type="submit"
                  onClick={form.handleSubmit(onSubmit, onError)}
                >
                  <FiPlusCircle size={35} />
                </AppTextButton>
              </fieldset>
              <AppTextButton
                className="col-span-2 ml-auto text-sm"
                onClick={onReset}
              >
                Återställ
              </AppTextButton>
            </form>
          </section>
        )}

        <section className="flex flex-col gap-2 rounded-b-lg bg-bg-base-layer p-2 md:p-4">
          {/*  <header>
            <h4 className="text-base">Tillagt material</h4>
          </header> */}
          <main className="flex w-full flex-col">
            {reportedMaterialRes.query.data &&
              !!reportedMaterialRes.query.data.length && (
                <MaterialTable
                  isFinished={order.isFinished}
                  data={reportedMaterialRes.query.data}
                  onTrash={onTrash}
                />
              )}
            {!reportedMaterialRes.query.isError &&
              !reportedMaterialRes.isLoading &&
              (!reportedMaterialRes.query.data ||
                !reportedMaterialRes.query.data.length) && (
                <p>Inget material inrapporterat</p>
              )}
            {reportedMaterialRes.isLoading &&
              !reportedMaterialRes.query.data && <AppLoader />}
            {reportedMaterialRes.query.isError &&
              !reportedMaterialRes.isLoading && (
                <p>Det gick inte att hämta material just nu</p>
              )}
          </main>
        </section>
      </main>
    </section>
  );
};

const MaterialTable = (props: {
  data: WorkOrderLineItem.Type[];
  isFinished: boolean;
  onTrash(material: WorkOrderLineItem.Type): void;
}) => {
  const calc = new WorkOrderReimbursementCalculator({
    materialData: props.data,
  });

  const {
    reportedMaterialRes: { isLoading },
  } = useContext(HandymanWorkOrderRouteContext);

  const { handyman } = useContext(SignedInContext);

  return (
    <>
      <ul className={__.classNames("w-full", isLoading && "cursor-wait")}>
        {props.data.map((material) => {
          const { unitPrice, quantity } = material;
          return (
            <li
              className="grid grid-cols-[auto,minmax(40px,1fr)] items-center border-b p-1 last:border-b-0"
              key={material.id}
              data-added-material-source={
                material.addedByHandyman ? "handyman" : "staff"
              }
              data-quantity={quantity}
            >
              <h3 className="text-semibold text-base">{material.name} </h3>

              {!!unitPrice && (
                <span className="row-start-2 text-sm">
                  {unitPrice * quantity}:- ({unitPrice}
                  :- a {quantity}st)
                </span>
              )}
              {!unitPrice && (
                <span className="row-start-2 text-sm">{quantity}st</span>
              )}

              {material.addedByHandyman && !props.isFinished && (
                <AppTextButton
                  className="col-start-3 row-span-2 ml-auto"
                  onClick={() => props.onTrash(material)}
                  disabled={isLoading}
                >
                  <FiTrash size={25} />
                </AppTextButton>
              )}
            </li>
          );
        })}
      </ul>
      {handyman.permittedToViewPrices && (
        <span className="ml-auto p-2 text-right">{calc.forMaterial}:-</span>
      )}
    </>
  );
};

export default CustomerOrderExtraMaterialSection;
