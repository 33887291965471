import { useContext } from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/common/brand/Logo";
import LoadingContent from "../../components/common/loaders/LoadingContent";
import PageHeader from "../../components/layout/PageHeader";
import AppRoutes from "../../routes";
import { GlobalContext } from "../../top-level-contexts";
import __ from "../../utils/utils";

interface Props {
  className?: string;
}

const NotFoundRoute = (props: Props) => {
  const { signInState } = useContext(GlobalContext);

  const signedInAsWorker =
    signInState.isSignedIn && signInState.signedInAs === "worker";

  return (
    <LoadingContent loading={signInState.isLoading} renderContentSeparately>
      <aside className={__.classNames("flex w-full flex-col", props.className)}>
        <PageHeader heading="Sidan hittades inte" />
        <main className="flex grow flex-col items-center gap-2 py-10">
          <h2 className="h-20 w-44">
            <Logo center withColor />
          </h2>
          <p className="text-lg font-bold">Oj, nu verkar du ha kommit fel!</p>
          {signedInAsWorker && (
            <Link to={AppRoutes.partner.root()}>Gå till framsidan</Link>
          )}
          {!signedInAsWorker && (
            <p className="max-w-xs text-center">
              Du kommer åt dina ärenden genom att klicka på länken vi skickar ut
              till dig via mail eller sms
            </p>
          )}
        </main>
      </aside>
    </LoadingContent>
  );
};

export default NotFoundRoute;
