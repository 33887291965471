import __ from "../../../utils/utils";

interface Props {
  className?: string;
  withColor?: boolean;
}

const IpisLogo = (props: Props) => {
  return (
    <img
      className={__.classNames(
        "rounded",
        //!props.className && "h-40 max-h-full rounded",
        props.className
      )}
      src={
        props.withColor ? "/logos/ipis-logo-color.png" : "/logos/ipis-logo.png"
      }
      data-testid="ipis-logo"
      alt="Logga för Ipis"
    />
  );
};

export default IpisLogo;
