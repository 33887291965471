import { motion } from "framer-motion";
import React, { useContext } from "react";
import { GlobalContext } from "../../top-level-contexts";
import __ from "../../utils/utils";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export const AppMain: React.FC<Props> = (props) => {
  /*  useEffect(() => {
    console.log("AppMain", { location });
  }); */
  const variants = {
    hidden: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  };
  const authContext = useContext(GlobalContext);

  return (
    //<AnimatePresence exitBeforeEnter initial={false}>
    <motion.main
      className={__.classNames(
        "flex h-full flex-col overflow-y-auto",
        !authContext.signInState.isSignedIn && "lg:col-span-2",
        props.className
      )}
      variants={variants}
      /*     initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }} */
      /* transition={{
          type: "tween",
          duration: 0.2,
        }} */
      //key={location.pathname}
    >
      {props.children}
    </motion.main>
    //</AnimatePresence>
  );
};
