import { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BiCheck } from "react-icons/bi";
import { FaHandshake } from "react-icons/fa";
import PageSection from "../../../components/common/PageSection";
import AppTabs from "../../../components/common/tabs/AppTabs";
import HandymanWorkOrderControls from "../../../components/customer-order/HandymanWorkOrderControls";
import WorkOrderImages from "../../../components/customer-order/WorkOrderImages";
import PageHeader from "../../../components/layout/PageHeader";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import WorkOrderFileContextProvider from "../../../components/order-image-wrapper/WorkOrderFileContextProvider";
import PageBanner from "../../../components/PageBanner";
import { useBundledState, useDetectKeyboardOpen } from "../../../hooks/hooks";
import __ from "../../../utils/utils";
import HandymanWorkOrderOverviewSection from "./HandymanWorkOrderOverviewSection";
import HandymanWorkOrderRouteContext from "./HandymanWorkOrderRouteContext";

enum Tabs {
  overview = "Översikt",
  handle = "Hantera",
  files = "Filer",
}

const HandymanWorkOrderPage = () => {
  const { order } = useContext(HandymanWorkOrderRouteContext);
  const keyboardOpen = useDetectKeyboardOpen();

  const selectedTabBundle = useBundledState(Tabs.overview);

  const { orderRes } = useContext(HandymanWorkOrderRouteContext);

  const handlingSomeoneElsesOrder =
    !order.isAssignedThisOrder && order.allowedToHandleOrder;

  /* async function onReset() {
    try {
      await orderRes.mutate(() => {
        return workOrderRepo.reset({ orderId: order.orderId });
      });
    } catch (er: any) {
      window.modal.alert({
        title: "Oops",
        prompt: "Ojdå",
        typeOfAlert: "error",
      });
    }
  }
 */
  return (
    <WorkOrderFileContextProvider orderId={order.orderId}>
      <section
        className={__.classNames(
          "mx-auto grid h-full w-full lg:bg-bg-base-layer",
          !keyboardOpen && "grid-rows-[auto,minmax(0,1fr)]",
          keyboardOpen && "grid-rows-1"
        )}
      >
        {!keyboardOpen && (
          <PageHeader
            RenderHeadings={(props) => {
              return (
                <span className="grid grid-cols-[auto,minmax(0,1fr)] items-center gap-x-2 gap-y-1">
                  <h1
                    className={__.classNames(
                      props.headingClassName,
                      "col-start-1"
                    )}
                  >
                    {order.description}
                  </h1>
                  <h2
                    className={__.classNames(
                      props.subheadingClassName,
                      "col-start-1"
                    )}
                  >
                    #{order.serialNumber}
                  </h2>
                  {order.isFinished && (
                    <span
                      className={__.classNames(
                        "col-start-2 row-span-2 row-start-1 my-auto"
                      )}
                    >
                      <span className="sr-only">Ordern är klarmarkerad</span>
                      <BiCheck
                        size={35}
                        className="text-green-600"
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </span>
              );
            }}
            loading={orderRes.query.isFetching}
            //heading={order.description}
            //subheading={`#${order.serialNumber}`}
          >
            {(order.isFinished || !!order.serviceContract) && (
              <section className="col-span-3 my-auto flex flex-col px-4  lg:col-span-1">
                {!!order.serviceContract && (
                  <span className="flex gap-2 text-base">
                    <FaHandshake
                      size={25}
                      className="text-orange-400"
                      aria-hidden="true"
                    />
                    Avtalskund
                  </span>
                )}
              </section>
            )}
            {/* {process.env.NODE_ENV === "development" && (
              <fieldset className="col-span-4 border-2 p-4">
                <legend>För testningssyfte</legend>
                <AppButton onClick={onReset}>
                  Återställ uppdraget till obekräftat
                </AppButton>
              </fieldset>
            )} */}
          </PageHeader>
        )}
        <ErrorBoundary
          fallbackRender={(props) => (
            <SomethingWentWrong
              devError={props.error}
              onTryAgain={() => orderRes.query.refetch()}
              tryAgainLabel="Hämta på nytt"
              description="Det gick inte att hämta detta uppdrag just nu."
            />
          )}
        >
          <main
            className={__.classNames(
              "grid grow md:gap-4",
              /*
                When not displaying banner that you're handling someone else's order
              */
              !handlingSomeoneElsesOrder && "grid-rows-1",
              /*
                When displaying banner that you're handling someone else's order
              */
              handlingSomeoneElsesOrder &&
                "grid-rows-[minmax(0,1fr),auto] md:grid-rows-[auto,minmax(0,1fr)]"
            )}
          >
            <AppTabs
              tabs={
                order.allowedToHandleOrder
                  ? Object.values(Tabs)
                  : [Tabs.overview, Tabs.files]
              }
              selectedTab={selectedTabBundle.value}
              onSelect={(tab) => selectedTabBundle.set(tab)}
              mainClassName="p-1 md:p-2 lg:p-8 2xl:p-12"
            >
              {selectedTabBundle.value === Tabs.overview && (
                <HandymanWorkOrderOverviewSection />
              )}
              {selectedTabBundle.value === Tabs.handle && (
                <HandymanWorkOrderControls />
              )}
              {selectedTabBundle.value === Tabs.files && (
                /* ToDo: Use more accessible element */
                <PageSection
                  as="div"
                  className="mx-auto h-full w-full max-w-screen-xl"
                >
                  <WorkOrderImages
                    uploadLabel="Ladda upp"
                    orderId={order.orderId}
                    allowDeleting={
                      order.allowedToHandleOrder && !order.isFinished
                    }
                    allowUploading={
                      order.allowedToHandleOrder && !order.isFinished
                    }
                  />
                </PageSection>
              )}
            </AppTabs>
            {!order.isAssignedThisOrder &&
              order.allowedToHandleOrder &&
              order.assignedTo?.handymanName && (
                <PageBanner
                  className="row-start-2 mt-auto  md:row-start-1 md:border-2"
                  isLoading={false}
                >
                  Du hanterar uppdraget åt {order.assignedTo.handymanName}
                </PageBanner>
              )}
          </main>
        </ErrorBoundary>
      </section>
    </WorkOrderFileContextProvider>
  );
};

export default HandymanWorkOrderPage;
