import { API } from "../api/API";
import SearchParams from "../api/SearchParams";
import { Observable } from "../observables/Observable";
import { WorkOrder } from "@eljouren/domain";
import { TWorkerSignInData } from "../schemas-and-types/repo-schemas";
import IAuthRepo, { TSignInState, TSignUpIdType } from "./interfaces/IAuthRepo";
import IHandymanRepo from "./interfaces/IHandymanRepo";

export class AuthRepo implements IAuthRepo {
  private readonly _signedInState = new Observable<TSignInState>({
    isLoading: false,
    isSignedIn: false,
  });

  constructor(private workerRepo: IHandymanRepo) {}

  get signedInState() {
    return this._signedInState.privateObs;
  }

  async notifyCustomerOrderFetched(order: WorkOrder.Type) {
    const state = { ...this.signedInState.value };

    if (state.signedInAs === "customer" || state.signedInAs === "sales") {
      state.brand = order.brand;
      this._signedInState.set(state);
    }
  }

  async customerAuthentication(orderId: string): Promise<200 | 404> {
    const base = API.base();
    const url = `${base}/auth`;
    try {
      const res = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workorderId: orderId,
          guidOrigin: "customer",
        }),
      });

      if (res.status === 200) {
        const json = await res.json();
        API.setNonSignedInJwt(json.jwtToken);

        /*
        Should probably return the brand from the server so we dont need to set the brand to ipis
        */
        this._signedInState.set({
          isSignedIn: true,
          signedInAs: "customer",
          isLoading: false,
          brand: "ipis",
        });

        return 200;
      } else {
        this._signedInState.set({
          isSignedIn: false,
          isLoading: false,
        });

        if (res.status === 404) {
          return 404;
        }

        throw new Error("Failed to authenticate");
      }
    } catch (er) {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: false,
      });
      throw new Error("Unknown error");
    }
  }
  async signUpIdAuthentication(
    signUpId: string,
    type: TSignUpIdType
  ): Promise<200 | 404> {
    const params = new SearchParams({
      signupId: signUpId,
    });
    const url = API.endpoint("signUpIdValid", params);

    try {
      const res = await fetch(url, {
        credentials: "include",
      });

      if (res.status === 200) {
        const json = await res.json();
        if (json.type !== type) {
          // Misleading
          return 404;
        }

        return 200;
      } else {
        if (res.status === 404) {
          return 404;
        }

        throw new Error("Failed to authenticate");
      }
    } catch (er) {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: false,
      });
      throw new Error("Unknown error");
    }
  }
  // Very similar to customerAuthentication, probably worth to merge
  async salesAuthentication(orderId: string): Promise<200 | 404> {
    const base = API.base();
    const url = `${base}/auth`;

    try {
      const res = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workorderId: orderId,
          guidOrigin: "sales",
        }),
      });

      if (res.status === 200) {
        const json = await res.json();
        API.setNonSignedInJwt(json.jwtToken);

        this._signedInState.set({
          isSignedIn: true,
          signedInAs: "sales",
          isLoading: false,
          brand: "ipis",
        });

        return 200;
      } else {
        this._signedInState.set({
          isSignedIn: false,
          isLoading: false,
        });

        if (res.status === 404) {
          return 404;
        }

        throw new Error("Failed to authenticate");
      }
    } catch (er) {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: false,
      });
      throw new Error("Unknown error");
    }
  }

  async staffAuthentication(staffGuid: string): Promise<200 | 404> {
    const base = API.base();
    const url = `${base}/auth`;

    try {
      const res = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          ...API.getOptionalAuthHeader(),
        },
        body: JSON.stringify({
          staffWebAppGuid: staffGuid,
        }),
      });

      if (res.status === 200) {
        const json = await res.json();
        /* 
          Jwt only  gets returned if the user wasnt signed in previously
        */
        if (json.jwtToken) {
          API.setNonSignedInJwt(json.jwtToken);
        }

        const firstName = json.firstName;
        const lastName = json.lastName;
        this._signedInState.set({
          isSignedIn: true,
          signedInAs: "staff",
          isLoading: false,
          brand: "ipis",
          firstName,
          lastName,
        });

        return 200;
      } else {
        this._signedInState.set({
          isSignedIn: false,
          isLoading: false,
        });

        if (res.status === 404) {
          return 404;
        }

        throw new Error("Failed to authenticate");
      }
    } catch (er) {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: false,
      });
      throw new Error("Unknown error");
    }
  }

  async signInWithToken(): Promise<TWorkerSignInData | null> {
    try {
      if (!API.workerIsAuthenticated()) {
        this._signedInState.set({
          isSignedIn: false,
          isLoading: false,
        });
        return null;
      }

      return this.buildSignInInfo();
    } catch (ex) {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: false,
      });

      return null;
    }
  }

  async signInWithCredentials(
    usernameOrEmail: string,
    password: string
  ): Promise<TWorkerSignInData | null> {
    const base = API.base();
    const url = `${base}/auth`;

    try {
      /*    this._signedInState.set({
        isSignedIn: false,
        isLoading: true,
      }); */

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          email: usernameOrEmail,
          password,
        }),
      });
      if (res.status === 200) {
        const json = await res.json();
        API.setWorkerJwt(json.jwtToken);
        return this.buildSignInInfo();
      } else {
        this._signedInState.set({
          isSignedIn: false,
          isLoading: false,
        });
        if (res.status === 401) {
          return null;
        }
        throw new Error("Failed to authenticate");
      }
    } catch (er) {
      throw new Error("Unknown error");
    }
  }

  private async buildSignInInfo(): Promise<TWorkerSignInData> {
    try {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: true,
      });

      const userId = API.getUserId();
      const worker = await this.workerRepo.fetchHandyman(userId);

      this._signedInState.set({
        isSignedIn: true,
        signedInAs: "worker",
        handyman: worker,
        isLoading: false,
      });

      return { handyman: worker };
    } catch (er) {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: false,
      });
      throw er;
    }
  }

  async signOut() {
    this._signedInState.set({
      isSignedIn: false,
      isLoading: false,
    });

    API.removeAllJwts();
  }

  async choosePassword(args: {
    signUpId: string;
    password: string;
    type: TSignUpIdType;
  }): Promise<boolean> {
    try {
      const endpoint = API.endpoint("signup");

      const res = await fetch(endpoint, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          signupId: args.signUpId,
          password: args.password,
          reset: args.type === "reset" ? "true" : "false",
        }),
      });

      if (res.status === 200) {
        return true;
      }

      throw new Error("Failed to fetch");
    } catch (er) {
      throw er;
    }
  }

  async invokeResetPasswordFlow(args: { email: string }): Promise<void> {
    const endpoint = API.endpoint("resetPassword");
    const res = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: args.email }),
    });

    if (res.status !== 200) {
      throw new Error("Failed to invoke reset password flow");
    }
  }
}
